import { useQuery } from '@apollo/client';
import { USER_DEVICES_QUERY, USER_ID_ME, } from '../../apollo';
const useDevices = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_DEVICES_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    return {
        devices: (data === null || data === void 0 ? void 0 : data.userDevices.devices) || [],
    };
};
export default useDevices;
