var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { USER_DEACTIVATE_MUTATION, UserBaseStatus, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
const useDeactivate = () => {
    const apolloClient = useApolloClient();
    return useCallback((id = '') => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.mutate({
            mutation: USER_DEACTIVATE_MUTATION,
            variables: { id },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userDeactivate.success)) {
                    return;
                }
                cache.modify({
                    id: getUserIdentifier(id),
                    fields: {
                        status() {
                            return UserBaseStatus.Deactivated;
                        },
                    },
                });
            },
        });
        return data === null || data === void 0 ? void 0 : data.userDeactivate.success;
    }), [apolloClient]);
};
export default useDeactivate;
