export const getArchive = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.process_id) ||
        !gto.archive_reason ||
        !gto.archive_state ||
        !gto.archive_type ||
        !gto.process_start_date ||
        !gto.requester_id ||
        !gto.user_id) {
        return undefined;
    }
    return {
        id: gto.process_id,
        archiveReason: gto.archive_reason,
        archiveState: gto.archive_state,
        archiveType: gto.archive_type,
        isDone: gto.is_done || false,
        creationDate: gto.creation_date,
        processStartDate: gto.process_start_date,
        requesterComment: gto.requester_comment || '',
        requesterId: gto.requester_id,
        userId: gto.user_id,
    };
};
