import { gql } from '@apollo/client';
export const SHOP_PRODUCT_BASE_FRAGMENT = gql `
  fragment ShopProductBase on ShopProduct {
    id
    name
    description
    modificationDate
    creationDate
    activated
    reactivation
    storeProductId
    defaultProduct
    position
    channel
    productType
    introductory
    packInformation {
      credits {
        amount
        type
      }
    }
    planInformation {
      credits {
        renewablePerPeriod
        cooldownPeriod
        type
      }
      recurrenceSubscriptionPeriod {
        unit
        value
      }
      prices {
        currency
        country
        amountCents
        id
        taxRateId
      }
      introductoryPlan {
        cycles
        recurrencePeriod {
          unit
          value
        }
        prices {
          currency
          country
          amountCents
        }
      }
    }
  }
`;
export const SHOP_PRODUCT_GROUP_BASE_FRAGMENT = gql `
  fragment ShopProductGroupBase on ShopProductGroup {
    id
    name
    description
    productType
    count
    productIds
    creationDate
  }
`;
export const SHOP_RULE_BASE_FRAGMENT = gql `
  fragment ShopRuleBase on ShopRule {
    id
    type
    name
    description
    priority
    filters
    activationDate
    expirationDate
    creationDate
    modificationDate
    activated
    productGroups
  }
`;
