var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { USER_ID_ME, USER_UNREAD_INFO_QUERY, } from '../../apollo';
import * as gql from './gql';
const useUnreadInfo = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(gql.USER_UNREAD_INFO_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const unreadInfo = (data === null || data === void 0 ? void 0 : data.user.unreadInfo) || {
        conversations: 0,
        notifications: 0,
    };
    const refetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apolloClient.query({
                query: USER_UNREAD_INFO_QUERY,
                fetchPolicy: 'network-only',
                variables: { id },
            });
            return true;
        }
        catch (err) {
            return false;
        }
    }), [apolloClient, id]);
    return Object.assign(Object.assign({}, unreadInfo), { refetch });
};
export default useUnreadInfo;
