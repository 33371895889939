import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronSmallRightIcon, QuoteOpenIcon } from '@happn/icons';
import Styled from './CardPrompt.styles';
const CardPrompt = ({ prompt, title, onClick, className, variant = 'display', isCommon = false, }) => {
    const { t } = useTranslation();
    const promptVariant = prompt.length <= 50 ? 'titleMedium' : 'headline1Medium';
    const isEditVariant = variant === 'edit';
    const modifyButtonLabel = t('teasers_modify_button');
    const handleKeyPress = useCallback((event) => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === ' ') {
            onClick === null || onClick === void 0 ? void 0 : onClick();
        }
    }, [onClick]);
    return (_jsxs(Styled.CardPrompt, Object.assign({ variant: variant, className: className, title: isEditVariant ? modifyButtonLabel : title, role: isEditVariant ? 'button' : '', onClick: onClick, tabIndex: 0, onKeyPress: handleKeyPress }, { children: [_jsx(Styled.Avatar, { icon: _jsx(QuoteOpenIcon, {}, void 0), size: "x-small", isCommon: isCommon }, void 0), _jsx(Styled.Title, { children: title }, void 0), _jsx(Styled.PromptText, Object.assign({ variant: promptVariant }, { children: prompt }), void 0), isEditVariant && (_jsxs(Styled.EditButton, Object.assign({ onClick: onClick }, { children: [modifyButtonLabel, " ", _jsx(ChevronSmallRightIcon, {}, void 0)] }), void 0))] }), void 0));
};
export default CardPrompt;
