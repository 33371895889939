import { defaultTo } from 'ramda';
import { NotificationsPreferencesType } from '../types';
import { getMatchingTraits } from './traits';
const defaultToNotificationsNone = defaultTo(NotificationsPreferencesType.None);
export const getPreferences = (userGto) => {
    var _a, _b, _c;
    const { matching_preferences: matching, location_preferences: location, mysterious_mode_preferences: mysteriousMode, marketing_preferences: marketing, notification_settings: notifications, } = userGto;
    return {
        matching: {
            ageMin: (matching === null || matching === void 0 ? void 0 : matching.age_min) || 20,
            ageMax: (matching === null || matching === void 0 ? void 0 : matching.age_max) || 40,
            female: Boolean(matching === null || matching === void 0 ? void 0 : matching.female),
            male: Boolean(matching === null || matching === void 0 ? void 0 : matching.male),
            nonBinary: Boolean(matching === null || matching === void 0 ? void 0 : matching.non_binary),
        },
        location: {
            hide: Boolean(location === null || location === void 0 ? void 0 : location.hide_location),
        },
        mysteriousMode: {
            hideGenderAlias: Boolean(mysteriousMode === null || mysteriousMode === void 0 ? void 0 : mysteriousMode.hide_gender_alias),
        },
        marketing: {
            audienceMeasurement: Boolean(marketing === null || marketing === void 0 ? void 0 : marketing.audience_measurement),
            operations: Boolean(marketing === null || marketing === void 0 ? void 0 : marketing.marketing_operations),
            targetedAds: Boolean(marketing === null || marketing === void 0 ? void 0 : marketing.targeted_ads),
            recommendedInEmails: Boolean(marketing === null || marketing === void 0 ? void 0 : marketing.recommended_in_emails),
        },
        notifications: {
            charms: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.charms),
            crushTime: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.crush_time),
            dailyRecap: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.daily_recap),
            crush: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.match),
            news: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.news),
            others: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.others),
            weeklyRecap: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.weekly_recap),
            // Push only
            dates: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.dates),
            likes: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.likes),
            messages: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.messages),
            near: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.near),
            reminders: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.reminders),
            visit: defaultToNotificationsNone(notifications === null || notifications === void 0 ? void 0 : notifications.visit),
        },
        biometric: {
            profileVerification: {
                accepted: Boolean((_b = (_a = userGto.biometric_preferences) === null || _a === void 0 ? void 0 : _a.profile_verification) === null || _b === void 0 ? void 0 : _b.accepted),
            },
        },
        matchingTraits: getMatchingTraits((_c = matching === null || matching === void 0 ? void 0 : matching.matching_traits) === null || _c === void 0 ? void 0 : _c.traits),
    };
};
