import { differenceInMinutes } from 'date-fns';
import { GqlTypes } from '@happn/types';
export const getOnlineStatus = (date) => {
    if (!date) {
        return GqlTypes.OnlineStatus.Unknown;
    }
    const correctDate = typeof date === 'string' ? new Date(date) : date;
    const now = Date.now();
    const MINUTES_IN_DAY = 1440;
    const diff = differenceInMinutes(now, correctDate);
    if (diff < 15) {
        return GqlTypes.OnlineStatus.BetweenNowAnd15Min;
    }
    if (diff < 60) {
        return GqlTypes.OnlineStatus.Between15MinAnd1H;
    }
    if (diff < 120) {
        return GqlTypes.OnlineStatus.Between1HAnd2H;
    }
    if (diff < 180) {
        return GqlTypes.OnlineStatus.Between2HAnd3H;
    }
    if (diff < MINUTES_IN_DAY) {
        return GqlTypes.OnlineStatus.Between3HAndYesterday;
    }
    if (diff < MINUTES_IN_DAY * 3) {
        return GqlTypes.OnlineStatus.BetweenYesterdayAnd3DaysAgo;
    }
    return GqlTypes.OnlineStatus.Before3DaysAgo;
};
