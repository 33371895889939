var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * getClientCountry gets ip based country from CloudFlare header `cf-ipcountry`.
 * As fallback it uses navigator.language
 */
import { getBrowserCountry, getCfCountry } from './getCountry';
export const getClientCountry = (() => {
    let initialized = false;
    let country = '';
    return () => __awaiter(void 0, void 0, void 0, function* () {
        if (!initialized) {
            initialized = true;
            try {
                country = yield getCfCountry();
            }
            catch (_a) {
                // fallthrough
            }
            if (!country) {
                country = getBrowserCountry();
            }
        }
        return country;
    });
})();
export default getClientCountry;
