import { nonUndefined } from '@happn/helpers';
export const getPicture = (imageGto) => {
    if (!(imageGto === null || imageGto === void 0 ? void 0 : imageGto.id) || !imageGto.url) {
        return undefined;
    }
    return {
        id: imageGto.id,
        url: imageGto.url,
        isDefault: imageGto.is_default || false,
    };
};
export const getPictures = (user) => {
    var _a;
    return ((_a = user.profiles) === null || _a === void 0 ? void 0 : _a.map(getPicture).filter(nonUndefined)) || [];
};
