var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { isEmpty, merge } from 'ramda';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { ApiTypes } from '@happn/types';
import { SHOP_RULES_QUERY, SHOP_RULES_SEARCH_QUERY, SHOP_RULE_ADD_MUTATION, SHOP_RULE_UPDATE_MUTATION, SHOP_RULE_DELETE_MUTATION, } from '../apollo';
import { toShopInAppRulesGtoModel } from '../apollo/mapper';
export const useRules = () => {
    const apolloClient = useApolloClient();
    /**
     * INTERNAL USAGE ONLY - https://happnapp.atlassian.net/wiki/spaces/FRONTEND/pages/2868346892/0003+-+Search+in+ShopInAppPurchaseRules+flow
     * List ALL rules and filter by given type
     */
    const _list = useCallback((ruleType) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.query({
            query: SHOP_RULES_QUERY,
        });
        return ((data === null || data === void 0 ? void 0 : data.shopRules) || []).filter((sr) => sr.type === ruleType);
    }), [apolloClient]);
    /**
     * INTERNAL USAGE ONLY - https://happnapp.atlassian.net/wiki/spaces/FRONTEND/pages/2868346892/0003+-+Search+in+ShopInAppPurchaseRules+flow
     * Search rules with given filters
     */
    const _listSearch = useCallback((ruleType, filters) => __awaiter(void 0, void 0, void 0, function* () {
        /**
         * Without those "default" values, backend will return empty array
         */
        const defaultFilters = {
            age: null,
            country: '',
            device_type: '',
            is_subscriber: false,
            register_date: null,
            user_id: '',
        };
        const input = merge(defaultFilters, filters);
        const { data } = yield apolloClient.query({
            query: SHOP_RULES_SEARCH_QUERY,
            variables: {
                ruleType,
                input,
            },
        });
        return data.shopRulesSearch || [];
    }), [apolloClient]);
    const list = useCallback((ruleType = ApiTypes.ShopRulesTypeEnum
        .Standard, filters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        const data = isEmpty(filters)
            ? yield _list(ruleType)
            : yield _listSearch(ruleType, filters);
        return {
            data,
        };
    }), [_list, _listSearch]);
    const add = useCallback((input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_RULE_ADD_MUTATION,
                variables: { input: toShopInAppRulesGtoModel(input) },
            });
            const addedRule = (data === null || data === void 0 ? void 0 : data.shopRuleAdd) || null;
            if (addedRule) {
                apolloClient.cache.modify({
                    fields: {
                        shopRules: (shopRules, { toReference }) => [
                            toReference(addedRule),
                            ...shopRules,
                        ],
                    },
                });
            }
            return addedRule;
        }
        catch (_a) {
            return null;
        }
    }), [apolloClient]);
    const update = useCallback((id, input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!id) {
                return null;
            }
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_RULE_UPDATE_MUTATION,
                variables: { id, input: toShopInAppRulesGtoModel(input) },
            });
            const updatedRule = (data === null || data === void 0 ? void 0 : data.shopRuleUpdate) || null;
            if (updatedRule) {
                apolloClient.cache.modify({
                    fields: {
                        shopRules: (shopRules, { toReference, readField }) => [
                            toReference(updatedRule),
                            ...shopRules.filter((ruleRef) => readField('id', ruleRef) !== updatedRule.id),
                        ],
                    },
                });
            }
            return updatedRule;
        }
        catch (_b) {
            return null;
        }
    }), [apolloClient]);
    const remove = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (id === '') {
                return false;
            }
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_RULE_DELETE_MUTATION,
                variables: {
                    id,
                },
            });
            const isSuccess = !!(data === null || data === void 0 ? void 0 : data.shopRuleDelete.success);
            if (isSuccess) {
                apolloClient.cache.modify({
                    fields: {
                        shopRules: (shopRules, { readField }) => [
                            ...shopRules.filter((ruleRef) => readField('id', ruleRef) !== id),
                        ],
                    },
                });
            }
            return isSuccess;
        }
        catch (err) {
            loggerService.error(new Error(`Could not delete rule: ${err}`));
            return false;
        }
    }), [apolloClient]);
    return {
        list,
        add,
        update,
        remove,
    };
};
export default useRules;
