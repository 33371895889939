import { nonUndefined } from '@happn/helpers';
export const getTeaserAnswer = (teaserAnswer) => {
    if (!(teaserAnswer === null || teaserAnswer === void 0 ? void 0 : teaserAnswer.teaser_id) ||
        !(teaserAnswer === null || teaserAnswer === void 0 ? void 0 : teaserAnswer.answer_text) ||
        !(teaserAnswer === null || teaserAnswer === void 0 ? void 0 : teaserAnswer.teaser_text) ||
        !(teaserAnswer === null || teaserAnswer === void 0 ? void 0 : teaserAnswer.translated_teaser)) {
        return undefined;
    }
    return {
        teaserId: teaserAnswer.teaser_id,
        answerText: teaserAnswer.answer_text,
        teaserText: teaserAnswer.teaser_text,
        translatedTeaser: teaserAnswer.translated_teaser,
    };
};
export const getTeaserAnswers = (teaserAnswers) => {
    if (!teaserAnswers) {
        return [];
    }
    return teaserAnswers === null || teaserAnswers === void 0 ? void 0 : teaserAnswers.map(getTeaserAnswer).filter(nonUndefined);
};
export const getTeaser = (teaser) => {
    if (!(teaser === null || teaser === void 0 ? void 0 : teaser.id) ||
        !(teaser === null || teaser === void 0 ? void 0 : teaser.default_teaser) ||
        !(teaser === null || teaser === void 0 ? void 0 : teaser.category_id) ||
        !(teaser === null || teaser === void 0 ? void 0 : teaser.translated_teaser)) {
        return undefined;
    }
    return {
        id: teaser.id,
        defaultTeaser: teaser.default_teaser,
        translatedTeaser: teaser.translated_teaser,
        categoryId: teaser.category_id,
    };
};
export const toTeasersListModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return [];
    }
    return res.data.map(getTeaser).filter(nonUndefined);
};
