var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { USER_SPONSOR_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_SPONSOR_PROFILE_QUERY } from './gql';
export const useSponsor = (userId = USER_ID_ME) => {
    const client = useApolloClient();
    const { data: sponsorData } = useQuery(USER_SPONSOR_PROFILE_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id: userId,
        },
    });
    const isSponsor = !!(sponsorData === null || sponsorData === void 0 ? void 0 : sponsorData.user.isSponsor);
    const markUserAsSponsor = useCallback((sponsor) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const res = yield client.mutate({
                mutation: USER_SPONSOR_MUTATION,
                update(cache, { data }) {
                    if (data === null || data === void 0 ? void 0 : data.userSponsor.success) {
                        cache.modify({
                            id: getUserIdentifier(userId),
                            fields: {
                                isSponsor(existing) {
                                    return !existing;
                                },
                            },
                        });
                    }
                },
                variables: {
                    id: userId,
                    input: {
                        is_sponsor: sponsor,
                    },
                },
            });
            return (_a = res.data) === null || _a === void 0 ? void 0 : _a.userSponsor.success;
        }
        catch (_b) {
            // fallthrough
        }
        return false;
    }), [client, userId]);
    const toggleSponsorStatus = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        return markUserAsSponsor(!isSponsor);
    }), [isSponsor, markUserAsSponsor]);
    return {
        markUserAsSponsor,
        toggleSponsorStatus,
        isSponsor,
    };
};
export default useSponsor;
