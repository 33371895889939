var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Avatar from '../Avatar';
import Styled from './CardAction.styles';
const CardAction = (_a) => {
    var { title, description, onClick, button, variant = 'default', avatarIcon, avatarSet, titleTestId } = _a, rest = __rest(_a, ["title", "description", "onClick", "button", "variant", "avatarIcon", "avatarSet", "titleTestId"]);
    const titleSize = variant === 'lini'
        ? 'headline2Medium'
        : variant === 'large'
            ? 'titleMedium'
            : 'headline1Medium';
    const handleKeyDown = useCallback((event) => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === ' ') {
            onClick();
        }
    }, [onClick]);
    return (_jsxs(Styled.CardAction, Object.assign({ onClick: onClick, variant: variant, role: "button", tabIndex: 0, onKeyDown: handleKeyDown }, rest, { children: [avatarIcon && _jsx(Styled.Avatar, { icon: avatarIcon }, void 0), avatarSet && (_jsx(Styled.AvatarSet, { children: avatarSet.map((avatar) => (_jsx(Avatar, { picture: avatar, variant: "light", withBorder: true }, avatar))) }, void 0)), _jsx(Styled.Title, Object.assign({ variant: titleSize, testId: titleTestId }, { children: title }), void 0), description && _jsx(Styled.Description, { children: description }, void 0), (button === null || button === void 0 ? void 0 : button.label) && (_jsx(Styled.Button, Object.assign({ startIcon: button.icon, testId: button === null || button === void 0 ? void 0 : button.testId }, { children: button.label }), void 0))] }), void 0));
};
export default CardAction;
