import jwtDecode from 'jwt-decode';
const decode = (jwtToken) => {
    if (!jwtToken) {
        return;
    }
    try {
        return jwtDecode(jwtToken || '');
    }
    catch (_a) {
        return;
    }
};
export default decode;
