var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Styled from './TextField.styles';
const TextField = (_a) => {
    var { className, onBlur, selected, hasError, disabled, iconLeft, iconRight, placeholder, fieldSize = 'large' } = _a, rest = __rest(_a, ["className", "onBlur", "selected", "hasError", "disabled", "iconLeft", "iconRight", "placeholder", "fieldSize"]);
    return (_jsxs(Styled.TextFieldWrapper, { children: [!!iconLeft && (_jsx(Styled.IconWrapper, Object.assign({ left: "1rem" }, { children: iconLeft }), void 0)), _jsx(Styled.TextField, Object.assign({ onBlur: onBlur, className: className, selected: selected, hasError: hasError, disabled: disabled, placeholder: placeholder, iconLeft: iconLeft, iconRight: iconRight, fieldSize: fieldSize, spellCheck: false }, rest), void 0), !!iconRight && (_jsx(Styled.IconWrapper, Object.assign({ right: "1rem" }, { children: iconRight }), void 0))] }, void 0));
};
export default TextField;
