import styled, { css } from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
import AvatarBase from '../Avatar';
import Button, { ButtonVariant } from '../Button';
import Text from '../Text';
const CardPrompt = styled.div `
  padding: 2rem;
  border-radius: 2rem;
  background-color: ${getThemeColor(ThemeColor.FillLightSubdued)};
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${({ variant }) => variant === 'edit' &&
    css `
      min-height: 13rem;
      cursor: pointer;
      outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
    `}
`;
const Avatar = styled(AvatarBase) `
  color: ${getThemeColor(ThemeColor.IconDark)};
  transform: rotate(5deg);
  margin-bottom: 0.75rem;
  background-color: ${({ isCommon }) => isCommon
    ? getThemeColor(ThemeColor.FillPrimary)
    : getThemeColor(ThemeColor.FillDarkMuted)};
`;
const Title = styled(Text).attrs({ variant: 'bodyMedium' }) `
  text-align: center;
  margin-bottom: 0.75rem;
`;
const PromptText = styled(Text) `
  text-align: center;
`;
const EditButton = styled(Button).attrs({ variant: ButtonVariant.Transparent }) `
  margin-top: 1rem;
  margin-right: 0;
  margin-left: auto;
`;
export default {
    CardPrompt,
    Avatar,
    Title,
    PromptText,
    EditButton,
};
