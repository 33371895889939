var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { USER_ABOUT_FRAGMENT, USER_UPDATE_MUTATION, USER_UNBAN_MUTATION, UserBaseStatus, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
const useUnban = () => {
    const client = useApolloClient();
    return useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const unbanResult = yield client.mutate({
            mutation: USER_UNBAN_MUTATION,
            variables: {
                id,
            },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.unbanUser.success)) {
                    return;
                }
                cache.modify({
                    id: getUserIdentifier(id),
                    fields: {
                        status() {
                            return UserBaseStatus.Active;
                        },
                    },
                });
            },
        });
        const unbanSucceed = (_a = unbanResult.data) === null || _a === void 0 ? void 0 : _a.unbanUser.success;
        if (!unbanSucceed) {
            return false;
        }
        const user = client.cache.readFragment({
            id: getUserIdentifier(id),
            fragment: USER_ABOUT_FRAGMENT,
        });
        if (!(user === null || user === void 0 ? void 0 : user.about.startsWith('#'))) {
            return true;
        }
        /**
         * Remove ban tag ({@link BanTag}) from user `about` field
         */
        const removeBanTagResult = yield client.mutate({
            mutation: USER_UPDATE_MUTATION,
            variables: {
                id,
                input: {
                    about: user.about.substring(3),
                },
            },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userUpdate.success)) {
                    return;
                }
                cache.modify({
                    id: getUserIdentifier(id),
                    fields: {
                        about() {
                            return user.about.substring(3);
                        },
                    },
                });
            },
        });
        return !!((_b = removeBanTagResult.data) === null || _b === void 0 ? void 0 : _b.userUpdate.success);
    }), [client]);
};
export default useUnban;
