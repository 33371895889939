import { StrictMode, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'

import sentryService from '@happn/sentry'
import theme from '@happn/theme'

import Environment from 'components/Environment'
import { FullScreenLoader } from 'components/Loaders'

import { ROOT_ELEMENT } from './constant'
import './i18n'
import GlobalStyle from './index.styles'
import * as serviceWorker from './serviceWorker'

sentryService.init({
  denyUrls: [/https:\/\/cdn.apple-mapkit\.com\/mk\/.+\/mapkit\.js/],
  ignoreErrors: [
    /ChunkLoadError/,
    /Loading CSS chunk \d+ failed/,
    /upstream connect error or disconnect/,
    /Received status code 4\d\d/,
    /Non-Error promise rejection captured/,
    /Load failed/,
    /Invariant violation/,
    /Failed to execute 'transaction' on 'IDBDatabase'/,
    /NetworkError when attempting to fetch resource/,
    /Cannot read property/,
  ],
})

const App = lazy(async () => {
  await new Promise((resolve) => setTimeout(resolve, 2500))
  return import(/* webpackChunkName: "WebApp" */ './app')
})

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Suspense fallback={<FullScreenLoader />}>
        <Environment>
          <App />
        </Environment>
      </Suspense>
    </ThemeProvider>
  </StrictMode>,
  ROOT_ELEMENT,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
