import { useQuery } from '@apollo/client';
import { USER_QUERY, UserProfileType, USER_ID_ME, } from '../../apollo';
const useAsProfile = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    const user = data === null || data === void 0 ? void 0 : data.user;
    if (!user) {
        return undefined;
    }
    return {
        type: UserProfileType.Gift,
        user,
    };
};
export default useAsProfile;
