var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { getPagination } from '@happn/apollo';
import { USER_ID_ME, USER_ORDERS_QUERY, USER_ORDER_BASE_FRAGMENT, USER_ORDER_UPDATE_MUTATION, } from '../../apollo';
import { getUserOrderIdentifier } from '../../helpers';
const useOrders = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const fetch = useCallback((limit, offset) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const usersResult = yield apolloClient.query({
            query: USER_ORDERS_QUERY,
            fetchPolicy: 'network-only',
            variables: {
                id,
                limit,
                offset,
            },
        });
        return {
            data: ((_a = usersResult.data) === null || _a === void 0 ? void 0 : _a.userOrders.items) || [],
            pagination: ((_b = usersResult.data) === null || _b === void 0 ? void 0 : _b.userOrders.pagination) || getPagination(),
        };
    }), [apolloClient, id]);
    const toggleSandbox = useCallback((orderId) => __awaiter(void 0, void 0, void 0, function* () {
        const order = apolloClient.cache.readFragment({
            id: getUserOrderIdentifier(orderId),
            fragment: USER_ORDER_BASE_FRAGMENT,
        });
        if (!order) {
            return false;
        }
        const { data } = yield apolloClient.mutate({
            mutation: USER_ORDER_UPDATE_MUTATION,
            variables: {
                id: orderId,
                input: {
                    channel: order.channel,
                    sandbox: !order.sandbox,
                },
            },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userOrderUpdate.success)) {
                    return;
                }
                cache.modify({
                    id: getUserOrderIdentifier(orderId),
                    fields: {
                        sandbox() {
                            return !order.sandbox;
                        },
                    },
                });
            },
        });
        return !!(data === null || data === void 0 ? void 0 : data.userOrderUpdate.success);
    }), [apolloClient]);
    return { fetch, toggleSandbox };
};
export default useOrders;
