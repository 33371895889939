import env from '@happn/env';
const DEVICE_LOCAL_STORAGE_KEY = 'deviceId';
export class DeviceService {
    constructor() {
        this._deviceId = localStorage.getItem(DEVICE_LOCAL_STORAGE_KEY);
    }
    get deviceId() {
        return this._deviceId;
    }
    get deviceType() {
        return env.common.deviceType;
    }
    get languageId() {
        const separator = '-';
        if (!navigator.language.includes(separator)) {
            return navigator.language;
        }
        const [languageId] = navigator.language.split(separator);
        return languageId;
    }
    static getInstance() {
        if (!DeviceService._instance) {
            DeviceService._instance = new DeviceService();
        }
        return DeviceService._instance;
    }
    persistDeviceId(deviceId) {
        this._deviceId = deviceId;
        localStorage.setItem(DEVICE_LOCAL_STORAGE_KEY, deviceId);
    }
    deleteDeviceId() {
        this._deviceId = null;
        localStorage.removeItem(DEVICE_LOCAL_STORAGE_KEY);
    }
}
const deviceService = DeviceService.getInstance();
export default deviceService;
