export var KnownErrorCodes;
(function (KnownErrorCodes) {
    KnownErrorCodes[KnownErrorCodes["WrongPassword"] = 1014] = "WrongPassword";
    KnownErrorCodes[KnownErrorCodes["UnderEighteen"] = 1022] = "UnderEighteen";
    KnownErrorCodes[KnownErrorCodes["UserBlocked"] = 2002] = "UserBlocked";
    KnownErrorCodes[KnownErrorCodes["UserBanned"] = 1018] = "UserBanned";
    KnownErrorCodes[KnownErrorCodes["PictureNoFace"] = 2604] = "PictureNoFace";
    KnownErrorCodes[KnownErrorCodes["NotFound"] = 25002] = "NotFound";
    KnownErrorCodes[KnownErrorCodes["InvalidEmailFormat"] = 20001] = "InvalidEmailFormat";
    // Used in cities flow, when user tries to set
    // again his location or he does it to often then
    // he should get this error
    KnownErrorCodes[KnownErrorCodes["PositionAlreadySet"] = 12000] = "PositionAlreadySet";
    KnownErrorCodes[KnownErrorCodes["AccessDenied"] = 1055] = "AccessDenied";
})(KnownErrorCodes || (KnownErrorCodes = {}));
