import styled, { css } from 'styled-components';
import { getThemeColor, getThemeFont, ThemeColor, ThemeFont, } from '@happn/theme';
import Text from '../Text/Text';
export const Counter = styled(Text).attrs({ as: 'span' }) `
  text-align: center;
  font-size: 0.75rem;
  font-family: ${getThemeFont(ThemeFont.LaNordMedium)};
`;
// Variants
const buildVariant = (bgColor, borderColor, counterColor) => css `
  background-color: ${getThemeColor(bgColor)};

  ${({ theme, withBorder }) => withBorder &&
    `
    border: 0.1rem solid ${theme.colors[borderColor]};
  `}

  ${Counter} {
    color: ${getThemeColor(counterColor)};
  }
`;
const light = css `
  ${buildVariant(ThemeColor.White, ThemeColor.Dark, ThemeColor.Dark)}
`;
const dark = css `
  ${buildVariant(ThemeColor.Dark, ThemeColor.BrandLight, ThemeColor.BrandLight)}
  color: ${getThemeColor(ThemeColor.White)};
`;
const colored = css `
  ${buildVariant(ThemeColor.FillPrimary, ThemeColor.BrandLight, ThemeColor.Dark)}
`;
// Sizes
const xSmall = css `
  width: 0.75rem;
  height: 0.75rem;
`;
const small = css `
  width: 1rem;
  height: 1rem;
`;
const large = css `
  height: 1.5rem;
  width: 2rem;
  padding: 0.1rem 0.3rem;
`;
const xLarge = css `
  width: 2.4rem;
  padding: 0.3rem 0.5rem;
`;
const ActivityBadge = styled.span `
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ variant }) => {
    switch (variant) {
        case 'colored':
            return colored;
        case 'dark':
            return dark;
        case 'light':
        default:
            return light;
    }
}}

  ${({ size }) => {
    switch (size) {
        case 'x-small':
            return xSmall;
        case 'large':
            return large;
        case 'x-large':
            return xLarge;
        case 'small':
        default:
            return small;
    }
}}
`;
export default {
    ActivityBadge,
    Counter,
};
