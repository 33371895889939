import env from '@happn/env';
import { LogLevel } from './logger.types';
export class LoggerService {
    static getInstance() {
        if (!LoggerService._instance) {
            LoggerService._instance = new LoggerService();
        }
        return LoggerService._instance;
    }
    _log(logLevel) {
        return (error) => {
            if (env.common.isTesting || env.common.isProduction) {
                return error;
            }
            console.log(`${logLevel.toUpperCase()}: ` + error.message);
            return error;
        };
    }
    error(error) {
        return this._log(LogLevel.Error)(error);
    }
    warn(error) {
        return this._log(LogLevel.Warning)(error);
    }
    info(error) {
        return this._log(LogLevel.Info)(error);
    }
}
const loggerService = LoggerService.getInstance();
export default loggerService;
