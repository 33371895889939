import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LogoIcon, SliderIcon, HeartIcon, MessagesIcon, NotificationIcon, LocationIcon, } from '@happn/icons';
import Styled from './SideBar.styles';
export const config = ({ isUserPremium, firstPicture, pendingLikersCount, unreadConversationsCount, }) => {
    const premiumItem = !isUserPremium
        ? {
            translationKey: 'subscription_details_toolbar_title',
            testId: 'sidebar-item-premium',
            icon: _jsx(LogoIcon, {}, void 0),
            href: '/subscription-details',
        }
        : undefined;
    return [
        {
            translationKey: 'manage_myprofile_title',
            picture: firstPicture,
            href: '/my-profile',
            testId: 'sidebar-item-myprofile',
        },
        {
            translationKey: 'reborn_home_nav_bar_item_timeline',
            icon: _jsx(SliderIcon, {}, void 0),
            href: '/home',
            testId: 'sidebar-item-timeline',
        },
        {
            translationKey: 'navigation_header_map',
            icon: _jsx(LocationIcon, {}, void 0),
            href: '/coming-soon',
            testId: 'sidebar-item-map',
        },
        {
            translationKey: 'reborn_home_nav_bar_item_likes',
            icon: (_jsxs(Styled.IconWrapper, { children: [pendingLikersCount && (_jsx(Styled.LikesCounter, { size: "large", variant: "light", count: pendingLikersCount, withBorder: true }, void 0)), _jsx(HeartIcon, {}, void 0)] }, void 0)),
            href: isUserPremium ? '/likes' : '/subscription-details/premium',
            testId: 'sidebar-item-likes',
        },
        {
            translationKey: 'my_profile_crushes_counter_plural',
            testId: 'sidebar-item-crushs',
            icon: (_jsxs(Styled.IconWrapper, { children: [!!unreadConversationsCount && (_jsx(Styled.ConversationsPin, { "data-testid": "conversations-pin", size: "x-small", variant: "light", withBorder: true }, void 0)), _jsx(MessagesIcon, {}, void 0)] }, void 0)),
            href: '#',
        },
        {
            translationKey: 'home_bottom_bar_item_notifications',
            testId: 'sidebar-item-notifications',
            icon: _jsx(NotificationIcon, {}, void 0),
            href: '/notifications',
        },
        premiumItem,
    ];
};
