import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_SOCIAL_MEDIA_QUERY } from './gql';
export const DEFAULT_VALUES = {
    apple: {
        id: '',
        email: '',
    },
    google: {
        id: '',
        email: '',
    },
    facebook: {
        id: '',
        email: '',
    },
    instagram: {
        pictures: [],
    },
};
const useSocialMedia = (id = USER_ID_ME) => {
    var _a;
    const { data } = useQuery(USER_SOCIAL_MEDIA_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    return ((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.socialMedia) || DEFAULT_VALUES;
};
export default useSocialMedia;
