import apolloService, { dateReadFunction, picturesFieldPolicy, patchTypeIfExists, } from '@happn/apollo';
import { DeviceTypeName } from '@happn/devices';
import { toUserProfileModel, toUserModel, toUsersSearchModel, toUserUploadImageModel, toUserSaveTraitAnswerModel, toUserMobileIdsModel, toUserMobileTokensModel, toUserPhoneNumbersModel, toUserDevicesModel, toUserRecoveryInfoModel, toUserRecoveryTokenModel, toUserLikesReceivedModel, toUserCreditsModal, toUserOrdersModel, toSubscriptionsModel, toSubscriptionOrdersModel, toUserNotificationsModel, toUserArchivesModel, toUserArchiveDownloadLinkModel, toRelationshipsBetweenUsersModel, toUsersExportsModel, toUserPurchaseStatusModel, toUserAclsModel, toUserSegmentsModel, toPaymentIntents, toUserLatestSubscription, toGenderAliasesModel, toTeasersListModel, } from './mappers';
import { UserProfileTypeName, UserSaveTraitAnswerTypeName, UsersSearchTypeName, UserTypeName, UserUploadImageTypeName, UserMobileIdsTypeName, UserMobileTokensTypeName, UserPhoneNumbersTypeName, UserSubscriptionTypeName, UserDevicesTypeName, UserRecoveryInfoTypeName, UserAudioTypeName, UserRecoveryTokenTypeName, UserLikesReceivedTypeName, UserCreditsTypeName, UserOrderTypeName, UserOrdersTypeName, SubscriptionTypeName, SubscriptionOrderTypeName, UserNotificationTypeName, UserArchiveTypeName, UserArchiveDownloadLinkTypeName, RelationshipsBetweenUsersTypeName, UsersExportTypeName, UserPurchaseStatusTypeName, UserAclsTypeName, UserSegmentTypeName, UserPaymentIntentsTypeName, UserPaymentIntentTypeName, UserLatestSubscriptionTypeName, GenderAliasesTypeName, TeasersTypeName, } from './types';
export const apolloRegisterUser = () => {
    apolloService.registerTypePatcher({
        [UserTypeName]: (user, _outerType, patcher) => {
            return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, user), patchTypeIfExists(user, 'picture', 'ImageGto', patcher)), patchTypeIfExists(user, 'pictures', 'ImageGto', patcher)), patchTypeIfExists(user, 'subscription', 'UserSubscription', patcher)), patchTypeIfExists(user, 'audios', UserAudioTypeName, patcher));
        },
        [UserProfileTypeName]: (data, _outerType, patcher) => {
            return Object.assign(Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'position', 'ProfilePosition', patcher)), patchTypeIfExists(data, 'user', UserTypeName, patcher));
        },
        [UsersSearchTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'pagination', 'Pagination', patcher)), patchTypeIfExists(data, 'users', UserTypeName, patcher))),
        [UserDevicesTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'devices', DeviceTypeName, patcher))),
        [UserLikesReceivedTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'items', UserTypeName, patcher))),
        [UserOrdersTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'items', UserOrderTypeName, patcher))),
        [UserNotificationTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'user', UserTypeName, patcher))),
        [UserPaymentIntentsTypeName]: (data, _outerType, patcher) => (Object.assign(Object.assign({}, data), patchTypeIfExists(data, 'items', UserPaymentIntentTypeName, patcher))),
    });
    apolloService.registerTypePolicy(UserPaymentIntentTypeName, {
        fields: {
            creationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(UserLatestSubscriptionTypeName, {
        fields: {
            startDate: dateReadFunction,
            endDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(UserTypeName, {
        fields: {
            lastActivityDate: dateReadFunction,
            registerDate: dateReadFunction,
            lastPositionUpdate: dateReadFunction,
            pictures: picturesFieldPolicy,
        },
    });
    apolloService.registerTypePolicy(UserSubscriptionTypeName, {
        fields: {
            creationDate: dateReadFunction,
            expirationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(UserAudioTypeName, {
        fields: {
            creationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(UserOrderTypeName, {
        fields: {
            releaseDate: dateReadFunction,
            creationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(SubscriptionTypeName, {
        fields: {
            endDate: dateReadFunction,
            startDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(SubscriptionOrderTypeName, {
        fields: {
            purchaseDate: dateReadFunction,
            releaseDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(UsersExportTypeName, {
        fields: {
            exportDate: dateReadFunction,
        },
    });
    apolloService.registerMapper(UserTypeName, toUserModel);
    apolloService.registerMapper(UsersSearchTypeName, toUsersSearchModel);
    apolloService.registerMapper(UserProfileTypeName, toUserProfileModel);
    apolloService.registerMapper(UserUploadImageTypeName, toUserUploadImageModel);
    apolloService.registerMapper(UserSaveTraitAnswerTypeName, toUserSaveTraitAnswerModel);
    apolloService.registerMapper(UserMobileIdsTypeName, toUserMobileIdsModel);
    apolloService.registerMapper(UserMobileTokensTypeName, toUserMobileTokensModel);
    apolloService.registerMapper(UserPhoneNumbersTypeName, toUserPhoneNumbersModel);
    apolloService.registerMapper(UserDevicesTypeName, toUserDevicesModel);
    apolloService.registerMapper(UserRecoveryInfoTypeName, toUserRecoveryInfoModel);
    apolloService.registerMapper(UserRecoveryTokenTypeName, toUserRecoveryTokenModel);
    apolloService.registerMapper(UserLikesReceivedTypeName, toUserLikesReceivedModel);
    apolloService.registerMapper(UserCreditsTypeName, toUserCreditsModal);
    apolloService.registerMapper(UserOrdersTypeName, toUserOrdersModel);
    apolloService.registerMapper(SubscriptionTypeName, toSubscriptionsModel);
    apolloService.registerMapper(SubscriptionOrderTypeName, toSubscriptionOrdersModel);
    apolloService.registerMapper(UserNotificationTypeName, toUserNotificationsModel);
    apolloService.registerMapper(UserArchiveTypeName, toUserArchivesModel);
    apolloService.registerMapper(UserArchiveDownloadLinkTypeName, toUserArchiveDownloadLinkModel);
    apolloService.registerMapper(RelationshipsBetweenUsersTypeName, toRelationshipsBetweenUsersModel);
    apolloService.registerMapper(UsersExportTypeName, toUsersExportsModel);
    apolloService.registerMapper(UserPurchaseStatusTypeName, toUserPurchaseStatusModel);
    apolloService.registerMapper(UserAclsTypeName, toUserAclsModel);
    apolloService.registerMapper(UserSegmentTypeName, toUserSegmentsModel);
    apolloService.registerMapper(UserPaymentIntentsTypeName, toPaymentIntents);
    apolloService.registerMapper(UserLatestSubscriptionTypeName, toUserLatestSubscription);
    apolloService.registerMapper(GenderAliasesTypeName, toGenderAliasesModel);
    apolloService.registerMapper(TeasersTypeName, toTeasersListModel);
};
