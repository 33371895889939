import { gql } from '@apollo/client';
export const USER_BASE_FIELDS_QUERY = gql `
  query UserBaseFieldsQuery($id: String) {
    user(id: $id) {
      id
      firstName
      lastName
      age
      job
      school
      workplace
      birthdate
      about
      gender
      genderAlias
      hideGenderAlias
      residenceCity
    }
  }
`;
