import { gql } from '@apollo/client';
export const USER_LOCATION_CITY_QUERY = gql `
  query UserLocationCityQuery($id: String) {
    user(id: $id) {
      id
      locationCity {
        id
        city
        county
        country
        display
        position {
          longitude
          latitude
        }
      }
    }
  }
`;
