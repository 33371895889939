import { sanitize } from 'dompurify'
import i18n from 'i18next'
import backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import dateService from 'services/date'

import translation from './en.json'
import { languageDetector } from './i18n.languageDetector'
import { defaultLanguage, languageKeys } from './i18n.languages'

i18n
  .use(languageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation,
      },
    },
    fallbackLng: [defaultLanguage.key],
    defaultNS: 'translation',
    nsSeparator: false,
    interpolation: {
      escape: (str) => sanitize(str),
    },
    whitelist: languageKeys,
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    partialBundledLanguages: true,
    debug: process.env.DEBUG === 'true',
  })

i18n.on('languageChanged', dateService.changeLocale)
