var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import useErrors from '@happn/errors';
import loggerService from '@happn/logger';
import { USER_UPLOAD_IMAGE_MUTATION, USER_UPDATE_MUTATION, USER_ID_ME, USER_QUERY, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_PICTURES_QUERY } from './gql';
const usePictures = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_PICTURES_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const { add: addError } = useErrors();
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const pictures = (data === null || data === void 0 ? void 0 : data.user.pictures) || [];
    const firstPicture = (data === null || data === void 0 ? void 0 : data.user.picture) || null;
    const upload = useCallback((file) => __awaiter(void 0, void 0, void 0, function* () {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPLOAD_IMAGE_MUTATION,
                variables: {
                    id,
                    input: { file },
                    transform: (_body, headers) => ({
                        body: formData,
                        headers,
                    }),
                },
            });
            return (data === null || data === void 0 ? void 0 : data.userUploadImage) || null;
        }
        catch (err) {
            loggerService.error(new Error(`Could not upload image, ${err}`));
            return null;
        }
    }), [apolloClient, id]);
    const update = useCallback((newPictures) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                refetchQueries: [
                    {
                        query: USER_QUERY,
                        fetchPolicy: 'cache-only',
                        variables: { id },
                    },
                ],
                variables: {
                    id,
                    input: {
                        picture: newPictures[0],
                        profiles: newPictures,
                    },
                },
                update(cache, { data }) {
                    var _a;
                    if (!((_a = data === null || data === void 0 ? void 0 : data.userUpdate) === null || _a === void 0 ? void 0 : _a.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            picture() {
                                return newPictures[0];
                            },
                            pictures(_existing) {
                                return newPictures;
                            },
                        },
                    });
                },
            });
            if (!((_a = data === null || data === void 0 ? void 0 : data.userUpdate) === null || _a === void 0 ? void 0 : _a.success)) {
                return false;
            }
            return true;
        }
        catch (error) {
            loggerService.error(new Error(`Could not update pictures, ${error}`));
            addError({ error });
            return false;
        }
    }), [addError, apolloClient, id, userId]);
    return {
        firstPicture,
        pictures,
        upload,
        update,
    };
};
export default usePictures;
