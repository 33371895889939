import { MyRelation } from '../types';
export const getUserRelations = (user) => {
    return {
        isCharmed: user.is_charmed || false,
        hasCharmedMe: user.has_charmed_me || false,
        isAccepted: user.is_accepted || false,
        myRelations: user.my_relations
            ? {
                [MyRelation.Accepted]: user.my_relations.accepted || null,
                [MyRelation.Blocked]: user.my_relations.blocked || null,
                [MyRelation.Rejected]: user.my_relations.rejected || null,
                [MyRelation.Mutual]: user.my_relations.mutual || null,
            }
            : null,
    };
};
