import styled, { css } from 'styled-components';
import Text from '../Text/Text';
import { ThemeColor, getThemeColor } from '@happn/theme';
const Label = styled(Text).attrs({ variant: 'bodyMedium' }) `
  white-space: nowrap;
`;
const IconWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ iconLeft }) => iconLeft && css `margin-right: 0.5rem;`};
  ${({ iconRight }) => iconRight && css `margin-left: 0.5rem;`};
`;
const smallSize = css `
  padding: 0.375rem 0.5rem;
  border-radius: 0.375rem;

  ${IconWrapper} {
    ${({ iconLeft }) => iconLeft && css `margin-right: 0.25rem;`};
    ${({ iconRight }) => iconRight && css `margin-left: 0.25rem;`};

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
`;
const mediumSize = css `
  padding: 0.5rem;
  border-radius: 0.5rem;

  ${IconWrapper} {
    svg {
      width: 1rem;
      height: 1rem;
    }

    ${({ iconLeft }) => iconLeft && css `margin-right: 0.25rem;`};
    ${({ iconRight }) => iconRight && css `margin-left: 0.25rem;`};
  }
`;
const largeSize = css `
  padding: 0.875rem 0.75rem;
  border-radius: 0.75rem;

  ${IconWrapper} {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
const xLargeSize = css `
  padding: 1rem 0.75rem;
  border-radius: 0.75rem;

  ${IconWrapper} {
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
const coloredColor = css `
  background-color: ${getThemeColor(ThemeColor.FillPrimary)};
  
  ${Label}, ${IconWrapper} {
    color: ${getThemeColor(ThemeColor.Dark)};
  }

  ${({ isActive }) => !isActive && css `
    background-color: ${getThemeColor(ThemeColor.FillLightSubdued)};
  `};
`;
const lightColor = css `
  background-color: ${getThemeColor(ThemeColor.FillLight)};

  ${Label}, ${IconWrapper} {
    color: ${getThemeColor(ThemeColor.Dark)};
  }

  ${({ isActive }) => !isActive && css `
    background-color: ${getThemeColor(ThemeColor.FillLight, 0.6)};
  `};
`;
const darkColor = css `
  background-color: ${getThemeColor(ThemeColor.Dark)};

  ${Label}, ${IconWrapper} {
    color: ${getThemeColor(ThemeColor.Light)};
  }

  ${({ isActive }) => !isActive && css `
    background-color: ${getThemeColor(ThemeColor.Dark, 0.6)};
  `};
`;
const Pill = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  /* Size */
  ${({ size }) => {
    switch (size) {
        case 'small':
            return smallSize;
        case 'medium':
            return mediumSize;
        case 'large':
            return largeSize;
        default:
        case 'x-large':
            return xLargeSize;
    }
}}

  /* Color */
  ${({ color }) => {
    switch (color) {
        case 'colored':
            return coloredColor;
        case 'light':
            return lightColor;
        default:
        case 'dark':
            return darkColor;
    }
}}
`;
export default {
    IconWrapper,
    Label,
    Pill,
};
