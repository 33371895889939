import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_AUDIOS_QUERY } from './gql';
const useAudios = (id = USER_ID_ME) => {
    var _a;
    const { data } = useQuery(USER_AUDIOS_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    return {
        audios: ((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.audios) || [],
    };
};
export default useAudios;
