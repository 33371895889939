export const getUserCrossing = (gto) => {
    if (gto.last_meet_position == null ||
        gto.last_meet_position.lat == null ||
        gto.last_meet_position.lon == null) {
        return {
            crossingsCount: gto.crossing_nb_times || 0,
            lastMeetPosition: null,
        };
    }
    return {
        crossingsCount: gto.crossing_nb_times || 0,
        lastMeetPosition: {
            lat: gto.last_meet_position.lat,
            lon: gto.last_meet_position.lon,
            modificationDate: gto.last_meet_position.modification_date,
        },
    };
};
