var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { USER_RESET_RENEWABLE_TIMER, USER_QUERY, USER_ID_ME, } from '../../apollo';
export const useResetRenewableTimers = (userId = USER_ID_ME) => {
    const client = useApolloClient();
    const reset = useCallback((creditType) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const success = yield client.mutate({
                mutation: USER_RESET_RENEWABLE_TIMER,
                variables: {
                    id: userId,
                    type: creditType,
                    input: {},
                },
                refetchQueries: [USER_QUERY],
            });
            return (_a = success.data) === null || _a === void 0 ? void 0 : _a.userResetRenewableTimer.success;
        }
        catch (_b) {
            // fallthrough
        }
        return false;
    }), [client, userId]);
    return { reset };
};
export default useResetRenewableTimers;
