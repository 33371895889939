import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
export const UPDATE_TEASER_ANSWER_MUTATION = gql `
  mutation UpdateTeaserAnswer($id: ID!, $input: Any!) {
    teasersUpdate(id: $id, input: $input) @rest(
      path: "api/v2/users/{args.id}/traits/d972621e-d1d8-4352-9ff1-8a53f0b0cdfe",
      type: "${StatusResponseTypeName}",  
      method: "PUT"
    ) {
      success
    }
  }
`;
export const DELETE_TEASER_ANSWER_MUTATION = gql `
  mutation DeleteTeaserAnswer($userId: ID!, $optionId: String!) {
    deleteAnswer(userId: $userId, optionId: $optionId) @rest(
      path: "api/v2/users/{args.userId}/traits/d972621e-d1d8-4352-9ff1-8a53f0b0cdfe?option_ids={args.optionId}",
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
