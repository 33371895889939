var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { GqlTypes } from '@happn/types';
import { USER_UPDATE_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_GENDER_QUERY } from './gql';
const useGender = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_GENDER_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const gender = (data === null || data === void 0 ? void 0 : data.user.gender) || GqlTypes.Gender.Unknown;
    const update = useCallback((genderContext) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        gender: genderContext.toLowerCase(),
                    },
                },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            gender() {
                                return genderContext;
                            },
                        },
                    });
                },
            });
            return Boolean(data === null || data === void 0 ? void 0 : data.userUpdate.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not update gender, ${err}`));
            return false;
        }
    }), [apolloClient, id, userId]);
    return {
        gender,
        update,
    };
};
export default useGender;
