export var ThemeColor;
(function (ThemeColor) {
    ThemeColor["Primary"] = "primary";
    ThemeColor["Secondary"] = "secondary";
    ThemeColor["Tertiary"] = "tertiary";
    ThemeColor["Quaternary"] = "quaternary";
    ThemeColor["Quinary"] = "quinary";
    ThemeColor["Senary"] = "senary";
    ThemeColor["White"] = "white";
    ThemeColor["Happn"] = "happn";
    ThemeColor["HappnHover"] = "happnHover";
    ThemeColor["HappnDark"] = "happnDark";
    ThemeColor["HappnDarkHover"] = "happnDarkHover";
    ThemeColor["Like"] = "like";
    ThemeColor["LikeHover"] = "likeHover";
    ThemeColor["Alert"] = "alert";
    ThemeColor["AlertHover"] = "alertHover";
    ThemeColor["Success"] = "success";
    ThemeColor["SuccessHover"] = "successHover";
    ThemeColor["FlashNote"] = "flashNote";
    ThemeColor["Gold"] = "gold";
    ThemeColor["Warning"] = "warning";
    ThemeColor["WarningHover"] = "warningHover";
    ThemeColor["Overlay"] = "overlay";
    ThemeColor["Spotify"] = "spotify";
    ThemeColor["PrimaryDark"] = "primaryDark";
    ThemeColor["BrandLight"] = "brandLight";
    ThemeColor["BrandLavander"] = "brandLavander";
    ThemeColor["BrandSky"] = "brandSky";
    ThemeColor["Dark"] = "dark";
    ThemeColor["DarkMuted"] = "darkMuted";
    ThemeColor["DarkSubdued"] = "darkSubdued";
    ThemeColor["IconDarkSubdued"] = "iconDarkSubdued";
    ThemeColor["FillSuccess"] = "fillSuccess";
    ThemeColor["Error"] = "error";
    ThemeColor["TextPrimary"] = "textPrimary";
    ThemeColor["SecondaryDark"] = "secondaryDark";
    ThemeColor["ShopPremium"] = "shopPremium";
    ThemeColor["BrandMallow"] = "brandMallow";
    ThemeColor["LightDisabled"] = "lightDisabled";
    ThemeColor["BrandButter"] = "brandButter";
    ThemeColor["BrandDark"] = "brandDark";
    ThemeColor["FillPrimary"] = "fillPrimary";
    ThemeColor["FillLight"] = "fillLight";
    ThemeColor["DarkDisabled"] = "darkDisabled";
    ThemeColor["FillLightSubdued"] = "fillLightSubdued";
    ThemeColor["TextDark"] = "textDark";
    ThemeColor["TextDarkSubdued"] = "textDarkSubdued";
    ThemeColor["TextDarkDisabled"] = "textDarkDisabled";
    ThemeColor["BorderDark"] = "borderDark";
    ThemeColor["BorderDarkMuted"] = "borderDarkMuted";
    ThemeColor["BorderError"] = "borderError";
    ThemeColor["BorderLight"] = "borderLight";
    ThemeColor["FillLanding"] = "fillLanding";
    ThemeColor["BrandMustard"] = "brandMustard";
    ThemeColor["FillBrandLavender"] = "fillBrandLavender";
    ThemeColor["FillBrandSky"] = "fillBrandSky";
    ThemeColor["FillBrandMallow"] = "fillBrandMallow";
    ThemeColor["FillBrandButter"] = "fillBrandButter";
    ThemeColor["FillDarkMuted"] = "fillDarkMuted";
    ThemeColor["BorderLightSubdued"] = "borderLightSubdued";
    ThemeColor["BorderDarkDisabled"] = "borderDarkDisabled";
    ThemeColor["IconSuccess"] = "iconSuccess";
    ThemeColor["Light"] = "light";
    ThemeColor["IconLight"] = "iconLight";
    ThemeColor["IconDark"] = "iconDark";
    ThemeColor["FillDark"] = "fillDark";
    ThemeColor["FillDarkSubdued"] = "fillDarkSubdued";
    ThemeColor["FillWhite"] = "fillWhite";
    ThemeColor["TextLight"] = "textLight";
    ThemeColor["FillWhiteDisabled"] = "fillWhiteDisabled";
    ThemeColor["FillDarkPressed"] = "fillDarkPressed";
    ThemeColor["FillLightPressed"] = "fillLightPressed";
    ThemeColor["TextLightSubdued"] = "textLightSubdued";
})(ThemeColor || (ThemeColor = {}));
export var ThemeBreakpoint;
(function (ThemeBreakpoint) {
    ThemeBreakpoint["Small"] = "small";
    ThemeBreakpoint["Medium"] = "medium";
    ThemeBreakpoint["Large"] = "large";
})(ThemeBreakpoint || (ThemeBreakpoint = {}));
export var ThemeSize;
(function (ThemeSize) {
    ThemeSize["DesktopCardHeight"] = "desktopCardHeight";
    ThemeSize["DesktopHeaderHeight"] = "desktopHeaderHeight";
    ThemeSize["MobileNavigationHeight"] = "mobileNavigationHeight";
    ThemeSize["CrushTimeCardWidth"] = "crushTimeCardWidth";
    ThemeSize["CrushTimeCardHeight"] = "crushTimeCardHeight";
})(ThemeSize || (ThemeSize = {}));
export var ThemeGradient;
(function (ThemeGradient) {
    ThemeGradient["Happn"] = "happn";
    ThemeGradient["Boost"] = "boost";
    ThemeGradient["FlashNote"] = "flashNote";
    ThemeGradient["Gold"] = "gold";
    ThemeGradient["CrushTime"] = "crushTime";
    ThemeGradient["Like"] = "like";
    ThemeGradient["Gray"] = "gray";
    ThemeGradient["Turquoise"] = "turquoise";
    ThemeGradient["PauseLocation"] = "pauseLocation";
    ThemeGradient["HappnHover"] = "happnHover";
    ThemeGradient["Storm"] = "storm";
    ThemeGradient["ShopDesktop"] = "shopDesktop";
})(ThemeGradient || (ThemeGradient = {}));
export var ThemeFont;
(function (ThemeFont) {
    ThemeFont["Primary"] = "primary";
    ThemeFont["LibreBaskerville"] = "libreBaskerville";
    ThemeFont["HelveticaNeue"] = "helveticaNeue";
    ThemeFont["SofiaPro"] = "sofiaPro";
    ThemeFont["LaNordRegular"] = "laNordRegular";
    ThemeFont["LaNordMedium"] = "laNordMedium";
    ThemeFont["LaNordBold"] = "laNordBold";
    ThemeFont["SpykBlack"] = "spykBlack";
    ThemeFont["SpykBlackItalic"] = "spykBlackItalic";
})(ThemeFont || (ThemeFont = {}));
