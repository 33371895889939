export var DeviceAccuracyType;
(function (DeviceAccuracyType) {
    DeviceAccuracyType["Exact"] = "EXACT";
    DeviceAccuracyType["City"] = "CITY";
})(DeviceAccuracyType || (DeviceAccuracyType = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType[DeviceType["NoDevice"] = 0] = "NoDevice";
    DeviceType[DeviceType["IPhone"] = 1] = "IPhone";
    DeviceType[DeviceType["Android"] = 2] = "Android";
    DeviceType[DeviceType["Windows"] = 4] = "Windows";
    DeviceType[DeviceType["WebApp"] = 8] = "WebApp";
})(DeviceType || (DeviceType = {}));
export const DeviceTypeName = 'Device';
