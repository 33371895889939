import { useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { errorsVar } from '../errors';
const useErrors = () => {
    const errors = useReactiveVar(errorsVar);
    const add = useCallback(({ id = uuidV4(), error, overrideMessage }) => {
        const hasError = errors.some((e) => e.id === id ||
            e.error.message === error.message ||
            (overrideMessage && e.overrideMessage === overrideMessage));
        if (hasError) {
            return;
        }
        errorsVar([...errors, { id, error, overrideMessage }]);
    }, [errors]);
    const remove = useCallback((id) => {
        errorsVar(errors.filter((e) => e.id !== id));
    }, [errors]);
    return { errors, add, remove };
};
export default useErrors;
