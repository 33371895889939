import { nonUndefined } from '@happn/helpers';
export const getAudio = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.creation_date) ||
        !gto.id ||
        !gto.url ||
        !gto.topic ||
        gto.duration === undefined) {
        return undefined;
    }
    return {
        id: gto.id,
        creationDate: gto.creation_date,
        url: gto.url,
        durationInSeconds: gto.duration,
        topic: gto.topic,
    };
};
export const getAudios = (gto) => gto ? gto.map(getAudio).filter(nonUndefined) : [];
