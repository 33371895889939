import { nonUndefined } from '@happn/helpers';
export const getLocationCity = (city) => {
    var _a, _b, _c, _d, _e;
    if (!(city === null || city === void 0 ? void 0 : city.city) ||
        !(city === null || city === void 0 ? void 0 : city.id) ||
        ((_a = city === null || city === void 0 ? void 0 : city.position) === null || _a === void 0 ? void 0 : _a.latitude) === undefined ||
        ((_b = city === null || city === void 0 ? void 0 : city.position) === null || _b === void 0 ? void 0 : _b.longitude) === undefined) {
        return null;
    }
    return {
        id: city.id,
        city: city.city,
        county: (_c = city.county) !== null && _c !== void 0 ? _c : '',
        country: (_d = city.country) !== null && _d !== void 0 ? _d : '',
        display: (_e = city.display_city_name) !== null && _e !== void 0 ? _e : '',
        position: {
            latitude: city.position.latitude,
            longitude: city.position.longitude,
        },
    };
};
export const toCitiesModel = (response) => {
    var _a, _b;
    if (!response || !((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.cities) === null || _b === void 0 ? void 0 : _b.length)) {
        return [];
    }
    const cities = response.data.cities
        .map((d) => {
        var _a, _b, _c;
        if (!d.id ||
            ((_a = d.position) === null || _a === void 0 ? void 0 : _a.latitude) === undefined ||
            d.position.longitude === undefined ||
            !d.city ||
            !d.display_city_name) {
            return undefined;
        }
        const city = {
            id: d.id,
            city: d.city,
            display: d.display_city_name,
            county: (_b = d.county) !== null && _b !== void 0 ? _b : '',
            country: (_c = d.country) !== null && _c !== void 0 ? _c : '',
            position: {
                longitude: d.position.longitude,
                latitude: d.position.latitude,
            },
        };
        return city;
    })
        .filter(nonUndefined);
    return cities;
};
