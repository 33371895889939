import { gql } from '@apollo/client';
import { USER_UNREAD_FRAGMENT } from '../../../apollo';
export const USER_UNREAD_INFO_QUERY = gql `
  ${USER_UNREAD_FRAGMENT}
  query UserUnreadInfoQuery($id: String) {
    user(id: $id) {
      ...UserUnreadInfo
    }
  }
`;
