import styled, { css } from 'styled-components';
import { ThemeColor, ThemeFont, getThemeColor, getThemeFont, } from '@happn/theme';
const TextFieldWrapper = styled.div `
  position: relative;
  ${({ disabled }) => disabled &&
    css `
      cursor: not-allowed;
    `}
`;
const TextField = styled.input `
  padding: 1.25rem 3.25rem;
  padding-left: ${({ iconLeft }) => (iconLeft ? '3.25rem' : '1rem')};
  padding-right: ${({ iconRight }) => (iconRight ? '3.25rem' : '1rem')};
  border: 0.125rem solid ${getThemeColor(ThemeColor.BorderDark)};
  border-radius: 1rem;
  font-family: ${getThemeFont(ThemeFont.LaNordRegular)};
  font-size: 0.875rem;
  line-height: 1.25rem;
  min-width: 18.75rem;
  outline-color: ${getThemeColor(ThemeColor.BorderDark)};
  outline-width: 1.25rem;

  ${({ fieldSize }) => fieldSize === 'medium' &&
    css `
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
    `}

  ${({ hasError }) => hasError &&
    css `
      outline-color: ${getThemeColor(ThemeColor.BorderError)};
      border-color: ${getThemeColor(ThemeColor.BorderError)};
    `}

  ${({ disabled }) => disabled &&
    css `
      ::placeholder {
        color: ${getThemeColor(ThemeColor.TextDarkDisabled)};
      }
    `}
`;
const IconWrapper = styled.div `
  position: absolute;
  left: ${({ left }) => (left ? left : 'unset')};
  right: ${({ right }) => (right ? right : 'unset')};
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
export default { IconWrapper, TextField, TextFieldWrapper };
