import { gql } from '@apollo/client';
export const USER_LOCATION_PREFERENCES_QUERY = gql `
  query UserLocationPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        location {
          hide
        }
      }
    }
  }
`;
