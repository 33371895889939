var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { equals } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useIdentities } from '@happn/identities';
import { USER_ID_ME, USER_MOBILE_TOKENS_DELETE_MUTATION, USER_MOBILE_TOKENS_QUERY, } from '../../apollo';
import { getUserMobileTokensIdentifier } from '../../helpers';
const useMobileTokens = (id = USER_ID_ME) => {
    var _a;
    const apolloClient = useApolloClient();
    const { patch } = useIdentities();
    const { data } = useQuery(USER_MOBILE_TOKENS_QUERY, { fetchPolicy: 'cache-only', variables: { id } });
    const mobileTokens = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.userMobileTokens) === null || _a === void 0 ? void 0 : _a.mobileTokens) || []; }, [(_a = data === null || data === void 0 ? void 0 : data.userMobileTokens) === null || _a === void 0 ? void 0 : _a.mobileTokens]);
    const update = useCallback((newMobileTokens) => __awaiter(void 0, void 0, void 0, function* () {
        if (equals(mobileTokens, newMobileTokens)) {
            return true;
        }
        const needToDelete = mobileTokens.filter((mobileToken) => !newMobileTokens.includes(mobileToken)).length > 0;
        if (needToDelete) {
            const { data: deleteData } = yield apolloClient.mutate({ mutation: USER_MOBILE_TOKENS_DELETE_MUTATION, variables: { id } });
            if (!(deleteData === null || deleteData === void 0 ? void 0 : deleteData.userMobileTokensDelete.success)) {
                return false;
            }
        }
        const filteredMobileTokens = needToDelete
            ? newMobileTokens
            : newMobileTokens.filter((mobileToken) => !mobileTokens.includes(mobileToken));
        const patchResult = yield patch({
            userId: id,
            mobileTokens: filteredMobileTokens,
        });
        if (!patchResult) {
            return false;
        }
        apolloClient.cache.modify({
            id: getUserMobileTokensIdentifier(id),
            fields: {
                mobileTokens() {
                    return newMobileTokens;
                },
            },
        });
        return true;
    }), [apolloClient, id, mobileTokens, patch]);
    return { mobileTokens, update };
};
export default useMobileTokens;
