import { gql } from '@apollo/client';
import { USER_SOCIAL_MEDIA_FRAGMENT } from '../../../apollo';
export const USER_SOCIAL_MEDIA_QUERY = gql `
  ${USER_SOCIAL_MEDIA_FRAGMENT}

  query UserSocialMediaQuery($id: String) {
    user(id: $id) {
      ...UserSocialMedia
    }
  }
`;
