import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_LAST_ACCEPTED_VERSIONS_QUERY, } from './gql';
const useLastAcceptedVersions = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_LAST_ACCEPTED_VERSIONS_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    return ((data === null || data === void 0 ? void 0 : data.user.lastAcceptedVersions) || {
        tos: '',
        sdc: '',
        cookieV1: '',
        cookieV2: '',
        cookieV3: '',
    });
};
export default useLastAcceptedVersions;
