var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const CF_COUNTRY_HEADER = 'cf-ipcountry';
/**
 * getCfCountry calls fetch on `/` uri to get the
 * ip country header forwarded by CloudFlare worker
 */
export const getCfCountry = () => __awaiter(void 0, void 0, void 0, function* () { return (yield fetch('/')).headers.get(CF_COUNTRY_HEADER) || ''; });
/**
 * getUserCountry tries to get user country from the navigator.language.
 * When the language is in format aa-BB we take only the BB part.
 * For format like xx we take the first two chars.
 */
export const getBrowserCountry = () => {
    const langRaw = navigator.language;
    if (!langRaw) {
        return '';
    }
    // when we have language in format en-US
    // take only the last part
    if (langRaw.length === 5) {
        return langRaw.substring(3);
    }
    const lang = langRaw.substring(0, 2);
    return lang.length !== 2 ? '' : lang;
};
