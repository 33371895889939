import { useQuery } from '@apollo/client';
import { USER_RECOVERY_TOKEN_QUERY, USER_ID_ME, } from '../../apollo';
const useRecoveryToken = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_RECOVERY_TOKEN_QUERY, {
        fetchPolicy: 'no-cache',
        // Need to pass empty object since no input for POST
        // but @rest throw err that is required.
        variables: { id, input: {} },
    });
    return {
        recoveryUrl: (data === null || data === void 0 ? void 0 : data.userRecoveryToken.recoveryUrl) || null,
    };
};
export default useRecoveryToken;
