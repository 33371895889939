import { ApiTypes } from '@happn/types';
export const shopProductPackInformationCredit = {
    type: ApiTypes.CreditTypeGto.Like,
    amount: 5,
};
export const packInformation = {
    credits: [shopProductPackInformationCredit],
};
export const shopProductPack = {
    activated: false,
    reactivation: false,
    channel: ApiTypes.ProductGtoChannelEnum.INTERNAL,
    creationDate: new Date('2021-01-01'),
    defaultProduct: false,
    description: '',
    id: '123',
    introductory: false,
    modificationDate: new Date('2021-01-01'),
    name: 'pack',
    packInformation,
    position: 0,
    productType: ApiTypes.ProductGtoProductTypeEnum.PACK,
    storeProductId: '',
};
export const recurrencePeriod = {
    unit: ApiTypes.PeriodGtoUnitEnum.MONTH,
    value: 5,
};
export const introductoryPlan = {
    cycles: 5,
    prices: [],
    recurrencePeriod,
};
export const recurrenceSubscriptionPeriod = {
    unit: ApiTypes.PeriodGtoUnitEnum.DAY,
    value: 3,
};
export const planInformationCredit = {
    cooldownPeriod: 100,
    renewablePerPeriod: 10,
    type: ApiTypes.CreditTypeGto.Credit,
};
export const shopProductPlanInformation = {
    recurrenceSubscriptionPeriod,
    credits: [planInformationCredit],
    prices: [],
    introductoryPlan,
};
export const shopProductPlan = {
    activated: false,
    reactivation: false,
    channel: ApiTypes.ProductGtoChannelEnum.INTERNAL,
    creationDate: new Date('2021-01-01'),
    defaultProduct: false,
    description: '',
    id: 'plan',
    introductory: false,
    modificationDate: new Date('2021-01-01'),
    name: 'abc',
    planInformation: shopProductPlanInformation,
    position: 0,
    productType: ApiTypes.ProductGtoProductTypeEnum.PLAN,
    storeProductId: '',
};
export const shopProductGroup = {
    id: '8a886930-1852-11e7-bdea-0d31b94a5ddc',
    name: 'AR Group',
    description: 'AR Group',
    productIds: [
        'd2933560-b459-11e5-8d4a-b9f5925dc5ce',
        'd293d1a0-b459-11e5-8d4a-b9f5925dc5ce',
    ],
    count: 2,
    productType: ApiTypes.ProductGtoProductTypeEnum.PACK,
    creationDate: new Date('2017-04-03T09:47:24'),
    modificationDate: new Date('2017-04-03T09:47:24'),
};
export const shopRule = {
    activationDate: new Date('2020-10-20'),
    creationDate: '2020-10-20',
    modificationDate: '2020-10-20',
    description: 'some desc',
    filters: {
        filter_type: ApiTypes.ShopInAppRulesFilterTypeNameEnum.Or,
        filters: [],
    },
    id: '123',
    name: 'shop rule',
    priority: 10,
    productGroups: {
        packs: [],
        plans: [],
    },
    type: ApiTypes.ShopRulesTypeEnum.Standard,
    activated: false,
};
