import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
import { USER_PICTURE_FIELDS } from './fields';
import { UserArchiveTypeName, UserTypeName, UserUploadImageTypeName } from './types';
export const USER_BAN_MUTATION = gql `
  mutation BanUser($id: ID!) {
    banUser(id: $id) @rest(
      path: "api/users/{args.id}?to_ban=true", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_UNBAN_MUTATION = gql `
  mutation UnbanUser($id: ID!) {
    unbanUser(id: $id, input:"todo=unban") @rest(
      path: "api/users/{args.id}", 
      type: "${StatusResponseTypeName}",  
      method: "PUT"
      bodySerializer: "formSerializer"
    ) {
      success
    }
  }
`;
export const USER_DELETE_MUTATION = gql `
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) @rest(
      path: "api/users/{args.id}?to_delete=true", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_CERTIFICATION_MUTATION = gql `
  mutation CertifyUser($id: ID!) {
    certifiedUser(id: $id) @rest(
      path: "api/v1/test-utils/users/{args.id}/verify",
      type: "${StatusResponseTypeName}",
      method: "PUT"
      bodyKey: "id"
    ) {
      success
    }
  }
`;
export const USER_DEACTIVATE_MUTATION = gql `
  mutation UserDeactivateMutation($id: ID!) {
    userDeactivate(id: $id) @rest(
      path: "api/users/{args.id}", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_ACTIVATE_MUTATION = gql `
  mutation UserActivateMutation($id: ID!, $input: any) {
    userActivate(id: $id, input: $input) @rest(
      path: "api/users/{args.id}", 
      type: "${StatusResponseTypeName}",  
      method: "PATCH"
    ) {
      success
    }
  }
`;
export const USER_UPDATE_MUTATION = gql `
  mutation UserUpdate($id: ID!, $input: Any!) {
    userUpdate(id: $id, input: $input) @rest(
      path: "api/users/{args.id}", 
      type: "${StatusResponseTypeName}",
      method: "PUT"
    ) {
      success
    }
  }
`;
export const USER_UPLOAD_IMAGE_MUTATION = gql `
  mutation UserUploadImageMutation($id: ID!, $input: any, $transform: any) {
    userUploadImage(id: $id, input: $input, transform: $transform) @rest (
      type: "${UserUploadImageTypeName}"
      path: "api/users/{args.id}/images?fields=${USER_PICTURE_FIELDS.join('')}",
      method: "POST"
      bodySerializer: $transform,
    ) {
      id
      url
      isDefault
    }
  }
`;
export const USER_MOBILE_IDS_DELETE_MUTATION = gql `
  mutation UserMobileIdsDeleteMutation($id: ID!) {
    userMobileIdsDelete(id: $id) @rest(
      path: "api/users/{args.id}/mobile-ids", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_MOBILE_TOKENS_DELETE_MUTATION = gql `
  mutation UserMobileTokensDeleteMutation($id: ID!) {
    userMobileTokensDelete(id: $id) @rest(
      path: "api/users/{args.id}/mobile-tokens", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_BALANCE_UPDATE_MUTATION = gql `
    mutation UserBalanceUpdateMutation($input: Any!) {
        userBalanceUpdate(input: $input) @rest(
            type: "${StatusResponseTypeName}"
            path: "api/credit-campaigns/",
            method: "POST"
        ) {
            success
        }
    }
`;
export const USER_INTERNAL_SUBSCRIPTION_ADD_MUTATION = gql `
  mutation UserInternalSubscriptionAddMutation($id: ID!, $input: Any!) {
    userInternalSubscriptionAdd(id: $id, input: $input) @rest(
      type: "${StatusResponseTypeName}"
      path: "api/users/{args.id}/subscriptions/internal"
      method: "POST"
    ) {
      success
    }
  }
`;
export const USER_RESET_CRUSH_TIME_MUTATION = gql `
  mutation UserResetCrushTimeMutation($id: ID!) {
    resetCrushTimeUser(id: $id) @rest(
      path: "api/v1/test-utils/users/{args.id}/crush-time/boards",
      type: "${StatusResponseTypeName}",
      method: "DELETE"
      bodyKey: "id"
    ) {
      success
    }
  }
`;
export const USER_RESET_RENEWABLE_TIMER = gql `
  mutation UserResetRenewableTimerMutation($id: ID!, $type: String!, $input: Any) {
    userResetRenewableTimer(id: $id, type: $type, input: $input) @rest (
      type: "${StatusResponseTypeName}"
      path: "api/users/{args.id}/credits/renewable/reset?credit_type={args.type}"
      method: "POST"
    ) {
      success
    }
  }
`;
export const USER_SPONSOR_MUTATION = gql `
  mutation UserSponsorMutation($id: ID!, $input: Any) {
    userSponsor(id: $id, input: $input) @rest (
      type: "${StatusResponseTypeName}"
      path: "api/users/{args.id}"
      method: "PUT"
    ) {
      success
    }
  }
`;
export const USER_ORDER_UPDATE_MUTATION = gql `
  mutation UserOrderUpdateMutation($id: ID, $input: Any) {
    userOrderUpdate(id: $id, input: $input) @rest(
      type: "${StatusResponseTypeName}"
      path: "api/orders/{args.id}"
      method: "PATCH"
    ) {
      success
    }
  }
`;
export const USER_CLOSE_ALL_REPORTS_MUTATION = gql `
  mutation UserCloseAllReportsMutation($id: ID) {
    userCloseAllReports(id: $id, input:"todo=close") @rest(
      type: "${StatusResponseTypeName}"
      path: "api/users/{args.id}/reports"
      method: "PUT"
      bodySerializer: "formSerializer"
    ) {
      success
    }
  }
`;
export const USER_ARCHIVE_CREATE_MUTATION = gql `
  mutation UserArchiveCreateMutation($id: ID, $input: Any, $transform: Any) {
    userArchiveCreate(id: $id, input: $input transform: $transform) @rest(
      type: "${UserArchiveTypeName}"
      path: "api/users/{args.id}/archives"
      method: "POST"
      bodySerializer: $transform
      endpoint: "bi"
    ) {
      id
      archiveReason
      archiveState
      archiveType
      isDone
      creationDate
      processStartDate
      requesterComment
      requesterId
      userId
    }
  }
`;
export const USER_CREATE_MUTATION = gql `
  mutation UserCreateMutation($input: Any) {
    userCreate( input: $input) @rest(
      type: "${UserTypeName}"
      path: "api/users"
      method: "POST"
    ) {
      id
      firstName
      lastName
    }
  }
`;
export const USERS_EXPORT_CREATE_MUTATION = gql `
  mutation UsersExportCreateMutation($fields: string, $input: Any) {
    usersExportCreate(fields: $fields, input: $input) @rest(
      type: "${StatusResponseTypeName}"
      path: "api/v1/users-exports?fields={args.fields}"
      method: "POST"
    ) {
      success
    }
  }
`;
export const USER_UPDATE_ACLS_MUTATION = gql `
  mutation UserUpdateAclsMutation($id: string, $input:string) {
    userUpdateAcls(id: $id, input: $input) @rest(
      path: "api/user-acls/{args.id}", 
      type: "${StatusResponseTypeName}",  
      method: "POST"
      bodySerializer: "formSerializer"
    ) {
      success
    }
  }
`;
export const USER_SOCIAL_IDENTITY_DELETE_MUTATION = gql `
  mutation UserSocialSynchronizationDeleteMutation($userId: ID!, $snName: string) {
    userSocialSynchronizationDelete(userId: $userId, snName: $snName) @rest(
      path: "admin/api/v1/users/{args.userId}/social-synchronizations/{args.snName}", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
export const USER_LIST_OF_LIKES_CLEANUP_MUTATION = gql `
  mutation CleanupLikesList($id: ID!) {
    cleanupLikesList(id: $id) @rest(
      path: "api/v1/users/{args.id}/relationships",
      type: "${StatusResponseTypeName}",
      method: "DELETE"
    ) {
      success
    }
  }
`;
