import apolloService, { dateReadFunction, patchTypeIfExists, } from '@happn/apollo';
import { toShopCheckoutSessionModel, toShopProductGroupModel, toShopProductModel, toShopModel, toShopRulesModel, toShopPaymentPortalModel, } from './mapper';
import { ShopCheckoutSessionTypeName, ShopProductGroupTypeName, ShopTypeName, ShopProductTypeName, ShopRuleTypeName, ShopPaymentPortalTypeName, } from './types';
export const apolloRegisterShop = () => {
    apolloService.registerTypePatcher({
        [ShopTypeName]: (shop, _outerType, patcher) => {
            return Object.assign(Object.assign({}, shop), patchTypeIfExists(shop, 'products', ShopProductTypeName, patcher));
        },
    });
    apolloService.registerMapper(ShopProductGroupTypeName, toShopProductGroupModel);
    apolloService.registerMapper(ShopProductTypeName, toShopProductModel);
    apolloService.registerMapper(ShopRuleTypeName, toShopRulesModel);
    apolloService.registerMapper(ShopTypeName, toShopModel);
    apolloService.registerMapper(ShopCheckoutSessionTypeName, toShopCheckoutSessionModel);
    apolloService.registerMapper(ShopPaymentPortalTypeName, toShopPaymentPortalModel);
    apolloService.registerTypePolicy(ShopProductGroupTypeName, {
        fields: {
            modificationDate: dateReadFunction,
            creationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(ShopProductTypeName, {
        fields: {
            modificationDate: dateReadFunction,
            creationDate: dateReadFunction,
        },
    });
    apolloService.registerTypePolicy(ShopRuleTypeName, {
        fields: {
            modificationDate: dateReadFunction,
            creationDate: dateReadFunction,
        },
    });
};
