import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
export const PATCH_IDENTITY_MUTATION = gql `
    mutation PatchIdentityMutation($id: String, $input: Any) {
        patchIdentity(id: $id, input: $input) @rest(
            type: "${StatusResponseTypeName}" 
            path: "api/identities/{args.id}" 
            method: "PATCH"
        ) {
            success
        }
    }
`;
