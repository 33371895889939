import { nonNil } from '@happn/helpers';
export const getDevicePosition = (gto) => {
    if (!gto) {
        return null;
    }
    return {
        alt: gto.alt || null,
        circleSize: gto.circle_size || null,
        hacc: gto.hacc || null,
        latitude: gto.latitude || null,
        locationAccuracy: gto.location_accuracy,
        longitude: gto.longitude || null,
        vacc: gto.vacc || null,
    };
};
export const getDevice = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.id)) {
        return null;
    }
    return {
        id: gto.id,
        country: gto.country || '',
        countryId: gto.country_id || '',
        appBuild: gto.app_build || '',
        language: gto.language || '',
        languageId: gto.language_id || '',
        modificationDate: gto.modification_date || null,
        osBuild: gto.os_build || '',
        status: gto.status || null,
        type: gto.type || '',
        position: getDevicePosition(gto.position),
    };
};
export const toDeviceModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return null;
    }
    if (Array.isArray(response.data)) {
        return response.data.map(getDevice).filter(nonNil);
    }
    return getDevice(response.data);
};
