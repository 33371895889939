import { gql } from '@apollo/client';
import { USER_AUDIO_FRAGMENT } from '../../../apollo';
export const USER_AUDIOS_QUERY = gql `
  ${USER_AUDIO_FRAGMENT}

  query UserAudiosQuery($id: String) {
    user(id: $id) {
      id
      audios {
        ...UserAudio
      }
    }
  }
`;
