import styled, { css } from 'styled-components';
import { background, compose } from 'styled-system';
import { ThemeColor, getThemeColor } from '@happn/theme';
import PolisAvatar from '../Avatar/Avatar';
import PolisButton, { ButtonSize } from '../Button';
import Text from '../Text/Text';
const Avatar = styled(PolisAvatar) `
  margin-bottom: 1rem;
`;
const AvatarSet = styled.div `
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  & > * {
    &:nth-of-type(odd) {
      transform: rotate(-5deg);
      transform: translateX(0.25rem) rotate(-5deg);
    }

    &:nth-of-type(even) {
      transform: translateX(-0.25rem) rotate(5deg);
    }
  }
`;
const Title = styled(Text) `
  margin-bottom: 0.25rem;
  text-align: center;
`;
const Description = styled(Text) `
  margin-bottom: 1rem;
  text-align: center;
`;
const Button = styled(PolisButton).attrs({ size: ButtonSize.Medium }) ``;
const LiniVariant = css `
  padding: 1rem;
  height: auto;
  max-width: 10rem;
  border-radius: 1.5rem;

  ${Title}, ${Description}, ${Avatar} {
    margin-right: auto;
  }

  ${Title} {
    text-align: left;
  }
`;
const LargeVariant = css `
  height: auto;
  min-width: 15.9rem;
  padding: 2rem;

  ${Title}, ${Description}, ${Avatar}, ${AvatarSet} {
    margin-right: auto;
  }

  ${Title} {
    text-align: left;
  }

  ${Button} {
    margin-left: auto;
    margin-top: 1.5rem;
  }
`;
const CardAction = styled.div `
  cursor: pointer;
  padding: 3.25rem 2rem;
  background-color: ${({ backgroundColor }) => backgroundColor
    ? getThemeColor(backgroundColor)
    : getThemeColor(ThemeColor.FillBrandLavender)};
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
  }

  ${({ variant }) => {
    switch (variant) {
        case 'large':
            return LargeVariant;
        case 'lini':
            return LiniVariant;
        default:
            return ``;
    }
}}

  ${compose(background)}
`;
export default { Avatar, AvatarSet, Button, CardAction, Description, Title };
