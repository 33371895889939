var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { USER_CLOSE_ALL_REPORTS_MUTATION, USER_ID_ME, } from '../../apollo';
const useReports = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const closeAll = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.mutate({ mutation: USER_CLOSE_ALL_REPORTS_MUTATION, variables: { id } });
        return data === null || data === void 0 ? void 0 : data.userCloseAllReports.success;
    }), [apolloClient, id]);
    return { closeAll };
};
export default useReports;
