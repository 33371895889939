import getExpiration from '../getExpiration';
const isValid = (jwtToken) => {
    const exp = getExpiration(jwtToken);
    const now = Math.round(Date.now() / 1000);
    if (!exp) {
        return false;
    }
    return exp - now > 0;
};
export default isValid;
