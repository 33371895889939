import styled from 'styled-components'

import { getThemeColor, ThemeColor } from '@happn/theme'
import { Lottie } from '@happn/animations'

const Outer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: ${getThemeColor(ThemeColor.FillLanding)};
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Image = styled(Lottie)`
  width: 5rem;
  height: 7.3rem;
`

export default { Outer, Image }
