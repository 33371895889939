import { useQuery } from '@apollo/client';
import { SHOP_PAYMENT_PORTAL_QUERY, } from '../apollo';
const usePaymentPortal = () => {
    var _a;
    const { data, loading } = useQuery(SHOP_PAYMENT_PORTAL_QUERY, {
        variables: {
            returnUrl: window.location.origin,
        },
    });
    return {
        loading,
        url: ((_a = data === null || data === void 0 ? void 0 : data.shopPaymentPortal) === null || _a === void 0 ? void 0 : _a.url) || '',
    };
};
export default usePaymentPortal;
