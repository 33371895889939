import styled from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
import Text from '../../Text/Text';
const IconWrapper = styled.div `
  display: block;
  margin-right: 1.125rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Title = styled(Text).attrs({ variant: 'headline2Regular' }) ``;
const Item = styled.li `
  margin: 0 auto 1.25rem 0;
  width: 100%;
  list-style: none;

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:focus-visible {
      outline: none;
      background-color: ${getThemeColor(ThemeColor.Secondary)};
      border-radius: 0.75rem;

      ${Title}, ${IconWrapper} {
        color: ${getThemeColor(ThemeColor.TextLight)};
      }
    }
  }

  ${Title}, ${IconWrapper} {
    transition: color 0.3s ease-in;
    color: ${({ isActive, theme }) => isActive
    ? `${theme.colors.textLight}`
    : `${theme.colors.textDarkSubdued}`};
  }

  &:hover {
    ${Title}, ${IconWrapper} {
      color: ${getThemeColor(ThemeColor.TextLight)};
    }
  }
`;
export default { IconWrapper, Item, Title };
