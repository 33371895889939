import { gql } from '@apollo/client';
export const USER_VERIFICATION_QUERY = gql `
  query UserVerificationQuery($id: String) {
    user(id: $id) {
      id
      verification {
        status
        reason
      }
    }
  }
`;
