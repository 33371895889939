import camelCase from 'camelcase';
import { nonUndefined, getLocalized } from '@happn/helpers';
import { ApiTypes } from '@happn/types';
export const formatHeight = (m) => {
    const cm = Math.round(m * 100);
    const ftFormatted = toFeet(cm);
    return `${cm} cm (${ftFormatted})`;
};
function toFeet(cm) {
    var realFeet = (cm * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return `${feet}’${inches}”`;
}
export const getFilteredAnswers = (filteredAnswers) => {
    var _a, _b;
    switch (filteredAnswers === null || filteredAnswers === void 0 ? void 0 : filteredAnswers.type) {
        case ApiTypes.TraitTypeGto.FLOATRANGE: {
            const { float_range } = filteredAnswers;
            return {
                type: ApiTypes.TraitTypeGto.FLOATRANGE,
                floatRange: {
                    minValue: (float_range === null || float_range === void 0 ? void 0 : float_range.min_value) || 0,
                    maxValue: (float_range === null || float_range === void 0 ? void 0 : float_range.max_value) || 0,
                },
            };
        }
        case ApiTypes.TraitTypeGto.SINGLE: {
            const singleOptions = filteredAnswers;
            return {
                type: ApiTypes.TraitTypeGto.SINGLE,
                single: {
                    values: ((_b = (_a = singleOptions.single) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.map((v) => v.id).filter(nonUndefined)) || [],
                },
            };
        }
    }
    return undefined;
};
export const getTraitOption = (option) => {
    var _a, _b;
    switch (option === null || option === void 0 ? void 0 : option.type) {
        case ApiTypes.TraitTypeGto.FLOATRANGE: {
            const { float_range } = option;
            return {
                type: ApiTypes.TraitTypeGto.FLOATRANGE,
                floatRange: {
                    minValue: (float_range === null || float_range === void 0 ? void 0 : float_range.min_value) || 0,
                    maxValue: (float_range === null || float_range === void 0 ? void 0 : float_range.max_value) || 0,
                    defaultValue: (float_range === null || float_range === void 0 ? void 0 : float_range.default_value) || 0,
                    step: (float_range === null || float_range === void 0 ? void 0 : float_range.step) || 0,
                    metric: (float_range === null || float_range === void 0 ? void 0 : float_range.metric) || '',
                },
            };
        }
        case ApiTypes.TraitTypeGto.SINGLE: {
            const singleOptions = option;
            return {
                type: ApiTypes.TraitTypeGto.SINGLE,
                single: ((_b = (_a = singleOptions.single) === null || _a === void 0 ? void 0 : _a.values) === null || _b === void 0 ? void 0 : _b.map((v) => ({
                    id: v.id || '',
                    label: getLocalized(v.default_label, v.label_localized),
                }))) || [],
            };
        }
    }
    return undefined;
};
export const getTraitAnswer = (answer) => {
    var _a, _b, _c, _d;
    if (!answer || !answer.type) {
        return { answer: '' };
    }
    switch (answer.type) {
        case ApiTypes.TraitTypeGto.FLOATRANGE: {
            if (((_a = answer.float_range) === null || _a === void 0 ? void 0 : _a.metric) !== 'length' ||
                !answer.float_range.value) {
                return { answer: '' };
            }
            return {
                answer: formatHeight(answer.float_range.value),
                rawAnswer: {
                    type: ApiTypes.TraitTypeGto.FLOATRANGE,
                    float_range: {
                        value: answer.float_range.value,
                        metric: answer.float_range.metric,
                    },
                },
            };
        }
        case ApiTypes.TraitTypeGto.SINGLE: {
            return {
                answer: getLocalized((_b = answer.single) === null || _b === void 0 ? void 0 : _b.default_label, (_c = answer.single) === null || _c === void 0 ? void 0 : _c.label_localized),
                rawAnswer: ((_d = answer.single) === null || _d === void 0 ? void 0 : _d.id)
                    ? {
                        type: ApiTypes.TraitTypeGto.SINGLE,
                        single: { id: answer.single.id },
                    }
                    : null,
            };
        }
    }
};
export const getTraitV2Answer = (trait) => {
    if (!(trait === null || trait === void 0 ? void 0 : trait.type)) {
        return { answer: '' };
    }
    const { answer, answers, type } = trait;
    switch (type) {
        case ApiTypes.TraitTypeGto.FLOATRANGE: {
            const floatRangeAnswer = answer;
            if ((floatRangeAnswer === null || floatRangeAnswer === void 0 ? void 0 : floatRangeAnswer.metric) !== 'length' || !floatRangeAnswer.value) {
                return { answer: '' };
            }
            return {
                answer: formatHeight(floatRangeAnswer.value),
                rawAnswer: {
                    type: ApiTypes.TraitV2TypeGTO.FLOAT_RANGE,
                    float_range: {
                        value: floatRangeAnswer.value,
                        metric: floatRangeAnswer.metric,
                    },
                },
            };
        }
        case ApiTypes.TraitV2TypeGTO.SINGLE: {
            const singleAnswer = answer;
            return {
                answer: singleAnswer,
            };
        }
        case ApiTypes.TraitV2TypeGTO.MULTI: {
            const multiAnswers = answers;
            return {
                answer: '',
                answers: multiAnswers.map((answer) => {
                    var _a, _b;
                    return ({
                        categoryId: (_a = answer === null || answer === void 0 ? void 0 : answer.category_id) !== null && _a !== void 0 ? _a : '',
                        optionId: (_b = answer === null || answer === void 0 ? void 0 : answer.option_id) !== null && _b !== void 0 ? _b : '',
                    });
                }),
                rawAnswer: {
                    type: ApiTypes.TraitV2TypeGTO.MULTI,
                    userInput: [],
                    multi: multiAnswers.map((answer) => {
                        var _a, _b;
                        return ({
                            categoryId: (_a = answer === null || answer === void 0 ? void 0 : answer.category_id) !== null && _a !== void 0 ? _a : '',
                            optionId: (_b = answer === null || answer === void 0 ? void 0 : answer.option_id) !== null && _b !== void 0 ? _b : '',
                        });
                    }),
                },
            };
        }
        case ApiTypes.TraitV2TypeGTO.USER_INPUT: {
            const userInputAnswers = answers;
            return {
                answer: '',
                answers: userInputAnswers.map((answer) => {
                    var _a, _b, _c;
                    return ({
                        categoryId: (_a = answer === null || answer === void 0 ? void 0 : answer.category_id) !== null && _a !== void 0 ? _a : '',
                        optionId: (_b = answer === null || answer === void 0 ? void 0 : answer.option_id) !== null && _b !== void 0 ? _b : '',
                        answerText: (_c = answer === null || answer === void 0 ? void 0 : answer.answer_text) !== null && _c !== void 0 ? _c : '',
                    });
                }),
                rawAnswer: {
                    type: ApiTypes.TraitV2TypeGTO.MULTI,
                    multi: [],
                    userInput: userInputAnswers.map((answer) => {
                        var _a, _b, _c;
                        return ({
                            categoryId: (_a = answer === null || answer === void 0 ? void 0 : answer.category_id) !== null && _a !== void 0 ? _a : '',
                            optionId: (_b = answer === null || answer === void 0 ? void 0 : answer.option_id) !== null && _b !== void 0 ? _b : '',
                            answerText: (_c = answer === null || answer === void 0 ? void 0 : answer.answer_text) !== null && _c !== void 0 ? _c : '',
                        });
                    }),
                },
            };
        }
        default: {
            return {
                answer: '',
            };
        }
    }
};
export const getTrait = (trait) => {
    if (!(trait === null || trait === void 0 ? void 0 : trait.id) || !trait.default_label) {
        return undefined;
    }
    return Object.assign({ id: trait.id, name: trait.default_short_label ? camelCase(trait.default_short_label) : '', emoji: getLocalized(trait.default_emoji, trait.emoji_localized), label: getLocalized(trait.default_label, trait.label_localized), sensitive: !!trait.sensitive_trait, shortLabel: getLocalized(trait.default_short_label, trait.short_label_localized) }, getTraitAnswer(trait.answer));
};
export const getTraitV2 = (trait) => {
    if (!(trait === null || trait === void 0 ? void 0 : trait.trait_id) || !trait.type) {
        return undefined;
    }
    return Object.assign({ id: trait.trait_id, type: trait.type, categories: getTraitV2Categories(trait === null || trait === void 0 ? void 0 : trait.categories) }, getTraitV2Answer(trait));
};
export const getTraits = (traits) => {
    if (!traits) {
        return [];
    }
    return traits.map(getTrait).filter(nonUndefined);
};
export const getTraitsV2 = (traits) => {
    if (!traits) {
        return [];
    }
    return traits.map(getTraitV2).filter(nonUndefined);
};
export const getMatchingTraits = (traits) => {
    if (!traits) {
        return [];
    }
    return traits
        .map((trait) => {
        const baseTrait = getTrait(trait);
        const option = getTraitOption(trait.option);
        const filteredAnswers = getFilteredAnswers(trait.filtered_answers);
        if (!baseTrait || !option || !filteredAnswers) {
            return undefined;
        }
        return Object.assign(Object.assign({}, baseTrait), { option, filteredAnswers });
    })
        .filter(nonUndefined);
};
export const getTraitV2Categories = (categories) => {
    if (!(categories === null || categories === void 0 ? void 0 : categories.length)) {
        return [];
    }
    return categories.map(({ category_text, id, translated_text }) => {
        var _a, _b;
        return ({
            id,
            categoryText: category_text,
            translatedText: (_b = (_a = translated_text[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '',
        });
    });
};
