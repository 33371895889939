const getPagination = (pagination) => {
    return {
        count: (pagination === null || pagination === void 0 ? void 0 : pagination.count) || 0,
        firstScrollId: (pagination === null || pagination === void 0 ? void 0 : pagination.first_scroll_id) || '',
        lastScrollId: (pagination === null || pagination === void 0 ? void 0 : pagination.last_scroll_id) || '',
        totalCount: (pagination === null || pagination === void 0 ? void 0 : pagination.total_count) || 0,
        isLastPage: (pagination === null || pagination === void 0 ? void 0 : pagination.is_last_page) || false,
    };
};
export default getPagination;
