import { shopMappers, isShopProductPlan } from '@happn/shop';
export const getSubscription = (s) => {
    if (!s ||
        !s.creation_date ||
        !s.expiration_date ||
        !s.plan ||
        !s.user_id ||
        s.is_processed === undefined) {
        return null;
    }
    const plan = shopMappers.getShopProduct(s.plan);
    if (!plan || !isShopProductPlan(plan)) {
        return null;
    }
    return {
        creationDate: s.creation_date,
        expirationDate: s.expiration_date,
        isProcessed: s.is_processed,
        userId: s.user_id,
        plan,
    };
};
