import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LovelyIcon, MusicNoteIcon, NotificationIcon, QuoteOpenIcon, SpotIcon, } from '@happn/icons';
import Avatar from '../Avatar';
import Styled from './CardInfo.styles';
const CardInfo = ({ variant = 'pattern', userPicture, className }) => {
    const Icon = () => {
        switch (variant) {
            case 'cityOfResidence':
                return _jsx(SpotIcon, {}, void 0);
            case 'spot':
                return _jsx(MusicNoteIcon, {}, void 0);
            case 'geoloc':
                return _jsx(LovelyIcon, {}, void 0);
            case 'notification':
                return _jsx(NotificationIcon, {}, void 0);
            case 'pattern':
            default:
                return _jsx(QuoteOpenIcon, {}, void 0);
        }
    };
    return (_jsx(Styled.CardInfo, Object.assign({ variant: variant, className: className }, { children: _jsxs(Styled.AvatarSet, { children: [_jsx(Avatar, { size: "large", variant: "light", picture: userPicture, withBorder: true }, void 0), _jsx(Avatar, { size: "large", variant: "light", icon: _jsx(Icon, {}, void 0) }, void 0)] }, void 0) }), void 0));
};
export default CardInfo;
