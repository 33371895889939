import styled, { css } from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
import Text from '../Text/Text';
const Counter = styled(Text).attrs({ variant: 'bodyBold' }) `
  position: absolute;
  z-index: 5;
`;
// Sizes
const xxLargeSize = css `
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const xLargeSize = css `
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const largeSize = css `
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const mediumSize = css `
  width: 2.875rem;
  height: 2.875rem;
  border-radius: 0.75rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const smallSize = css `
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  ${Counter} {
    font-size: 0.75rem;
  }
`;
const xSmallSize = css `
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }

  ${Counter} {
    font-size: 0.75rem;
  }
`;
const xxSmallSize = css `
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }

  ${Counter} {
    font-size: 0.75rem;
  }
`;
// Variant
const darkVariant = css `
  color: ${getThemeColor(ThemeColor.IconLight)};
  background-color: ${getThemeColor(ThemeColor.FillDark)};

  ${({ isLoading, theme }) => isLoading &&
    `
    background-color: ${theme.colors.fillDarkSubdued};
  `}

  ${({ withBorder, theme }) => withBorder &&
    `
     border: 0.125rem solid ${theme.colors.dark};
  `}

  ${Counter} {
    color: ${getThemeColor(ThemeColor.IconLight)};
  }
`;
const lightVariant = css `
  color: ${getThemeColor(ThemeColor.Dark)};
  background-color: ${getThemeColor(ThemeColor.FillWhite)};

  ${({ isLoading, theme }) => isLoading &&
    `
    background-color: ${theme.colors.FillWhite};
  `}

  ${({ withBorder, theme }) => withBorder &&
    `
     border: 0.125rem solid ${theme.colors.white};
  `}

  ${Counter} {
    color: ${getThemeColor(ThemeColor.Dark)};
  }
`;
const Picture = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-user-drag: none;

  ${({ isHidden }) => isHidden &&
    `
    filter: blur(0.2rem);
  `}
`;
const Avatar = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${({ variant }) => {
    switch (variant) {
        case 'light':
            return lightVariant;
        case 'dark':
        default:
            return darkVariant;
    }
}}

  ${({ isHidden }) => isHidden &&
    `
    position: relative;
  `}

  ${({ size }) => {
    switch (size) {
        case 'xx-large':
            return xxLargeSize;
        case 'x-large':
            return xLargeSize;
        case 'large':
            return largeSize;
        case 'small':
            return smallSize;
        case 'x-small':
            return xSmallSize;
        case 'xx-small':
            return xxSmallSize;
        case 'medium':
        default:
            return mediumSize;
    }
}}
`;
export default {
    Avatar,
    Counter,
    Picture,
};
