var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import immutabilityUpdate from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import loggerService from '@happn/logger';
import { USER_UPDATE_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import useBalance from '../useBalance';
import { USER_MATCHING_PREFERENCES_QUERY, } from './gql';
const useMatchingPreferences = (id = USER_ID_ME) => {
    var _a;
    const apolloClient = useApolloClient();
    const { refetch: refetchBalance } = useBalance();
    const { data } = useQuery(USER_MATCHING_PREFERENCES_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const matchingPreferences = useMemo(() => {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.user.preferences) === null || _a === void 0 ? void 0 : _a.matching) || {
            ageMin: 20,
            ageMax: 40,
            female: false,
            male: false,
            nonBinary: false,
        };
    }, [(_a = data === null || data === void 0 ? void 0 : data.user.preferences) === null || _a === void 0 ? void 0 : _a.matching]);
    const update = useCallback((newMatchingPreferences) => __awaiter(void 0, void 0, void 0, function* () {
        const preferences = Object.assign(Object.assign({}, matchingPreferences), newMatchingPreferences);
        try {
            yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        matching_preferences: {
                            age_max: preferences.ageMax,
                            age_min: preferences.ageMin,
                            male: preferences.male,
                            female: preferences.female,
                            non_binary: preferences.nonBinary,
                        },
                    },
                },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            preferences(current) {
                                return immutabilityUpdate(current, {
                                    matching: { $set: preferences },
                                });
                            },
                        },
                    });
                },
            });
            yield refetchBalance();
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not matching preferences, ${err}`));
            return false;
        }
    }), [apolloClient, id, matchingPreferences, refetchBalance, userId]);
    return { matchingPreferences, update };
};
export default useMatchingPreferences;
