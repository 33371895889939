var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { GENDER_ALIASES_QUERY, } from '../../';
import { GqlTypes } from '@happn/types';
import { USER_UPDATE_MUTATION, USER_ID_ME } from '../../apollo';
import loggerService from '@happn/logger';
const useGenderAliases = (gender, id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(GENDER_ALIASES_QUERY, {
        fetchPolicy: 'network-only',
        skip: !gender || gender === GqlTypes.Gender.Unknown,
        variables: {
            gender: gender === null || gender === void 0 ? void 0 : gender.toLocaleLowerCase(),
        },
    });
    const update = useCallback((genderAliasId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        gender_alias: {
                            id: genderAliasId
                        },
                    },
                },
            });
            return Boolean(data === null || data === void 0 ? void 0 : data.userUpdate.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not update gender alias, ${err}`));
            return false;
        }
    }), [apolloClient, id]);
    return {
        genderAliases: (data === null || data === void 0 ? void 0 : data.genderAliases) || [],
        update,
    };
};
export default useGenderAliases;
