import { useQuery } from '@apollo/client';
import { RELATIONSHIPS_BETWEEN_USERS_QUERY, } from '../../apollo';
const useRelationships = (userIdA, userIdB) => {
    const { data, loading } = useQuery(RELATIONSHIPS_BETWEEN_USERS_QUERY, {
        variables: { userIdA, userIdB },
        fetchPolicy: 'no-cache',
    });
    return {
        isLoading: loading,
        relationships: {
            [userIdA]: (data === null || data === void 0 ? void 0 : data.relationshipsBetweenUsers.statusAB) || null,
            [userIdB]: (data === null || data === void 0 ? void 0 : data.relationshipsBetweenUsers.statusBA) || null,
        },
    };
};
export default useRelationships;
