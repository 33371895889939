var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import logger from '@happn/logger';
import { USER_ACLS_QUERY, USER_UPDATE_ACLS_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserAclsIdentifier } from '../../helpers';
const useAcls = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data, loading } = useQuery(USER_ACLS_QUERY, { variables: { id } });
    const update = useCallback((acls, overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_ACLS_MUTATION,
                variables: {
                    id: overrideId || id,
                    input: `permissions=${acls.join(',')}`,
                },
                update: (cache, result) => {
                    var _a;
                    if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userUpdateAcls.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserAclsIdentifier(overrideId || id),
                        fields: {
                            acls: () => acls,
                        },
                    });
                },
            });
            return !!(data === null || data === void 0 ? void 0 : data.userUpdateAcls.success);
        }
        catch (err) {
            logger.error(err);
            return false;
        }
    }), [apolloClient, id]);
    return {
        loading,
        acls: (data === null || data === void 0 ? void 0 : data.userAcls.acls) || [],
        update,
    };
};
export default useAcls;
