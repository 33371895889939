var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { SHOP_PRODUCT_GROUP_ADD_MUTATION, SHOP_PRODUCT_GROUP_UPDATE_MUTATION, SHOP_PRODUCT_GROUP_REMOVE_MUTATION, SHOP_PRODUCT_GROUPS_QUERY, } from '../apollo';
export const useProductGroups = () => {
    const apolloClient = useApolloClient();
    const list = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.query({
            query: SHOP_PRODUCT_GROUPS_QUERY,
        });
        return {
            data: (data === null || data === void 0 ? void 0 : data.shopProductGroups) || [],
        };
    }), [apolloClient]);
    const add = useCallback((productGroup) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_PRODUCT_GROUP_ADD_MUTATION,
                variables: { input: productGroup },
            });
            const newProductGroup = (data === null || data === void 0 ? void 0 : data.shopProductGroupAdd) || null;
            if (newProductGroup) {
                apolloClient.cache.modify({
                    fields: {
                        shopProductGroups: (shopProductGroups, { toReference }) => [toReference(newProductGroup), ...shopProductGroups],
                    },
                });
            }
            return newProductGroup;
        }
        catch (_a) {
            return null;
        }
    }), [apolloClient]);
    const update = useCallback((productGroup) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!productGroup.id) {
                return null;
            }
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_PRODUCT_GROUP_UPDATE_MUTATION,
                variables: { id: productGroup.id, input: productGroup },
            });
            const updatedProductGroup = (data === null || data === void 0 ? void 0 : data.shopProductGroupUpdate) || null;
            if (updatedProductGroup) {
                apolloClient.cache.modify({
                    fields: {
                        shopProductGroups: (shopProductGroups, { toReference, readField }) => [
                            toReference(updatedProductGroup),
                            ...shopProductGroups.filter((productRef) => readField('id', productRef) !== updatedProductGroup.id),
                        ],
                    },
                });
            }
            return updatedProductGroup;
        }
        catch (_b) {
            return null;
        }
    }), [apolloClient]);
    const remove = useCallback((id) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const result = yield apolloClient.mutate({
            variables: {
                id,
            },
            mutation: SHOP_PRODUCT_GROUP_REMOVE_MUTATION,
        });
        const success = !!((_c = result.data) === null || _c === void 0 ? void 0 : _c.shopProductGroupRemove.success);
        if (success) {
            apolloClient.cache.modify({
                fields: {
                    shopProductGroups: (products, { readField }) => products.filter((productRef) => readField('id', productRef) !== id),
                },
            });
        }
        return success;
    }), [apolloClient]);
    return { list, add, update, remove };
};
export default useProductGroups;
