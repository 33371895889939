import { gql } from '@apollo/client';
export const USER_MATCHING_TRAITS_PREFERENCES_QUERY = gql `
  query UserMatchingTraitsPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        matchingTraits
      }
    }
  }
`;
