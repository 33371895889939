/* istanbul ignore next */
export const locales: { [key: string]: () => Promise<any> } = {
  en: () =>
    import(/* webpackChunkName: "date-fns-en_US" */ 'date-fns/locale/en-US'),
  fr: () => import(/* webpackChunkName: "date-fns-fr" */ `date-fns/locale/fr`),
  'zh-TW': () =>
    import(/* webpackChunkName: "date-fns-zh_TW" */ 'date-fns/locale/zh-TW'),
  da: () => import(/* webpackChunkName: "date-fns-da" */ 'date-fns/locale/da'),
  nl: () => import(/* webpackChunkName: "date-fns-nl" */ 'date-fns/locale/nl'),
  de: () => import(/* webpackChunkName: "date-fns-de" */ 'date-fns/locale/de'),
  el: () => import(/* webpackChunkName: "date-fns-el" */ 'date-fns/locale/el'),
  'hi-IN': () =>
    import(/* webpackChunkName: "date-fns-hi_IN" */ 'date-fns/locale/hi'),
  hu: () => import(/* webpackChunkName: "date-fns-hu" */ 'date-fns/locale/hu'),
  it: () => import(/* webpackChunkName: "date-fns-it" */ 'date-fns/locale/it'),
  ja: () => import(/* webpackChunkName: "date-fns-ja" */ 'date-fns/locale/ja'),
  nb: () => import(/* webpackChunkName: "date-fns-nb" */ 'date-fns/locale/nb'),
  pl: () => import(/* webpackChunkName: "date-fns-pl" */ 'date-fns/locale/pl'),
  'pt-BR': () =>
    import(/* webpackChunkName: "date-fns-pt_BR" */ 'date-fns/locale/pt-BR'),
  'pt-PT': () =>
    import(/* webpackChunkName: "date-fns-pt_PT" */ 'date-fns/locale/pt'),
  ru: () => import(/* webpackChunkName: "date-fns-ru" */ 'date-fns/locale/ru'),
  es: () => import(/* webpackChunkName: "date-fns-es" */ 'date-fns/locale/es'),
  'es-AR': () =>
    import(/* webpackChunkName: "date-fns-es_AR" */ 'date-fns/locale/es'),
  'es-419': () =>
    import(/* webpackChunkName: "date-fns-es_419" */ 'date-fns/locale/es'),
  sv: () => import(/* webpackChunkName: "date-fns-sv" */ 'date-fns/locale/sv'),
  'te-IN': () =>
    import(/* webpackChunkName: "date-fns-te_IN" */ 'date-fns/locale/te'),
  tr: () => import(/* webpackChunkName: "date-fns-tr" */ 'date-fns/locale/tr'),
  th: () => import(/* webpackChunkName: "date-fns-th" */ 'date-fns/locale/th'),
}
