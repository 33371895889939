import { omit } from 'ramda';
const omitKeys = (input, ...props) => {
    const omitDeepOnOwnProps = (obj) => {
        if (typeof input === 'undefined') {
            return input;
        }
        if (!Array.isArray(obj) &&
            Object.prototype.toString.call(obj) !== '[object Object]') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return omitKeys(obj, ...props);
        }
        const o = {};
        for (const [key, value] of Object.entries(obj)) {
            o[key] = !(value === null || value === undefined)
                ? omitKeys(value, ...props)
                : value;
        }
        return omit(props, o);
    };
    if (Array.isArray(input)) {
        return input.map(omitDeepOnOwnProps);
    }
    return omitDeepOnOwnProps(input);
};
export default omitKeys;
