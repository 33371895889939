import { Language } from './i18n.types'

export const defaultLanguage: Language = {
  countryCode: 'US',
  key: 'en',
  englishName: 'English',
  translatedName: 'English',
  localCode: 'en-US',
}

export const languages: Language[] = [
  {
    countryCode: 'DK',
    key: 'da',
    englishName: 'Danish',
    translatedName: 'Dansk',
    localCode: 'da-DK',
  },
  {
    countryCode: 'DE',
    key: 'de',
    englishName: 'German',
    translatedName: 'Deutsch',
    localCode: 'de-DE',
  },
  defaultLanguage,
  {
    countryCode: 'ES',
    key: 'es',
    englishName: 'Spanish',
    translatedName: 'Español',
    localCode: 'es-ES',
  },
  {
    countryCode: 'PT',
    key: 'pt-PT',
    englishName: 'Portuguese (Portugal)',
    translatedName: 'Português (Portugal)',
    localCode: 'pt-PT',
  },
  {
    countryCode: 'BR',
    key: 'pt-BR',
    englishName: 'Portuguese (Brazil)',
    translatedName: 'Português (Brazil)',
    localCode: 'pt-BR',
  },
  {
    countryCode: 'AR',
    key: 'es-AR',
    englishName: 'Spanish (Argentina)',
    translatedName: 'Español (Argentina)',
    localCode: 'es-AR',
  },
  {
    countryCode: 'ES',
    key: 'es-419',
    englishName: 'Spanish (Latin America)',
    translatedName: 'Español (Latin America)',
    localCode: 'es-19',
  },
  {
    countryCode: 'FR',
    key: 'fr',
    englishName: 'French',
    translatedName: 'Français',
    localCode: 'fr-FR',
  },
  {
    countryCode: 'IN',
    key: 'hi-IN',
    englishName: 'Hindi (India)',
    translatedName: 'हिंदी (India)',
    localCode: 'hi-IN',
  },
  {
    countryCode: 'IT',
    key: 'it',
    englishName: 'Italian',
    translatedName: 'Italiano',
    localCode: 'it-IT',
  },
  {
    countryCode: 'NO',
    key: 'nb',
    englishName: 'Norwegian Bokmål',
    translatedName: 'Norsk Bokmål',
    localCode: 'no-NO',
  },
  {
    countryCode: 'NL',
    key: 'nl',
    englishName: 'Dutch',
    translatedName: 'Nederlands',
    localCode: 'nl-NL',
  },
  {
    countryCode: 'SE',
    key: 'sv',
    englishName: 'Swedish',
    translatedName: 'Svenska',
    localCode: 'sv-SE',
  },
  {
    countryCode: 'IN',
    key: 'te-IN',
    englishName: 'Telugu (India)',
    translatedName: 'తెలుగు (India)',
    localCode: 'te-IN',
  },
  {
    countryCode: 'TR',
    key: 'tr',
    englishName: 'Turkish',
    translatedName: 'Türkçe',
    localCode: 'tr-TR',
  },
]

export const languageKeys = languages.map((language) => language.key)
