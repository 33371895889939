var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { AnimationType } from '@happn/animations';
import Styled from './Button.styles';
import { ButtonSize as Size, ButtonVariant as Variant, } from './Button.types';
const buttons = {
    [Size.XSmall]: {
        [Variant.Primary]: null,
        [Variant.Secondary]: Styled.XSmallSecondary,
        [Variant.Tertiary]: null,
        [Variant.RoundedPrimary]: null,
        [Variant.RoundedSecondary]: null,
        [Variant.Transparent]: null,
        [Variant.Link]: null,
    },
    [Size.Small]: {
        [Variant.Primary]: Styled.SmallPrimary,
        [Variant.Secondary]: Styled.SmallSecondary,
        [Variant.Tertiary]: Styled.SmallTertiary,
        [Variant.RoundedPrimary]: Styled.SmallRoundedPrimary,
        [Variant.RoundedSecondary]: Styled.SmallRoundedSecondary,
        [Variant.Transparent]: Styled.Transparent,
        [Variant.Link]: Styled.Link,
    },
    [Size.Medium]: {
        [Variant.Primary]: Styled.MediumPrimary,
        [Variant.Secondary]: null,
        [Variant.Tertiary]: null,
        [Variant.RoundedPrimary]: null,
        [Variant.RoundedSecondary]: null,
        [Variant.Transparent]: null,
        [Variant.Link]: null,
    },
    [Size.Large]: {
        [Variant.Primary]: Styled.LargePrimary,
        [Variant.Secondary]: Styled.LargeSecondary,
        [Variant.Tertiary]: Styled.LargeTertiary,
        [Variant.Link]: Styled.Link,
        [Variant.RoundedPrimary]: null,
        [Variant.RoundedSecondary]: null,
        [Variant.Transparent]: null,
    },
};
const Button = forwardRef((_a, ref) => {
    var { variant = Variant.Primary, size = Size.Small, testId = 'button', fullWidth = false, startIcon: startIconProp, endIcon: endIconProp, children, isLoading, isDisabled, className } = _a, rest = __rest(_a, ["variant", "size", "testId", "fullWidth", "startIcon", "endIcon", "children", "isLoading", "isDisabled", "className"]);
    const startIcon = startIconProp && (_jsx(Styled.StartIcon, Object.assign({ "data-testid": `${testId}-start-icon` }, { children: startIconProp }), void 0));
    const endIcon = endIconProp && (_jsx(Styled.EndIcon, Object.assign({ "data-testid": `${testId}-end-icon` }, { children: endIconProp }), void 0));
    const Label = () => (_jsxs(_Fragment, { children: [startIcon, children, endIcon] }, void 0));
    const lightLoaderEligibleVariants = [
        Variant.Primary,
        Variant.RoundedPrimary,
    ];
    const Loader = () => (_jsx(_Fragment, { children: lightLoaderEligibleVariants.includes(variant) ? (_jsx(Styled.Loader, { type: AnimationType.CircleEndLight, size: size }, void 0)) : (_jsx(Styled.Loader, { type: AnimationType.CircleEndDark, size: size }, void 0)) }, void 0));
    const Cmp = buttons[size][variant];
    if (!Cmp) {
        throw new Error(`This button variant does not exist : ${variant} ${size}`);
    }
    return (_jsx(Cmp, Object.assign({ ref: ref, fullWidth: fullWidth, className: className, "data-testid": testId, disabled: isDisabled || isLoading }, rest, { children: isLoading ? _jsx(Loader, {}, void 0) : _jsx(Label, {}, void 0) }), void 0));
});
export default Button;
