var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 as uuid } from 'uuid';
import { jwt } from '@happn/helpers';
export const AUTH_ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token';
export const AUTH_REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh_token';
export const MOBILE_TOKEN_LOCAL_STORAGE_KEY = 'mobile_token';
export const MOBILE_ID_LOCAL_STORAGE_KEY = 'mobile_id';
export class AuthorizationService {
    /**
     * Create new instance of AuthorizationService. Reads local storage and
     * stores tokens internally. If access token is available, userId will
     * be decoded from it.
     */
    constructor() {
        var _a, _b;
        this._logoutCallbacks = [];
        this._autoLoginCallbacks = [];
        this._userFetchCallbacks = [];
        this._refreshRequestCallbacks = [];
        this._accessToken =
            localStorage.getItem(AUTH_ACCESS_TOKEN_LOCAL_STORAGE_KEY) || undefined;
        this._refreshToken =
            localStorage.getItem(AUTH_REFRESH_TOKEN_LOCAL_STORAGE_KEY) || undefined;
        this._mobileToken =
            localStorage.getItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY) || undefined;
        this._mobileId =
            localStorage.getItem(MOBILE_ID_LOCAL_STORAGE_KEY) || undefined;
        this._userId = (_a = jwt.decode(this._accessToken)) === null || _a === void 0 ? void 0 : _a.sub;
        this._scopes = ((_b = jwt.decode(this._accessToken)) === null || _b === void 0 ? void 0 : _b.scope) || [];
    }
    /**
     * Return user scopes
     */
    get scopes() {
        return this._scopes;
    }
    /**
     * Returns user id
     */
    get userId() {
        return this._userId;
    }
    /**
     * Returns accessToken and refreshToken
     */
    get authorizationTokens() {
        return {
            accessToken: this._accessToken,
            refreshToken: this._refreshToken,
        };
    }
    /**
     * Returns mobile token and id. If current mobile tokens are
     * missing, new one will be generated
     */
    get mobileTokens() {
        if (!this._mobileToken || !this._mobileId) {
            return this._generateMobileTokens();
        }
        return { mobileToken: this._mobileToken, mobileId: this._mobileId };
    }
    /**
     * `getInstance` - returns AuthorizationService instance or returns existing one if it's there
     * @param options
     * @returns {AuthorizationService} instance
     */
    static getInstance() {
        if (!AuthorizationService._instance) {
            AuthorizationService._instance = new AuthorizationService();
        }
        return AuthorizationService._instance;
    }
    /**
     * Generates new mobile token and id used in authorization funnel.
     * @returns Mobile token and id
     */
    _generateMobileTokens() {
        this._mobileToken = uuid();
        this._mobileId = uuid();
        localStorage.setItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY, this._mobileToken);
        localStorage.setItem(MOBILE_ID_LOCAL_STORAGE_KEY, this._mobileId);
        return { mobileToken: this._mobileToken, mobileId: this._mobileId };
    }
    /**
     * Saves authorization tokens into local storage, gets userId from accessToken
     * and store it internally. If any of the params is missing, cleanup currently
     * saved tokens.
     * @param accessToken
     * @param refreshToken
     */
    storeAuthorizationTokens(accessToken, refreshToken) {
        var _a, _b;
        if (!accessToken || !refreshToken) {
            this.clearAuthorizationTokens();
            return;
        }
        localStorage.setItem(AUTH_ACCESS_TOKEN_LOCAL_STORAGE_KEY, accessToken);
        localStorage.setItem(AUTH_REFRESH_TOKEN_LOCAL_STORAGE_KEY, refreshToken);
        this._accessToken = accessToken;
        this._refreshToken = refreshToken;
        this._userId = (_a = jwt.decode(this._accessToken)) === null || _a === void 0 ? void 0 : _a.sub;
        this._scopes = ((_b = jwt.decode(this._accessToken)) === null || _b === void 0 ? void 0 : _b.scope) || [];
    }
    /**
     * Removes authorization tokens from local storage and
     * cleanup internal fields.
     */
    clearAuthorizationTokens() {
        localStorage.removeItem(AUTH_ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(AUTH_REFRESH_TOKEN_LOCAL_STORAGE_KEY);
        this._accessToken = undefined;
        this._refreshToken = undefined;
        this._userId = undefined;
        this._scopes = [];
    }
    /**
     * Removes mobile tokens from local storage and
     * cleanup internal fields.
     */
    clearMobileTokens() {
        localStorage.removeItem(MOBILE_TOKEN_LOCAL_STORAGE_KEY);
        localStorage.removeItem(MOBILE_ID_LOCAL_STORAGE_KEY);
        this._mobileId = undefined;
        this._mobileToken = undefined;
    }
    /**
     * Stores callback that will be fired on logout
     * @param cb
     */
    subscribeToLogout(cb) {
        this._logoutCallbacks.push(cb);
    }
    /**
     * Stores callback that will be fired on auto login
     * @param cb
     */
    subscribeToAutoLogin(cb) {
        this._autoLoginCallbacks.push(cb);
    }
    subscribeToUserFetch(cb) {
        this._userFetchCallbacks.push(cb);
    }
    subscribeToRefreshRequest(cb) {
        this._refreshRequestCallbacks.push(cb);
    }
    /**
     * Calls all logoutCallbacks and clear authorization tokens
     * from local storage
     */
    logout(props) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this._logoutCallbacks.map((cb) => cb(Object.assign(Object.assign({}, props), { userId: this.userId }))));
            this.clearAuthorizationTokens();
        });
    }
    /**
     * Calls all autoLogin callbacks
     */
    autoLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this._autoLoginCallbacks.map((cb) => cb()));
        });
    }
    userFetch(user) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this._userFetchCallbacks.map((cb) => cb(user)));
        });
    }
    refreshRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this._refreshRequestCallbacks.map((cb) => cb()));
        });
    }
}
const authorizationService = AuthorizationService.getInstance();
export default authorizationService;
