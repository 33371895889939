import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import env from '@happn/env';
export const createWsLink = (apolloService) => {
    const subscriptionClient = new SubscriptionClient(env.common.gqlApiSubscriptions, {
        reconnect: true,
        lazy: true,
        connectionParams: apolloService.wsConnectionParams,
    });
    const wsLink = new WebSocketLink(subscriptionClient);
    return { wsLink, subscriptionClient };
};
