import { gql } from '@apollo/client';
export const USER_MYSTERIOUS_MODE_PREFERENCES_QUERY = gql `
  query UserMysteriousModePreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        mysteriousMode {
          hideGenderAlias
        }
      }
    }
  }
`;
