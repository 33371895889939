import { gql } from '@apollo/client';
import { USER_LAST_ACCEPTED_VERSIONS_FRAGMENT } from '../../../apollo';
export const USER_LAST_ACCEPTED_VERSIONS_QUERY = gql `
  ${USER_LAST_ACCEPTED_VERSIONS_FRAGMENT}

  query UserLastAcceptedVersionsQuery($id: String) {
    user(id: $id) {
      id
      ...UserLastAcceptedVersions
    }
  }
`;
