var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { default as formatDate } from 'date-fns/format';
import { nonUndefined } from '@happn/helpers';
export const getPricesArray = (prices) => {
    if (!prices) {
        return [];
    }
    if (Array.isArray(prices)) {
        return prices;
    }
    return [prices];
};
const getShopProductGroup = (productGroup) => {
    var _a;
    if (!(productGroup === null || productGroup === void 0 ? void 0 : productGroup.id) || !productGroup.name || !productGroup.product_type) {
        return undefined;
    }
    return {
        id: productGroup.id,
        name: productGroup.name,
        productType: productGroup.product_type,
        description: productGroup.description || '',
        count: ((_a = productGroup === null || productGroup === void 0 ? void 0 : productGroup.product_ids) === null || _a === void 0 ? void 0 : _a.length) || 0,
        productIds: (productGroup === null || productGroup === void 0 ? void 0 : productGroup.product_ids) || [],
        modificationDate: productGroup.modification_date,
        creationDate: productGroup.creation_date || '',
    };
};
export const toShopProductGroupModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return null;
    }
    return Array.isArray(res.data)
        ? res.data
            .map(getShopProductGroup)
            .filter(nonUndefined)
            .sort((a, b) => new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime())
        : getShopProductGroup(res.data) || null;
};
export const getPackInformationCredit = (credit) => {
    if (!(credit === null || credit === void 0 ? void 0 : credit.type)) {
        return undefined;
    }
    return {
        amount: credit.amount || 0,
        type: credit.type,
    };
};
export const getPackInformation = (packInformation) => {
    if (!(packInformation === null || packInformation === void 0 ? void 0 : packInformation.credits)) {
        return undefined;
    }
    return {
        credits: packInformation.credits
            .map(getPackInformationCredit)
            .filter(nonUndefined),
    };
};
export const getPlanInformationCredit = (credit) => {
    if (!(credit === null || credit === void 0 ? void 0 : credit.type)) {
        return undefined;
    }
    return {
        cooldownPeriod: credit.cooldown_period || 0,
        renewablePerPeriod: credit.renewable_per_period || 0,
        type: credit.type,
    };
};
export const getPrice = (price) => {
    if (!(price === null || price === void 0 ? void 0 : price.amount_cents) || !price.country || !price.currency) {
        return undefined;
    }
    return {
        amountCents: price.amount_cents,
        country: price.country,
        currency: price.currency,
        id: price.id,
        taxRateId: price.tax_rate_id,
    };
};
export const getPeriod = (periodGto) => ({
    unit: periodGto === null || periodGto === void 0 ? void 0 : periodGto.unit,
    value: periodGto === null || periodGto === void 0 ? void 0 : periodGto.value,
});
export const getIntroductoryPlan = (introductoryPlan) => {
    if (!introductoryPlan) {
        return undefined;
    }
    return {
        cycles: introductoryPlan.cycles || 0,
        prices: getPricesArray(introductoryPlan.prices).map(getPrice),
        recurrencePeriod: getPeriod(introductoryPlan.recurrence_period),
    };
};
export const getPlanInformation = (planInformation) => {
    var _a, _b;
    if (!((_a = planInformation === null || planInformation === void 0 ? void 0 : planInformation.recurrence_subscription_period) === null || _a === void 0 ? void 0 : _a.unit) ||
        planInformation.recurrence_subscription_period.value === undefined) {
        return undefined;
    }
    return {
        credits: ((_b = planInformation.credits) === null || _b === void 0 ? void 0 : _b.map(getPlanInformationCredit).filter(nonUndefined)) || [],
        recurrenceSubscriptionPeriod: getPeriod(planInformation.recurrence_subscription_period),
        prices: getPricesArray(planInformation.prices)
            .map(getPrice)
            .filter(nonUndefined),
        introductoryPlan: getIntroductoryPlan(planInformation.introductory_plan),
    };
};
export const getShopProduct = (product) => {
    if (!(product === null || product === void 0 ? void 0 : product.id) ||
        !product.creation_date ||
        !product.channel ||
        !product.name ||
        !product.product_type) {
        return undefined;
    }
    const information = {
        productType: product.product_type,
        packInformation: getPackInformation(product.pack_information),
        planInformation: getPlanInformation(product.plan_information),
    };
    return Object.assign({ id: product.id, activated: product.activated || false, reactivation: product.reactivation || false, channel: product.channel, creationDate: product.creation_date, defaultProduct: product.default_product || false, description: product.description || '', introductory: product.introductory || false, modificationDate: product.modification_date, name: product.name, position: product.position || 0, storeProductId: product.store_product_id || '' }, information);
};
export const toShopProductModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return null;
    }
    if (Array.isArray(res.data)) {
        return getMultipleShopProducts(res.data);
    }
    return getShopProduct(res.data) || null;
};
const getMultipleShopProducts = (p) => {
    if (!p) {
        return [];
    }
    return p
        .map(getShopProduct)
        .filter(nonUndefined)
        .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
};
const getShopRule = (shopRule) => {
    if (!(shopRule === null || shopRule === void 0 ? void 0 : shopRule.id) ||
        !shopRule.name ||
        !shopRule.type ||
        !shopRule.product_groups ||
        shopRule.priority === undefined ||
        !shopRule.filters ||
        !shopRule.activation_date) {
        return undefined;
    }
    return {
        id: shopRule.id,
        activated: shopRule.activated || false,
        activationDate: shopRule.activation_date,
        expirationDate: shopRule.expiration_date,
        creationDate: shopRule.creation_date,
        description: shopRule.description || '',
        modificationDate: shopRule.modification_date,
        name: shopRule.name,
        priority: shopRule.priority,
        productGroups: shopRule.product_groups,
        type: shopRule.type,
        filters: shopRule.filters,
    };
};
export const toShopRulesModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return null;
    }
    if (Array.isArray(res.data)) {
        return res.data
            .map(getShopRule)
            .filter(nonUndefined)
            .sort((a, b) => a.priority - b.priority);
    }
    return getShopRule(res.data) || null;
};
export const getStringDate = (date) => date ? formatDate(new Date(date), 'yyyy-MM-dd') : undefined;
export const toShopInAppRulesGtoModel = (data) => {
    const { activationDate, expirationDate, productGroups, creationDate, modificationDate } = data, rest = __rest(data, ["activationDate", "expirationDate", "productGroups", "creationDate", "modificationDate"]);
    return Object.assign(Object.assign({}, rest), { activation_date: getStringDate(activationDate), expiration_date: getStringDate(expirationDate), product_groups: {
            packs: (productGroups === null || productGroups === void 0 ? void 0 : productGroups.packs) || [],
            plans: (productGroups === null || productGroups === void 0 ? void 0 : productGroups.plans) || [],
        } });
};
export const toShopModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return null;
    }
    return {
        type: res.data.type,
        products: getMultipleShopProducts(res.data.products),
    };
};
export const toShopCheckoutSessionModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.url)) {
        return null;
    }
    return {
        url: response.data.url,
    };
};
export const toShopPaymentPortalModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.url)) {
        return null;
    }
    return {
        url: response.data.url,
        eligible: response.data.eligible || false,
    };
};
