export const ROOT_ELEMENT = document.getElementById('root')

export const IMAGE_MAX_SIZE = 5242880
export const IMAGE_MODE = 1
export const IMAGE_MAX_WIDTH = 1400
export const IMAGE_MAX_HEIGHT = 1600
export const IMAGE_TYPE = 'image/jpeg'
export const IMAGE_QUALITY = 0.9
export const MAX_AUDIO_DURATION = 60 // in seconds
export const SPOTIFY_PREVIEW_DURATION = 30000 // in ms
export const SPOTIFY_DEFAULT_MARKET = 'FR'

export const BRAZE_NOTIFICATIONS_FETCH_INTERVAL = 5 * 60 * 1000 // 5 min in ms

export const ANDROID_MOBILE_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.ftw_and_co.happn'
export const IOS_MOBILE_APP_LINK = 'https://apps.apple.com/app/id489185828'
export const WEBSITE_HOME_PAGE = 'https://www.happn.com'
export const ZENDESK_WEBSITE_PAGE = 'https://support.happn.fr/hc'

// How many minutes need to be elapsed
// to trigger refresh recommendations when user is back
export const MAX_IDLE_TIMEOUT = 3

export const GEOLOCATION_CONFIG = {
  timeout: 5 * 1000,
  maximumAge: 60 * 1000,
  enableHighAccuracy: true,
}

export const MAX_RECORDING_TIME_FOR_CONVERSATIONS_SECONDS = 60
