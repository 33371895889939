import styled, { css } from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
const fullWidthStyle = css `
  width: 100%;
`;
const RoundedCell = styled.button `
  cursor: pointer;
  border: none;
  outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
  border-radius: 1rem;
  padding: 1rem;
  min-height: 4rem;
  text-align: left;
  display: flex;
  align-items: center;
  background-color: ${({ variant }) => variant === 'dark'
    ? getThemeColor(ThemeColor.FillDark)
    : getThemeColor(ThemeColor.FillWhite)};

  color: ${({ variant }) => variant === 'dark'
    ? getThemeColor(ThemeColor.TextLight)
    : getThemeColor(ThemeColor.TextDark)};

  ${({ fullWidth }) => fullWidth && fullWidthStyle}
`;
const IconLeftWrapper = styled.span `
  margin-right: 0.75rem;
`;
const IconRightWrapper = styled.span `
  margin-left: auto;
`;
export default { IconLeftWrapper, IconRightWrapper, RoundedCell };
