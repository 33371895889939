export const getUserCredit = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.creation_date) || !gto.type) {
        return undefined;
    }
    return {
        type: gto.type,
        refId: gto.ref_id || '',
        amount: gto.amount || 0,
        creationDate: new Date(gto.creation_date),
        transactionType: gto.transaction_type || '',
    };
};
