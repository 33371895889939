import { gql } from '@apollo/client';
import { UserLatestSubscriptionTypeName } from '../../../apollo';
export const USER_LATEST_SUBSCRIPTION = gql `
  query UserLatestSubscriptionQuery($userId: String) {
    userLatestSubscription(userId: $userId) @rest(
      type: ${UserLatestSubscriptionTypeName}
      path: "api/v1/users/{args.userId}/subscriptions/latest"
    ) {
        userId
        subscriptionId
        channel
        startDate
        endDate
        subscriptionType
        status
        introductory
        reactivation
    }
  }
`;
