import { gql } from '@apollo/client';
import { SHOP_PRODUCT_BASE_FRAGMENT, SHOP_PRODUCT_GROUP_BASE_FRAGMENT, SHOP_RULE_BASE_FRAGMENT, } from './fragments';
import { ShopProductGroupTypeName, ShopTypeName, ShopProductTypeName, ShopRuleTypeName, ShopPaymentPortalTypeName, } from './types';
const MAX_IAPR_RULES_RESULTS = 700;
export const SHOP_PRODUCT_GROUPS_QUERY = gql `
  ${SHOP_PRODUCT_GROUP_BASE_FRAGMENT}
  
  query ShopProductGroupsQuery {
    shopProductGroups
      @rest(
        type: "${ShopProductGroupTypeName}"
        path: "api/shop-groups"
      ) {
        ...ShopProductGroupBase
        modificationDate
      }
  }
`;
export const SHOP_PRODUCTS_QUERY = gql `
  ${SHOP_PRODUCT_BASE_FRAGMENT}

  query ShopProductsQuery {
    shopProducts @rest(
      type: "${ShopProductTypeName}"
      path: "api/v2/shop-products"
    ) {
      ...ShopProductBase
    }
  }
`;
export const SHOP_RULES_QUERY = gql `
  ${SHOP_RULE_BASE_FRAGMENT}

  query ShopRulesQuery {
    shopRules @rest(
      type: "${ShopRuleTypeName}"
      path: "api/v2/shop/rules?limit=${MAX_IAPR_RULES_RESULTS}"
    ) {
      ...ShopRuleBase
    }
  }
`;
export const SHOP_RULES_SEARCH_QUERY = gql `
  ${SHOP_RULE_BASE_FRAGMENT}

  query ShopRulesSearchQuery($ruleType: String, $input: Any) {
    shopRulesSearch(ruleType: $ruleType, input: $input) @rest(
      type: "${ShopRuleTypeName}"
      path: "api/v2/shop/rules/search?type={args.ruleType}&limit=${MAX_IAPR_RULES_RESULTS}"
      method: "POST"
    ) {
      ...ShopRuleBase
    }
  }
`;
export const SHOP_QUERY = gql `
  ${SHOP_PRODUCT_BASE_FRAGMENT}

  query ShopQuery {
    shop @rest(
      type: "${ShopTypeName}"
      path: "api/v3/shop?channel=STRIPE"
    ) {
      type
      products {
        ...ShopProductBase
      }
    } 
  }
`;
export const SHOP_PAYMENT_PORTAL_QUERY = gql `
  query ShopPaymentPortalQuery($returnUrl: String) {
    shopPaymentPortal(returnUrl: $returnUrl) @rest(
      type: "${ShopPaymentPortalTypeName}"
      method: "GET"
      path: "api/v1/payment-portal?return_url={args.returnUrl}"
    ) {
      url
      eligible
    }
  }
`;
