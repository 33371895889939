var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { PATCH_IDENTITY_MUTATION, } from '../apollo';
const useIdentities = () => {
    const apolloClient = useApolloClient();
    const patch = useCallback(({ userId, mobileIds = [], mobileTokens = [] }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!userId) {
            return false;
        }
        try {
            const { data } = yield apolloClient.mutate({
                mutation: PATCH_IDENTITY_MUTATION,
                variables: {
                    id: userId,
                    input: {
                        mobile_ids: mobileIds,
                        mobile_tokens: mobileTokens,
                    },
                },
            });
            return !!((_a = data === null || data === void 0 ? void 0 : data.patchIdentity) === null || _a === void 0 ? void 0 : _a.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not patch identity, ${err}`));
        }
        return false;
    }), [apolloClient]);
    return { patch };
};
export default useIdentities;
