import { gql } from '@apollo/client';
import { USER_TRAITS_FRAGMENT, USER_TRAITS_V2_FRAGMENT } from '../../../apollo';
export const USER_TRAITS_QUERY = gql `
  ${USER_TRAITS_FRAGMENT}
  ${USER_TRAITS_V2_FRAGMENT}

  query UserTraitsQuery($id: String) {
    user(id: $id) {
      id
      ...UserTraits
      ...UserTraitsV2
    }
  }
`;
