import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LogoWithTextIcon, LogoIcon } from '@happn/icons';
import { ThemeColor, getThemeColor } from '@happn/theme';
import ActivityBadge from '../ActivityBadge';
export const SIDE_BAR_WIDTH = '16.5rem';
export const SIDE_BAR_WIDTH_REDUCED = '4.5rem';
const IconWrapper = styled.div `
  position: relative;
`;
const LikesCounter = styled(ActivityBadge) `
  position: absolute;
  right: -1.3rem;
  top: -0.5rem;
  width: 2rem;
`;
const LogoWithText = styled(LogoWithTextIcon) ``;
const Logo = styled(LogoIcon) `
  width: 2rem;
  height: 3rem;
`;
const ConversationsPin = styled(ActivityBadge) `
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
`;
const SideBar = styled(motion.nav) `
  background-color: ${getThemeColor(ThemeColor.Dark)};
  height: 100%;
  color: ${getThemeColor(ThemeColor.White)};
  padding: 1.5rem;
  width: ${SIDE_BAR_WIDTH};
  z-index: 10;
`;
const HomeLink = styled(Link) `
  display: block;
  margin-bottom: 3.5rem;
`;
export default {
    IconWrapper,
    Logo,
    LogoWithText,
    SideBar,
    LikesCounter,
    ConversationsPin,
    HomeLink,
};
