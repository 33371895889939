import { gql } from '@apollo/client';
export const USER_MARKETING_PREFERENCES_QUERY = gql `
  query UserMarketingPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        marketing {
          audienceMeasurement
          operations
          targetedAds
          recommendedInEmails
        }
      }
    }
  }
`;
