export var LogoutType;
(function (LogoutType) {
    LogoutType["Interaction"] = "interaction";
    LogoutType["AccountRecovery"] = "recoverToken";
    LogoutType["AutoLogin"] = "autoLogin";
    LogoutType["Deleted"] = "deleted";
})(LogoutType || (LogoutType = {}));
export var Scope;
(function (Scope) {
    // Admin
    Scope["AdminCreate"] = "admin_create";
    Scope["AdminRead"] = "admin_read";
    Scope["AdminDelete"] = "admin_delete";
    Scope["AdminSearchUser"] = "admin_search_user";
    Scope["AdminDeleteUsers"] = "admin_delete_users";
    // Acl
    Scope["AclDelete"] = "acl_delete";
    Scope["AclRead"] = "acl_read";
    Scope["AclCreate"] = "acl_create";
    Scope["AclUpdate"] = "acl_update";
    // Geo
    Scope["GeoWrite"] = "geo_write";
    Scope["GeoRead"] = "geo_read";
    // Push campaign
    Scope["PushCampaignCreate"] = "push_campaign_create";
    Scope["PushCampaignUpdate"] = "push_campaign_update";
    Scope["PushCampaignRead"] = "push_campaign_read";
    // Segments
    Scope["SegmentUpdate"] = "segment_update";
    Scope["SegmentDelete"] = "segment_delete";
    Scope["SegmentCreate"] = "segment_create";
    Scope["SegmentRead"] = "segment_read";
    // Report type
    Scope["ReportTypeDelete"] = "report_type_delete";
    Scope["ReportTypeUpdate"] = "report_type_update";
    Scope["ReportTypeRead"] = "report_type_read";
    Scope["ReportTypeCreate"] = "report_type_create";
    // All reports
    Scope["AllReportRead"] = "all_report_read";
    Scope["AllReportUpdate"] = "all_report_update";
    // All images
    Scope["AllImageDelete"] = "all_image_delete";
    Scope["AllImageRead"] = "all_image_read";
    Scope["AllImageCreate"] = "all_image_create";
    Scope["AllImageUpdate"] = "all_image_update";
    // Application settings
    Scope["ApplicationSettingRead"] = "application_setting_read";
    Scope["ApplicationSettingUpdate"] = "application_setting_update";
    // Shop
    Scope["AllShopDelete"] = "all_shop_delete";
    Scope["AllShopUpdate"] = "all_shop_update";
    Scope["AllShopCreate"] = "all_shop_create";
    Scope["AllShopRead"] = "all_shop_read";
    // Archive
    Scope["ArchiveRead"] = "archive_read";
    Scope["ArchiveCreate"] = "archive_create";
    // Archives
    Scope["AllArchiveCreate"] = "all_archive_create";
    Scope["AllArchiveRead"] = "all_archive_read";
    // Moderator
    Scope["ModeratorCreate"] = "moderator_create";
    Scope["ModeratorDelete"] = "moderator_delete";
    Scope["ModeratorRead"] = "moderator_read";
    // Credit
    Scope["CreditUpdate"] = "credit_update";
    // Orders
    Scope["AllOrderRead"] = "all_order_read";
    Scope["AllOrderUpdate"] = "all_order_update";
    // Checkout
    Scope["CheckoutCreate"] = "checkout_create";
    // Pack
    Scope["PackUpdate"] = "pack_update";
    Scope["PackRead"] = "pack_read";
    Scope["PackCreate"] = "pack_create";
    Scope["PackDelete"] = "pack_delete";
    // Boosts
    Scope["AllBoostRead"] = "all_boost_read";
    // Recovery tokens
    Scope["AllRecoveryTokenRead"] = "all_recovery_token_read";
    Scope["AllRecoveryTokenCreate"] = "all_recovery_token_create";
    // Locale
    Scope["LocaleRead"] = "locale_read";
    // Analytics
    Scope["AnalyticsRead"] = "analytics_read";
    // Videos
    Scope["AllVideocallRead"] = "all_videocall_read";
    // Permission
    Scope["PermissionRead"] = "permission_read";
    // Language
    Scope["LanguageRead"] = "language_read";
    // Achievement
    Scope["AchievementTypeRead"] = "achievement_type_read";
    // Notifications
    Scope["AllNotificationRead"] = "all_notification_read";
    // Notification
    Scope["NotificationTypeRead"] = "notification_type_read";
    // Boost
    Scope["BoostCreate"] = "boost_create";
    Scope["BoostRead"] = "boost_read";
    // Subscription
    Scope["SubscriptionTypeRead"] = "subscription_type_read";
    // Audio calls
    Scope["AllAudioCallRead"] = "all_audiocall_read";
    // Trait
    Scope["TraitRead"] = "trait_read";
    // User
    Scope["UserCreate"] = "user_create";
    Scope["UserDelete"] = "user_delete";
    Scope["UserUpdate"] = "user_update";
    Scope["UserRead"] = "user_read";
    // User image
    Scope["UserImageCreate"] = "user_image_create";
    Scope["UserImageDelete"] = "user_image_delete";
    Scope["UserImageUpdate"] = "user_image_update";
    Scope["UserImageRead"] = "user_image_read";
    // User conversation
    Scope["UserConversationCreate"] = "user_conversation_create";
    Scope["UserConversationRead"] = "user_conversation_read";
    Scope["UserConversationDelete"] = "user_conversation_delete";
    Scope["UserConversationUpdate"] = "user_conversation_update";
    // User report
    Scope["UserReportRead"] = "user_report_read";
    Scope["UserReportUpdate"] = "user_report_update";
    Scope["UserReportDelete"] = "user_report_delete";
    Scope["UserReportCreate"] = "user_report_create";
    // User applications
    Scope["UserApplicationsUpdate"] = "user_applications_update";
    Scope["UserApplicationsRead"] = "user_applications_read";
    Scope["UserApplicationsDelete"] = "user_applications_delete";
    // User achievements
    Scope["UserAchievementCreate"] = "user_achievement_create";
    Scope["UserAchievementRead"] = "user_achievement_read";
    Scope["UserAchievementUpdate"] = "user_achievement_update";
    Scope["UserAchievementDelete"] = "user_achievement_delete";
    // User videocall
    Scope["UserVideocallRead"] = "user_videocall_read";
    Scope["UserVideocallUpdate"] = "user_videocall_update";
    Scope["UserVideocallCreate"] = "user_videocall_create";
    // User subscription
    Scope["UserSubscriptionCreate"] = "user_subscription_create";
    Scope["UserSubscriptionDelete"] = "user_subscription_delete";
    Scope["UserSubscriptionRead"] = "user_subscription_read";
    Scope["UserSubscriptionUpdate"] = "user_subscription_update";
    // User social
    Scope["UserSocialUpdate"] = "user_social_update";
    Scope["UserSocialCreate"] = "user_social_create";
    Scope["UserSocialDelete"] = "user_social_delete";
    Scope["UserSocialRead"] = "user_social_read";
    // User device
    Scope["UserDeviceDelete"] = "user_device_delete";
    Scope["UserDeviceUpdate"] = "user_device_update";
    Scope["UserDeviceRead"] = "user_device_read";
    Scope["UserDeviceCreate"] = "user_device_create";
    // User rejected
    Scope["UserRejectedCreate"] = "user_rejected_create";
    Scope["UserRejectedRead"] = "user_rejected_read";
    Scope["UserRejectedDelete"] = "user_rejected_delete";
    // User message
    Scope["UserMessageCreate"] = "user_message_create";
    Scope["UserMessageDelete"] = "user_message_delete";
    Scope["UserMessageRead"] = "user_message_read";
    Scope["UserMessageUpdate"] = "user_message_update";
    // User blocked
    Scope["UserBlockedCreate"] = "user_blocked_create";
    Scope["UserBlockedRead"] = "user_blocked_read";
    Scope["UserBlockedDelete"] = "user_blocked_delete";
    // User accepted
    Scope["UserAcceptedCreate"] = "user_accepted_create";
    Scope["UserAcceptedRead"] = "user_accepted_read";
    Scope["UserAcceptedDelete"] = "user_accepted_delete";
    // User ban bulk
    Scope["UserBanBulk"] = "user_ban_bulk";
    // User ban
    Scope["UserBan"] = "user_ban";
    // User unban
    Scope["UserUnban"] = "user_unban";
    // User order
    Scope["UserOrderCreate"] = "user_order_create";
    Scope["UserOrderRead"] = "user_order_read";
    Scope["UserOrderUpdate"] = "user_order_update";
    // User notifications
    Scope["UserNotificationsRead"] = "user_notifications_read";
    // User position
    Scope["UserPositionRead"] = "user_position_read";
    Scope["UserPositionUpdate"] = "user_position_update";
    // User audio call
    Scope["UserAudioCallUpdate"] = "user_audiocall_update";
    Scope["UserAudioCallCreate"] = "user_audiocall_create";
    Scope["UserAudioCallRead"] = "user_audiocall_read";
    // User trait
    Scope["UserTraitAnswerWrite"] = "user_trait_answer_write";
    // User mode
    Scope["UserModeRead"] = "user_mode_read";
    // User shop
    Scope["UserShopRead"] = "user_shop_read";
    // User poke
    Scope["UserPokeCreate"] = "user_poke_create";
    // User search
    Scope["SearchUser"] = "search_user";
    // Users update
    Scope["AllUserUpdate"] = "all_user_update";
    // Users read
    Scope["AllUserRead"] = "all_user_read";
    // Users delete
    Scope["AllUserDelete"] = "all_user_delete";
    // Users trait answer
    Scope["AllUserTraitAnswerRead"] = "all_user_trait_answer_read";
    // User segment read
    Scope["AllUserSegmentRead"] = "all_user_segment_read";
    // Payment read
    Scope["AllPaymentRead"] = "all_payment_read";
    // Payment write
    Scope["AllPaymentWrite"] = "all_payment_write";
})(Scope || (Scope = {}));
