import { gql } from '@apollo/client';
export const USER_MATCHING_PREFERENCES_QUERY = gql `
  query UserMatchingPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        matching {
          ageMin
          ageMax
          female
          male
          nonBinary
        }
      }
    }
  }
`;
