import styled from 'styled-components';
import { ThemeColor, getThemeColor, media } from '@happn/theme';
import DefaultPatterBg from './assets/defaultPattern.svg';
import PartternWithMapBg from './assets/partternWithMap.svg';
const CardInfo = styled.div `
  border-radius: 2rem;
  width: 100%;
  height: 8.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;

  background-image: ${({ variant }) => {
    switch (variant) {
        case 'pattern':
            return `url(${DefaultPatterBg})`;
        default:
            return `url(${PartternWithMapBg})`;
    }
}};

  background-color: ${({ variant }) => {
    switch (variant) {
        case 'cityOfResidence':
            return getThemeColor(ThemeColor.FillBrandLavender);
        case 'spot':
            return getThemeColor(ThemeColor.FillBrandSky);
        case 'geoloc':
            return getThemeColor(ThemeColor.FillSuccess);
        case 'notification':
            return getThemeColor(ThemeColor.FillBrandButter);
        case 'pattern':
        default:
            return getThemeColor(ThemeColor.FillBrandMallow);
    }
}};

  ${media.minSmall} {
    background-position-y: -2rem;
  }
`;
const AvatarSet = styled.div `
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  & > * {
    &:nth-of-type(odd) {
      transform: rotate(-5deg);
      transform: translateX(0.25rem) rotate(-5deg);
    }

    &:nth-of-type(even) {
      transform: translateX(-0.25rem) rotate(5deg);
    }
  }
`;
export default {
    CardInfo,
    AvatarSet,
};
