export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["Small"] = "small";
    ButtonSize["Large"] = "large";
    ButtonSize["XSmall"] = "xSmall";
    ButtonSize["Medium"] = "medium";
})(ButtonSize || (ButtonSize = {}));
export var ButtonVariant;
(function (ButtonVariant) {
    ButtonVariant["Primary"] = "primary";
    ButtonVariant["Secondary"] = "secondary";
    ButtonVariant["Tertiary"] = "tertiary";
    ButtonVariant["RoundedPrimary"] = "RoundedPrimary";
    ButtonVariant["RoundedSecondary"] = "RoundedSecondary";
    ButtonVariant["Transparent"] = "Transparent";
    ButtonVariant["Link"] = "Link";
})(ButtonVariant || (ButtonVariant = {}));
