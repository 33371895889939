var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import immutabilityUpdate from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import loggerService from '@happn/logger';
import { USER_UPDATE_MUTATION, USER_ID_ME, USER_QUERY, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
const useSensitiveTraitsPreferences = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const sensitiveTraitsPreferences = useMemo(() => data === null || data === void 0 ? void 0 : data.user.sensitiveTraitsPreferences, [data === null || data === void 0 ? void 0 : data.user.sensitiveTraitsPreferences]);
    const updateSensitiveTraitsPreferences = useCallback((newSensitiveTraitsPreferences) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        sensitive_traits_preferences: {
                            sensitive_traits_accepted: newSensitiveTraitsPreferences.sensitiveTraitsAccepted,
                        },
                    },
                },
                refetchQueries: [USER_QUERY],
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            sensitiveTraitsPreferences(current) {
                                return immutabilityUpdate(current, {
                                    sensitiveTraitsPreferences: {
                                        $set: {
                                            sensitiveTraitsAccepted: newSensitiveTraitsPreferences.sensitiveTraitsAccepted,
                                        },
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not marketing preferences, ${err}`));
            return false;
        }
    }), [apolloClient, id, userId]);
    return {
        sensitiveTraitsPreferences,
        updateSensitiveTraitsPreferences,
    };
};
export default useSensitiveTraitsPreferences;
