import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
export const USER_PHONE_NUMBERS_UPDATE_MUTATION = gql `
  mutation UserPhoneNumbersUpdateMutation($id: ID!, $input: Any) {
    userPhoneNumbersUpdate(id: $id, input: $input) @rest(
      path: "api/users/{args.id}/phone-numbers", 
      type: "${StatusResponseTypeName}",  
      method: "POST"
    ) {
      success
    }
  }
`;
export const USER_PHONE_NUMBERS_DELETE_MUTATION = gql `
  mutation UserPhoneNumbersDeleteMutation($id: ID!) {
    userPhoneNumbersDelete(id: $id) @rest(
      path: "api/users/{args.id}/phone-numbers", 
      type: "${StatusResponseTypeName}",  
      method: "DELETE"
    ) {
      success
    }
  }
`;
