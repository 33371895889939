import { gql } from '@apollo/client';
import { CityTypeName } from './types';
export const CITIES_QUERY = gql `
  query CitiesQuery($city: String, $limit: Number) {
    cities(city: $city, limit: $limit) @rest(
      path: "api/cities/search?query={args.city}&limit={args.limit}",
      type: "${CityTypeName}"
    ) {
      id
      city
      county
      country
      display
      position {
        longitude
        latitude
      }
    }
  }
`;
