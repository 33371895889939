import { nonUndefined } from '@happn/helpers';
export const getUserSegment = (featureId, segment) => {
    if (!(segment === null || segment === void 0 ? void 0 : segment.id)) {
        return undefined;
    }
    return {
        featureId,
        segmentId: segment.id,
    };
};
export const toUserSegmentsModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return [];
    }
    return Object.entries(res.data)
        .map(([featureId, segment]) => getUserSegment(featureId, segment))
        .filter(nonUndefined);
};
