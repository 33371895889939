import { gql } from '@apollo/client';
import { DEVICE_FRAGMENT } from '@happn/devices';
import { ApiTypes } from '@happn/types';
import { USER_SEARCH_FIELDS, USER_PROFILE_FIELDS, USER_FIELDS, USER_PENDING_LIKERS_FIELDS, USER_BALANCE_FIELDS, USER_LIKES_RECEIVED_FIELDS, USER_NOTIFICATIONS_FIELDS, USERS_EXPORTS_FIELDS, USER_UNREAD_FIELDS, } from './fields';
import { USER_PROFILE_FRAGMENT, USER_PENDING_LIKERS_FRAGMENT, USER_BALANCE_FRAGMENT, USER_AUDIO_FRAGMENT, USER_UNREAD_FRAGMENT, } from './fragments';
import { UserArchiveTypeName, UserProfileTypeName, UsersSearchTypeName, UserTypeName, UserMobileIdsTypeName, UserMobileTokensTypeName, UserPhoneNumbersTypeName, UserDevicesTypeName, UserRecoveryInfoTypeName, UserRecoveryTokenTypeName, UserLikesReceivedTypeName, UserCreditsTypeName, UserOrdersTypeName, SubscriptionTypeName, SubscriptionOrderTypeName, UserNotificationTypeName, UserArchiveDownloadLinkTypeName, RelationshipsBetweenUsersTypeName, UsersExportTypeName, UserPurchaseStatusTypeName, UserAclsTypeName, UserSegmentTypeName, UserPaymentIntentsTypeName, GenderAliasesTypeName, } from './types';
export const USER_PROFILE_QUERY = gql `
  ${USER_PROFILE_FRAGMENT}

  query UserProfileQuery($id: String!) {
    userProfile(id: $id) @rest (
      path: "api/users/{args.id}?fields=${USER_PROFILE_FIELDS}",
      type: "${UserProfileTypeName}"
    ) {
      ...UserProfile
    }
  }
`;
export const USER_QUERY = gql `
  ${USER_AUDIO_FRAGMENT}

  query UserQuery($id: String!) {
    user(id: $id) @rest (
        path: "api/users/{args.id}?fields=${USER_FIELDS}",
        type: "${UserTypeName}"
    ) {
      id
      firstName
      lastName
      gender
      genderAlias
      age
      about
      audios {
        ...UserAudio
      }
      job
      workplace
      school
      lastActivityDate
      birthdate
      onlineStatus
      isModerator
      isAdmin
      isSponsor
      status
      firstIp
      country
      language
      lastPositionUpdate
      registerDate
      deviceInfo
      sensitiveTraitsPreferences
      hideGenderAlias
      picture {
        id
        url
        isDefault
      }
      pictures {
        id
        url
        isDefault
      }
      traits {
        id
        emoji
        label
        shortLabel
        answer
        name
        type
        sensitive
        rawAnswer {
          type
          single {
            id
          }
          float_range {
            value
            metric
          }
        }
      }
      traitsV2 {
        id
        type
        answer
        answers
        categories
        rawAnswer {
          type
          single {
            id
          }
          float_range {
            value
            metric
          }
          multi {
            categoryId
            optionId
          }
          userInput {
            categoryId
            optionId
            answerText
            defaultText
          }
        }
      }
      unreadInfo {
          conversations
          notifications
        }
      isSub
      balance {
        type
        total
        permanent
        renewable
        renewablePerPeriod
        cooldownPeriod
        cooldownTimeLeft
      }
      lastAcceptedVersions {
        tos
        cookieV1
        sdc
      }
      preferences {
        matching {
          ageMin
          ageMax
          female
          male
          nonBinary
        }
        location {
          hide
        }
        mysteriousMode {
          hideGenderAlias
        }
        marketing {
          audienceMeasurement
          operations
          targetedAds
          recommendedInEmails
        }
        notifications {
          charms
          crush
          crushTime
          dailyRecap
          news
          others
          weeklyRecap
          dates
          likes
          messages
          near
          reminders
          visit
        }
        matchingTraits 
        biometric {
          profileVerification {
            accepted
          }
        }
      }
      locationCity {
        id
        city
        county
        country
        display
        position {
          longitude
          latitude
        }
      }
      residenceCity {
        id
        city
        county
        country
        display
        position {
          longitude
          latitude
        }
      }
      pendingLikers {
        label
        iconUrl
      }
      email
      verification {
        status
        reason
      }
      socialMedia {
        instagram {
          pictures {
            id
            createdAt
            description
            link
            images {
              url
            }
          }
        }
        facebook {
          id
          email
        }
        apple {
          id
          email
        }
        google {
          id
          email
        }
    }
    subscription {
      creationDate
      expirationDate
      isProcessed
      userId
      plan {
        id
        name
        channel
      }
    }
    subscriptionLevel
    teaserAnswers
    }
  }
`;
export const USER_MOBILE_IDS_QUERY = gql `
  query UserMobileIdsQuery($id: String!) {
    userMobileIds(id: $id) @rest(
      type: "${UserMobileIdsTypeName}", 
      path: "api/users/{args.id}/mobile-ids?user_id={args.id}"
    ) {
      id
      mobileIds
    }
  }
`;
export const USER_MOBILE_TOKENS_QUERY = gql `
  query UserMobileTokensQuery($id: String!) {
    userMobileTokens(id: $id) @rest(
      type: "${UserMobileTokensTypeName}", 
      path: "api/users/{args.id}/mobile-tokens?user_id={args.id}"
    ) {
      id
      mobileTokens
    }
  }
`;
export const USER_PHONE_NUMBERS_QUERY = gql `
  query UserMobileTokensQuery($id: String!) {
    userPhoneNumbers(id: $id) @rest(
      type: "${UserPhoneNumbersTypeName}", 
      path: "api/users/{args.id}/phone-numbers?user_id={args.id}"
    ) {
      id
      phoneNumbers
    }
  }
`;
export const USER_DEVICES_QUERY = gql `
  ${DEVICE_FRAGMENT}
 
  query UserDevicesQuery($id: String!) {
    userDevices(id: $id) @rest(
      type: "${UserDevicesTypeName}"
      path: "api/users/{args.id}/devices?user_id={args.id}"
    ) {
      id
      devices {
        ...DeviceFields
      }
    }
  }
`;
export const USER_RECOVERY_INFO_QUERY = gql `
  query UserRecoveryInfoQuery($id: String!) {
    userRecoveryInfo(id: $id) @rest(
      type: "${UserRecoveryInfoTypeName}"
      path: "api/users/{args.id}/recovery-info?user_id={args.id}"
    ) {
      id
      recoveryInfo {
        type
        value
        status
      }
    }
  }
`;
export const USER_RECOVERY_TOKEN_QUERY = gql `
  query UserRecoveryTokenQuery($id: String!, $input: Any) {
    userRecoveryToken(id: $id, input: $input) @rest(
      method: "POST",
      type: "${UserRecoveryTokenTypeName}",
      path: "api/v1/identities/{args.id}/recovery-tokens",
    ) {
      recoveryUrl
    }
  }
`;
export const USER_PENDING_LIKERS_QUERY = gql `
  ${USER_PENDING_LIKERS_FRAGMENT}

  query UserPendingLikersQuery($id: String!) {
    user(id: $id) @rest(
      type: "${UserTypeName}", 
      path: "api/users/{args.id}?fields=${USER_PENDING_LIKERS_FIELDS}"
    ) {
      id
      ...UserPendingLikers
    }
  }
`;
export const USER_UNREAD_INFO_QUERY = gql `
  ${USER_UNREAD_FRAGMENT}

  query UserUnreadInfoQuery($id: String!) {
    user(id: $id) @rest(
      type: "${UserTypeName}", 
      path: "api/users/{args.id}?fields=${USER_UNREAD_FIELDS}"
    ) {
      id
      ...UserUnreadInfo
    }
  }
`;
export const USER_BALANCE_QUERY = gql `
  ${USER_BALANCE_FRAGMENT}

  query UserBalanceQuery($id: String) {
    user(id: $id) @rest(
      type: "${UserTypeName}", 
      path: "api/users/{args.id}?fields=${USER_BALANCE_FIELDS}"
    ) {
      id
      ...UserBalance
    }
  }
`;
export const USER_LIKES_RECEIVED_QUERY = gql `
  query UserLikesReceivedQuery($id: String, $limit: Number, $scrollIdFrom: String) {
    userLikesReceived(
      id: $id, 
      limit: $limit, 
      scroll_id_from: $scrollIdFrom
    ) @rest(
      path: "api/users/{args.id}/likes-received?fields=${USER_LIKES_RECEIVED_FIELDS}&scroll_id_from={args.scroll_id_from}&limit={args.limit}",
      type: "${UserLikesReceivedTypeName}"
    ) {
      items {
        id
        age
        gender
        genderAlias
        status
        job
        picture {
          id
          url
          isDefault
        }
        pictures {
          id
          url
          isDefault
        }
        firstName
        isAccepted
        onlineStatus
        hasCharmedMe
        lastActivityDate
      }
      pagination {
        count
        firstScrollId
        lastScrollId
        totalCount
        isLastPage
      }
    }
  }
`;
export const USERS_SEARCH_QUERY = gql `
  query UsersSearchQuery($input: Any) {
    usersSearch(input: $input) @rest(
      method: "POST",
      path: "api/v1/users-search?fields=${USER_SEARCH_FIELDS}",
      type: "${UsersSearchTypeName}",
    ) {
      users {
        id
        firstName
        lastName
        gender
        job
        picture {
          id
          url
          isDefault
        }
        pictures {
          id
          url
          isDefault
        }
        age
        country
        status
        registerDate
        lastActivityDate
        lastPositionUpdate
        preferences {
          matching {
            ageMin
            ageMax
            male
            female
            nonBinary
          }
        }
        about
        device
        status
        email
      }
      pagination {
        totalCount
      }
    }
  }
`;
export const USER_STANDARD_CREDITS_QUERY = gql `
  query UserStandardCreditsQuery($id: String, $limit: Number!, $offset: Number!, $creditType: String! ) {
    userCredits(
      id: $id,
      limit: $limit,
      offset: $offset,
      creditType: $creditType
    ) @rest (
      path: "api/users/{args.id}/credits?offset={args.offset}&limit={args.limit}&credit_type={args.creditType}"
      type: "${UserCreditsTypeName}"
    ) {
      items {
        amount
        creationDate
        refId
        transactionType
        type
      }
      pagination {
        count
        totalCount
      }
    }
  }
`;
export const USER_RENEWABLE_CREDITS_QUERY = gql `
  query UserRenewableCreditsQuery($id: String, $limit: Number!, $offset: Number!, $creditType: String! ) {
    userCredits(
      id: $id,
      limit: $limit,
      offset: $offset,
      creditType: $creditType
    ) @rest (
      path: "api/users/{args.id}/credits/renewable?offset={args.offset}&limit={args.limit}&credit_type={args.creditType}"
      type: "${UserCreditsTypeName}"
    ) {
      items {
        amount
        creationDate
        refId
        transactionType
        type
      }
      pagination {
        count
        totalCount
      }
    }
  }
`;
export const USER_ORDERS_QUERY = gql `
  query UserOrdersQuery($id: String, $offset: Number, $limit: Number) {
    userOrders(
      id: $id,
      offset: $offset,
      limit: $limit
    ) @rest(
      path: "api/users/{args.id}/orders?limit={args.limit}&offset={args.offset}"
      type: ${UserOrdersTypeName}
    ) {
      items {
        id
        channel
        label
        quantity
        creationDate
        currency
        releaseDate
        sandbox
        totalAmountAti
        ordersCount
      }
      pagination {
        count
      }
    }
  }
`;
export const SUBSCRIPTIONS_QUERY = gql `
  query SubscriptionsQuery($id: String!) {
    subscriptions(id: $id) @rest(
      path: "api/users/{args.id}/subscriptions?purchased=true&internal=true"
      type: ${SubscriptionTypeName}
    ) {
      id
      type
      channel
      endDate
      cancellationDate
      introductory
      reactivation
      startDate
      status
      userId
      level
    }
  }
`;
export const SUBSCRIPTION_ORDERS_QUERY = gql `
  query SubscriptionOrdersQuery(
    $id: String, 
    $subscriptionId: String
  ) {
    subscriptionOrders(id: $id, subscriptionId: $subscriptionId) @rest(
      path: "api/users/{args.id}/subscriptions/{args.subscriptionId}/orders"
      type: ${SubscriptionOrderTypeName}
    ) {
      amount
      channel
      currency
      id
      purchaseDate
      releaseDate
      sandbox
      storeProductId
    }
  }
`;
export const USER_CHARMS_RECEIVED_QUERY = gql `
  query UserCharmsReceivedQuery($id: String, $offset: number, $limit: number) {
    userCharmsReceived(id: $id, offset: $offset, limit: $limit) @rest(
      type: ${UserNotificationTypeName}
      path: "api/v1/users/{args.id}/charms-received?status=1&limit={args.limit}&offset={args.offset}&fields=${USER_NOTIFICATIONS_FIELDS}&types=${ApiTypes.UserNotificationEnum.POKED_YOU}"
    ) {
      id
      modificationDate
      type
      user {
        id
        firstName
        lastName
        gender
        picture {
          id
          url
          isDefault
        }
      }
    }
  }
`;
export const USER_CRUSHES_RECEIVED_QUERY = gql `
  query UserCrushesReceivedQuery($id: String, $offset: number, $limit: number) {
    userCrushesReceived(id: $id, offset: $offset, limit: $limit) @rest(
      type: ${UserNotificationTypeName}
      path: "api/users/{args.id}/notifications?status=1&limit={args.limit}&offset={args.offset}&fields=${USER_NOTIFICATIONS_FIELDS}&types=563"
    ) {
      id
      modificationDate
      type
      user {
        id
        firstName
        lastName
        gender
        picture {
          id
          url
          isDefault
        }
      }
    }
  }
`;
export const USER_CROSSINGS_RECEIVED_QUERY = gql `
  query UserCrossingsQuery($id: String, $offset: number, $limit: number) {
    userCrossings(id: $id, offset: $offset, limit: $limit) @rest(
      type: ${UserNotificationTypeName}
      path: "api/users/{args.id}/crossings?status=1&limit={args.limit}&offset={args.offset}&fields=${USER_NOTIFICATIONS_FIELDS}"
    ) {
      id
      modificationDate
      type
      count
      user {
        id
        firstName
        lastName
        gender
        status
        picture {
          id
          url
          isDefault
        }
      }
    }
  }
`;
export const USER_ARCHIVES_QUERY = gql `
  query UserArchivesQuery($id: String) {
    userArchives(id: $id) @rest(
      type: ${UserArchiveTypeName}
      path: "api/v1/users/{args.id}/archives"
      endpoint: "bi"
    ) {
      id
      archiveReason
      archiveState
      archiveType
      isDone
      creationDate
      processStartDate
      requesterComment
      requesterId
      userId
    }
  }
`;
export const USER_ARCHIVE_DOWNLOAD_LINK_QUERY = gql `
  query UserArchiveDownloadLinkQuery($userId: String, $processId: String) {
    userArchiveDownloadLink(userId: $userId, processId: $processId) @rest(
      type: ${UserArchiveDownloadLinkTypeName}
      path: "api/v1/users/{args.userId}/archives/{args.processId}/download-link/"
      endpoint: "bi"
    ) {
      url
    }
  }
`;
export const RELATIONSHIPS_BETWEEN_USERS_QUERY = gql `
  query RelationshipsBetweenUsersQuery($userIdA: String, $userIdB: String) {
    relationshipsBetweenUsers(userIdA: $userIdA, userIdB: $userIdB) @rest(
      type: ${RelationshipsBetweenUsersTypeName}
      path: "api/users/{args.userIdA}/relationships/{args.userIdB}"
    ) {
      statusAB
      statusBA
    }
  }
`;
export const USERS_EXPORTS_QUERY = gql `
  query UsersExportsQuery($offset: number, $limit: number) {
    usersExports(offset: $offset, limit: $limit) @rest(
      type: ${UsersExportTypeName}
      path: "api/v1/users-exports?offset={args.offset}&limit={args.limit}&fields=${USERS_EXPORTS_FIELDS}"
    ) {
      id
      exportDate
      exporterUserId
      fields
      fileName
      filter
      progressPercentage
      signedUrl
    }
  }
`;
export const USER_PURCHASE_STATUS_QUERY = gql `
  query UserPurchaseStatusQuery($id: String, $purchaseId: String) {
    userPurchaseStatus(id: $id, purchaseId: $purchaseId)
      @rest( 
        type: ${UserPurchaseStatusTypeName}
        path: "api/v1/users/{args.id}/purchases/{args.purchaseId}/status"
      ) {
      delivered
    }
  }
`;
export const USER_ACLS_QUERY = gql `
  query UserAclsQuery($id: String) {
    userAcls(id: $id)
      @rest( 
        type: ${UserAclsTypeName}
        path: "api/user-acls/{args.id}"
      ) {
        id
        acls
    }
  }
`;
export const USER_SEGMENTS_QUERY = gql `
  query UserSegmentsQuery($userId: String, $featureIds: String) {
    userSegments(userId: $userId, featureIds: $featureIds) @rest(
      type: ${UserSegmentTypeName}
      path: "api/v1/users/{args.userId}/segments?feature_ids={args.featureIds}"
    ) {
      featureId
      segmentId
    }
  }
`;
export const USER_PAYMENT_INTENTS_QUERY = gql `
  query UserPaymentIntentsQuery($userId: String, $query: String) {
    userPaymentIntents(userId: $userId, query: $query) @rest(
      type: ${UserPaymentIntentsTypeName}
      path: "api/v1/users/{args.userId}/payment-intents?{args.query}"
    ) {
      items {
        id
        status
        creationDate
        amount
        currency
      }
      pagination {
        count
        firstScrollId
        lastScrollId
        totalCount
        isLastPage
      }
    }
  }
`;
export const GENDER_ALIASES_QUERY = gql `
  query GenderAliasesQuery($gender: String) {
    genderAliases(gender: $gender) @rest(
      path: "api/v1/gender-aliases?gender={args.gender}",
      type: "${GenderAliasesTypeName}"
    ) {
      id
      gender
      label
      defaultLabel
    }
  }
`;
