import styled, { css } from 'styled-components';
import { getThemeColor, ThemeColor } from '@happn/theme';
const largeRadius = css `
  border-radius: 2rem;
`;
const mediumRadius = css `
  border-radius: 1.5rem;
`;
const smallRadius = css `
  border-radius: 1rem;
`;
const Picture = styled.img `
  width: ${({ width }) => width !== null && width !== void 0 ? width : '100%'};
  height: ${({ height }) => height !== null && height !== void 0 ? height : '100%'};
  filter: ${({ blur }) => blur ? 'blur(1rem)' : ''};
  object-fit: cover;
  background-color: ${getThemeColor(ThemeColor.FillLightSubdued)};
  -webkit-user-drag: none;

  /* For hidden "alt" style when image isn't present */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;

  ${({ radius }) => {
    switch (radius) {
        case 'large':
            return largeRadius;
        case 'medium':
            return mediumRadius;
        case 'small':
        default:
            return smallRadius;
    }
}}
`;
export default {
    Picture,
};
