import { VerificationStatusType } from '../types';
export const getVerification = (gto) => {
    if (!gto.verification) {
        return null;
    }
    return {
        status: gto.verification.status
            ? gto.verification.status
            : VerificationStatusType.Unverified,
        reason: gto.verification.reason || '',
    };
};
