export var ArchiveTypeEnum;
(function (ArchiveTypeEnum) {
    ArchiveTypeEnum["Html"] = "HTML";
    ArchiveTypeEnum["Tarball"] = "TARBALL";
})(ArchiveTypeEnum || (ArchiveTypeEnum = {}));
export var ArchiveReasonEnum;
(function (ArchiveReasonEnum) {
    ArchiveReasonEnum["Manual"] = "MANUAL";
    ArchiveReasonEnum["DataRequest"] = "DATA_REQUEST";
})(ArchiveReasonEnum || (ArchiveReasonEnum = {}));
export var ArchiveStateEnum;
(function (ArchiveStateEnum) {
    ArchiveStateEnum["Pending"] = "PENDING";
    ArchiveStateEnum["Created"] = "CREATED";
})(ArchiveStateEnum || (ArchiveStateEnum = {}));
export var BoostReportGtoStatusEnum;
(function (BoostReportGtoStatusEnum) {
    BoostReportGtoStatusEnum["RUNNING"] = "RUNNING";
    BoostReportGtoStatusEnum["FINISHED"] = "FINISHED";
    BoostReportGtoStatusEnum["EXPIRED"] = "EXPIRED";
})(BoostReportGtoStatusEnum || (BoostReportGtoStatusEnum = {}));
export var CredentialsGrantTypeEnum;
(function (CredentialsGrantTypeEnum) {
    CredentialsGrantTypeEnum["Password"] = "password";
    CredentialsGrantTypeEnum["Assertion"] = "assertion";
    CredentialsGrantTypeEnum["RefreshToken"] = "refresh_token";
})(CredentialsGrantTypeEnum || (CredentialsGrantTypeEnum = {}));
export var CredentialsAssertionTypeEnum;
(function (CredentialsAssertionTypeEnum) {
    CredentialsAssertionTypeEnum["FacebookAccessToken"] = "facebook_access_token";
    CredentialsAssertionTypeEnum["FacebookAccessTokenAndMobileData"] = "facebook_access_token_and_mobile_data";
    CredentialsAssertionTypeEnum["VkAccessToken"] = "vk_access_token";
    CredentialsAssertionTypeEnum["PhoneNumberAndMobileToken"] = "phone_number_and_mobile_token";
    CredentialsAssertionTypeEnum["PhoneNumberVerifiedTokenWithBirthDateAndFirstName"] = "phone_number_verified_token_with_birth_date_and_first_name";
    CredentialsAssertionTypeEnum["PhoneNumberVerifiedTokenWithBirthDate"] = "phone_number_verified_token_with_birth_date";
    CredentialsAssertionTypeEnum["PhoneNumberVerifiedTokenWithMobileId"] = "phone_number_verified_token_with_mobile_id";
    CredentialsAssertionTypeEnum["PhoneNumberVerifiedTokenWithFacebookToken"] = "phone_number_verified_token_with_facebook_token";
    CredentialsAssertionTypeEnum["PhoneNumberVerifiedToken"] = "phone_number_verified_token";
    CredentialsAssertionTypeEnum["AppleSignInTokenAndMobileData"] = "apple_sign_in_token_and_mobile_data";
    CredentialsAssertionTypeEnum["RecoveryToken"] = "recovery_token";
    CredentialsAssertionTypeEnum["GoogleSignInTokenAndMobileData"] = "google_sign_in_token_and_mobile_data";
})(CredentialsAssertionTypeEnum || (CredentialsAssertionTypeEnum = {}));
export var CreditTypeGto;
(function (CreditTypeGto) {
    CreditTypeGto["Credit"] = "credit";
    CreditTypeGto["Video"] = "video";
    CreditTypeGto["Boost"] = "boost";
    CreditTypeGto["MiniBoost"] = "mini_boost";
    CreditTypeGto["Like"] = "like";
})(CreditTypeGto || (CreditTypeGto = {}));
// ShopV2
export var CreditDtoCategoryEnum;
(function (CreditDtoCategoryEnum) {
    CreditDtoCategoryEnum["PERMANENT"] = "CREDIT_PERMANENT";
    CreditDtoCategoryEnum["RENEWABLE"] = "CREDIT_RENEWABLE";
})(CreditDtoCategoryEnum || (CreditDtoCategoryEnum = {}));
// ShopV2
export var CreditDtoTypeEnum;
(function (CreditDtoTypeEnum) {
    CreditDtoTypeEnum["CHARM"] = "CREDIT_CHARM";
    CreditDtoTypeEnum["BOOST"] = "CREDIT_BOOST";
    CreditDtoTypeEnum["MINI_BOOST"] = "CREDIT_MINI_BOOST";
    CreditDtoTypeEnum["VIDEO"] = "CREDIT_VIDEO";
})(CreditDtoTypeEnum || (CreditDtoTypeEnum = {}));
export var CreditRenewablePeriodDtoUnitEnum;
(function (CreditRenewablePeriodDtoUnitEnum) {
    CreditRenewablePeriodDtoUnitEnum["DAY"] = "RENEWABLE_PERIOD_DAY";
    CreditRenewablePeriodDtoUnitEnum["HOUR"] = "RENEWABLE_PERIOD_HOUR";
    CreditRenewablePeriodDtoUnitEnum["MINUTE"] = "RENEWABLE_PERIOD_MINUTE";
})(CreditRenewablePeriodDtoUnitEnum || (CreditRenewablePeriodDtoUnitEnum = {}));
export var InstagramUserStatusResponseDataEnum;
(function (InstagramUserStatusResponseDataEnum) {
    InstagramUserStatusResponseDataEnum["NOTDELETED"] = "NOT_DELETED";
    InstagramUserStatusResponseDataEnum["DELETED"] = "DELETED";
    InstagramUserStatusResponseDataEnum["PENDING"] = "PENDING";
    InstagramUserStatusResponseDataEnum["INVALIDCONFIRMATIONCODE"] = "INVALID_CONFIRMATION_CODE";
})(InstagramUserStatusResponseDataEnum || (InstagramUserStatusResponseDataEnum = {}));
export var LocaleStatus;
(function (LocaleStatus) {
    LocaleStatus["ACTIVE"] = "ACTIVE";
    LocaleStatus["INACTIVE"] = "INACTIVE";
})(LocaleStatus || (LocaleStatus = {}));
export var PendingLikersConfigurationGtoEntryLocationsEnum;
(function (PendingLikersConfigurationGtoEntryLocationsEnum) {
    PendingLikersConfigurationGtoEntryLocationsEnum["Conversations"] = "conversations";
    PendingLikersConfigurationGtoEntryLocationsEnum["Stack"] = "stack";
})(PendingLikersConfigurationGtoEntryLocationsEnum || (PendingLikersConfigurationGtoEntryLocationsEnum = {}));
export var PeriodGtoUnitEnum;
(function (PeriodGtoUnitEnum) {
    PeriodGtoUnitEnum["YEAR"] = "YEAR";
    PeriodGtoUnitEnum["MONTH"] = "MONTH";
    PeriodGtoUnitEnum["WEEK"] = "WEEK";
    PeriodGtoUnitEnum["DAY"] = "DAY";
    PeriodGtoUnitEnum["HOUR"] = "HOUR";
    PeriodGtoUnitEnum["MINUTE"] = "MINUTE";
    PeriodGtoUnitEnum["SECOND"] = "SECOND";
})(PeriodGtoUnitEnum || (PeriodGtoUnitEnum = {}));
export var BillingDtoTypeEnum;
(function (BillingDtoTypeEnum) {
    BillingDtoTypeEnum["ONESHOT"] = "BILLING_ONE_SHOT";
    BillingDtoTypeEnum["RECURRING"] = "BILLING_RECURRING";
})(BillingDtoTypeEnum || (BillingDtoTypeEnum = {}));
export var BillingPeriodDtoUnitEnum;
(function (BillingPeriodDtoUnitEnum) {
    BillingPeriodDtoUnitEnum["YEAR"] = "BILLING_PERIOD_YEAR";
    BillingPeriodDtoUnitEnum["MONTH"] = "BILLING_PERIOD_MONTH";
    BillingPeriodDtoUnitEnum["DAY"] = "BILLING_PERIOD_DAY";
    BillingPeriodDtoUnitEnum["MINUTE"] = "BILLING_PERIOD_MINUTE";
})(BillingPeriodDtoUnitEnum || (BillingPeriodDtoUnitEnum = {}));
export var ProductGtoChannelEnum;
(function (ProductGtoChannelEnum) {
    ProductGtoChannelEnum["PLAYSTORE"] = "PLAYSTORE";
    ProductGtoChannelEnum["WINDOWS"] = "WINDOWS";
    ProductGtoChannelEnum["ITUNES"] = "ITUNES";
    ProductGtoChannelEnum["INTERNAL"] = "INTERNAL";
    ProductGtoChannelEnum["STRIPE"] = "STRIPE";
})(ProductGtoChannelEnum || (ProductGtoChannelEnum = {}));
export var ProductGtoProductTypeEnum;
(function (ProductGtoProductTypeEnum) {
    ProductGtoProductTypeEnum["PACK"] = "PACK";
    ProductGtoProductTypeEnum["PLAN"] = "PLAN";
})(ProductGtoProductTypeEnum || (ProductGtoProductTypeEnum = {}));
// ShopV2
export var ProductGtoProductCategoryEnum;
(function (ProductGtoProductCategoryEnum) {
    ProductGtoProductCategoryEnum["PACK"] = "PACK";
    ProductGtoProductCategoryEnum["PLAN"] = "PLAN";
})(ProductGtoProductCategoryEnum || (ProductGtoProductCategoryEnum = {}));
// ShopV2
export var ProductGtoProductOfferTypeEnum;
(function (ProductGtoProductOfferTypeEnum) {
    ProductGtoProductOfferTypeEnum["STANDARD"] = "OFFER_STANDARD";
    ProductGtoProductOfferTypeEnum["INTRODUCTORY"] = "OFFER_INTRODUCTORY";
    ProductGtoProductOfferTypeEnum["REACTIVATION"] = "OFFER_REACTIVATION";
    ProductGtoProductOfferTypeEnum["PROMOTIONAL"] = "OFFER_PROMOTIONAL";
})(ProductGtoProductOfferTypeEnum || (ProductGtoProductOfferTypeEnum = {}));
// ShopV2
export var ProductGtoTypeEnum;
(function (ProductGtoTypeEnum) {
    ProductGtoTypeEnum["PLAN_ESSENTIAL"] = "PLAN_ESSENTIAL";
    ProductGtoTypeEnum["PLAN_PREMIUM"] = "PLAN_PREMIUM";
    ProductGtoTypeEnum["PLAN_DELUXE"] = "PLAN_DELUXE";
    ProductGtoTypeEnum["PACK_CHARM"] = "PACK_CHARM";
    ProductGtoTypeEnum["PACK_BOOST"] = "PACK_BOOST";
    ProductGtoTypeEnum["PACK_MINI_BOOST"] = "PACK_MINI_BOOST";
    ProductGtoTypeEnum["PACK_VIDEO"] = "PACK_VIDEO";
})(ProductGtoTypeEnum || (ProductGtoTypeEnum = {}));
// ShopV2
export var ShopDtoTypeEnum;
(function (ShopDtoTypeEnum) {
    ShopDtoTypeEnum["SHOP_PLAN_ESSENTIAL"] = "SHOP_PLAN_ESSENTIAL";
    ShopDtoTypeEnum["SHOP_PLAN_PREMIUM"] = "SHOP_PLAN_PREMIUM";
    ShopDtoTypeEnum["SHOP_PLAN_DELUXE"] = "SHOP_PLAN_DELUXE";
    ShopDtoTypeEnum["SHOP_PACK_CHARM"] = "SHOP_PACK_CHARM";
    ShopDtoTypeEnum["SHOP_PACK_BOOST"] = "SHOP_PACK_BOOST";
    ShopDtoTypeEnum["SHOP_PACK_MINI_BOOST"] = "SHOP_PACK_MINI_BOOST";
    ShopDtoTypeEnum["SHOP_PACK_VIDEO"] = "SHOP_PACK_VIDEO";
})(ShopDtoTypeEnum || (ShopDtoTypeEnum = {}));
export var StoreDtoTypeEnum;
(function (StoreDtoTypeEnum) {
    StoreDtoTypeEnum["PLAYSTORE"] = "STORE_PLAYSTORE";
    StoreDtoTypeEnum["ITUNES"] = "STORE_ITUNES";
    StoreDtoTypeEnum["STRIPE"] = "STORE_STRIPE";
})(StoreDtoTypeEnum || (StoreDtoTypeEnum = {}));
export var RecommendationType;
(function (RecommendationType) {
    RecommendationType[RecommendationType["NUMBER_0"] = 0] = "NUMBER_0";
    RecommendationType[RecommendationType["NUMBER_1"] = 1] = "NUMBER_1";
    RecommendationType[RecommendationType["NUMBER_2"] = 2] = "NUMBER_2";
    RecommendationType[RecommendationType["NUMBER_3"] = 3] = "NUMBER_3";
    RecommendationType[RecommendationType["NUMBER_4"] = 4] = "NUMBER_4";
})(RecommendationType || (RecommendationType = {}));
export var SubscriptionTypeEnum;
(function (SubscriptionTypeEnum) {
    SubscriptionTypeEnum["Internal"] = "INTERNAL";
    SubscriptionTypeEnum["Purchased"] = "PURCHASED";
})(SubscriptionTypeEnum || (SubscriptionTypeEnum = {}));
export var SubscriptionLevelEnum;
(function (SubscriptionLevelEnum) {
    SubscriptionLevelEnum["Freemium"] = "SUBSCRIPTION_LEVEL_FREEMIUM";
    SubscriptionLevelEnum["Essential"] = "SUBSCRIPTION_LEVEL_ESSENTIAL";
    SubscriptionLevelEnum["Premium"] = "SUBSCRIPTION_LEVEL_PREMIUM";
    SubscriptionLevelEnum["Deluxe"] = "SUBSCRIPTION_LEVEL_DELUXE";
})(SubscriptionLevelEnum || (SubscriptionLevelEnum = {}));
export var SubscriptionGtoChannelEnum;
(function (SubscriptionGtoChannelEnum) {
    SubscriptionGtoChannelEnum["PLAYSTORE"] = "PLAYSTORE";
    SubscriptionGtoChannelEnum["WINDOWS"] = "WINDOWS";
    SubscriptionGtoChannelEnum["ITUNES"] = "ITUNES";
    SubscriptionGtoChannelEnum["INTERNAL"] = "INTERNAL";
    SubscriptionGtoChannelEnum["STRIPE"] = "STRIPE";
})(SubscriptionGtoChannelEnum || (SubscriptionGtoChannelEnum = {}));
export var TraitTypeGto;
(function (TraitTypeGto) {
    TraitTypeGto["FLOATRANGE"] = "FLOAT_RANGE";
    TraitTypeGto["SINGLE"] = "SINGLE";
    TraitTypeGto["USER_INPUT"] = "USER_INPUT";
})(TraitTypeGto || (TraitTypeGto = {}));
export var TraitV2TypeGTO;
(function (TraitV2TypeGTO) {
    TraitV2TypeGTO["SINGLE"] = "SINGLE_CHOICE";
    TraitV2TypeGTO["MULTI"] = "MULTI_CHOICE";
    TraitV2TypeGTO["FLOAT_RANGE"] = "FLOAT_RANGE";
    TraitV2TypeGTO["USER_INPUT"] = "USER_INPUT";
})(TraitV2TypeGTO || (TraitV2TypeGTO = {}));
export var UserCreationGtoTypeEnum;
(function (UserCreationGtoTypeEnum) {
    UserCreationGtoTypeEnum["CLIENT"] = "CLIENT";
    UserCreationGtoTypeEnum["COMMUNITY"] = "COMMUNITY";
})(UserCreationGtoTypeEnum || (UserCreationGtoTypeEnum = {}));
export var UserGenderEnum;
(function (UserGenderEnum) {
    UserGenderEnum["Male"] = "male";
    UserGenderEnum["Female"] = "female";
    UserGenderEnum["NonBinary"] = "non_binary";
    UserGenderEnum["Unknown"] = "unknown";
})(UserGenderEnum || (UserGenderEnum = {}));
export var UserRecoveryInfoStatus;
(function (UserRecoveryInfoStatus) {
    UserRecoveryInfoStatus["PENDING"] = "PENDING";
    UserRecoveryInfoStatus["VERIFIED"] = "VERIFIED";
})(UserRecoveryInfoStatus || (UserRecoveryInfoStatus = {}));
export var UserRecoveryInfoType;
(function (UserRecoveryInfoType) {
    UserRecoveryInfoType["EMAIL"] = "EMAIL";
})(UserRecoveryInfoType || (UserRecoveryInfoType = {}));
export var UserNotificationEnum;
(function (UserNotificationEnum) {
    // Used
    // @see https://github.com/happn-app/happn-php/blob/aaae08bb3c806dad78967cc3f1162394591beae6/application/services/Event/Service/Event.php#L24
    UserNotificationEnum["REWARD_NEW_ACCOUNT"] = "REWARD_NEW_ACCOUNT";
    UserNotificationEnum["POKED_YOU"] = "POKED_YOU";
    UserNotificationEnum["MATCH"] = "MATCH";
    // Extra probably will be used in future
    // @see https://github.com/happn-app/happn-php/blob/2a7406d12dd669eec470a64f146bdd9305078108/application/services/Achievement/HttpClient/AchievementTypeClient.php#L81
    // SOCIAL_LIKE = 'SOCIAL_LIKE', // => 791
    // START_TUTORIAL = 'START_TUTORIAL', // => 792
    // Not used
    // SENT_MESSAGE = 'SENT_MESSAGE', // => 473,
    // WIN_FACEBOOK_CONNECT = 'WIN_FACEBOOK_CONNECT', // => 522,
    // WIN_PHOTO_UPLOAD = 'WIN_PHOTO_UPLOAD', // => 523,
    // WIN_LIKE_PAGE = 'WIN_LIKE_PAGE', // => 526,
    // REWARD_FACEBOOK_CONNECT = 'REWARD_FACEBOOK_CONNECT', // => 527,
    // REWARD_PHOTO_UPLOAD = 'REWARD_PHOTO_UPLOAD', // => 528,
    // REWARD_LIKE_PAGE = 'REWARD_LIKE_PAGE', // => 531,
    // VISITED_PROFILE = 'VISITED_PROFILE', // => 532,
    // REWARD_OFFER = 'REWARD_OFFER', // => 566
    // we should ignore it
    // WIN_RATING_APP = 'WIN_RATING_APP', // => 524,
    // WIN_INVITE = 'WIN_INVITE', // => 525,
    // REWARD_INVITE = 'REWARD_INVITE', // => 530,
    // REWARD_RATING_APP = 'REWARD_RATING_APP', // => 529,
})(UserNotificationEnum || (UserNotificationEnum = {}));
export var ShopRulesTypeEnum;
(function (ShopRulesTypeEnum) {
    ShopRulesTypeEnum["Standard"] = "STANDARD";
    ShopRulesTypeEnum["Introductory"] = "INTRODUCTORY";
    ShopRulesTypeEnum["Reactivation"] = "REACTIVATION";
    ShopRulesTypeEnum["BasicOffer"] = "BASIC_OFFER";
})(ShopRulesTypeEnum || (ShopRulesTypeEnum = {}));
// Shop v2
export var ShopRulesFilterDeviceEnum;
(function (ShopRulesFilterDeviceEnum) {
    ShopRulesFilterDeviceEnum["Android"] = "ANDROID";
    ShopRulesFilterDeviceEnum["Ios"] = "IOS";
    ShopRulesFilterDeviceEnum["WebApp"] = "WEB_APP";
    ShopRulesFilterDeviceEnum["Windows"] = "WINDOWS";
})(ShopRulesFilterDeviceEnum || (ShopRulesFilterDeviceEnum = {}));
// Shop v1
export var ShopInAppRulesFilterDeviceEnum;
(function (ShopInAppRulesFilterDeviceEnum) {
    ShopInAppRulesFilterDeviceEnum["Android"] = "ANDROID";
    ShopInAppRulesFilterDeviceEnum["Ios"] = "IOS";
    ShopInAppRulesFilterDeviceEnum["WebApp"] = "WEB_APP";
    ShopInAppRulesFilterDeviceEnum["Windows"] = "WINDOWS";
})(ShopInAppRulesFilterDeviceEnum || (ShopInAppRulesFilterDeviceEnum = {}));
// Shop v2
export var ShopRulesFilterTypeNameEnum;
(function (ShopRulesFilterTypeNameEnum) {
    //  Logical filters
    ShopRulesFilterTypeNameEnum["And"] = "AND_FILTER";
    ShopRulesFilterTypeNameEnum["Or"] = "OR_FILTER";
    //  Business filters
    ShopRulesFilterTypeNameEnum["Age"] = "AGE_FILTER";
    ShopRulesFilterTypeNameEnum["Country"] = "COUNTRY_FILTER";
    ShopRulesFilterTypeNameEnum["DeviceType"] = "DEVICE_TYPE_FILTER";
    ShopRulesFilterTypeNameEnum["IsSubscriber"] = "IS_SUBSCRIBER_FILTER";
    ShopRulesFilterTypeNameEnum["RegisterDate"] = "REGISTER_DATE_FILTER";
    ShopRulesFilterTypeNameEnum["RegVintage"] = "REG_VINTAGE_FILTER";
    ShopRulesFilterTypeNameEnum["UserHash"] = "USER_HASH_FILTER";
    ShopRulesFilterTypeNameEnum["UserId"] = "USER_ID_FILTER";
    ShopRulesFilterTypeNameEnum["SubscriberStatus"] = "TODO: SUBSCRIBER_STATUS_FILTER";
    ShopRulesFilterTypeNameEnum["PtsScore"] = "PTS_SCORE_FILTER";
    ShopRulesFilterTypeNameEnum["PtrScore"] = "PTR_SCORE_FILTER";
    ShopRulesFilterTypeNameEnum["IsIntroEligible"] = "IS_INTRO_ELIGIBLE_FILTER";
})(ShopRulesFilterTypeNameEnum || (ShopRulesFilterTypeNameEnum = {}));
// Shop v1
export var ShopInAppRulesFilterTypeNameEnum;
(function (ShopInAppRulesFilterTypeNameEnum) {
    ShopInAppRulesFilterTypeNameEnum["Age"] = "AGE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["Country"] = "COUNTRY_FILTER";
    ShopInAppRulesFilterTypeNameEnum["DeviceType"] = "DEVICE_TYPE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["IsSubscriber"] = "IS_SUBSCRIBER_FILTER";
    ShopInAppRulesFilterTypeNameEnum["LegacyUserHash"] = "LEGACY_USER_HASH_FILTER";
    ShopInAppRulesFilterTypeNameEnum["Noop"] = "NOOP_FILTER";
    ShopInAppRulesFilterTypeNameEnum["PtsScore"] = "PTS_SCORE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["PtrScore"] = "PTR_SCORE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["RegisterDate"] = "REGISTER_DATE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["RegVintage"] = "REG_VINTAGE_FILTER";
    ShopInAppRulesFilterTypeNameEnum["UserHash"] = "USER_HASH_FILTER";
    ShopInAppRulesFilterTypeNameEnum["UserId"] = "USER_ID_FILTER";
    ShopInAppRulesFilterTypeNameEnum["IsIntroEligible"] = "IS_INTRO_ELIGIBLE_FILTER";
    //  Logical filters
    ShopInAppRulesFilterTypeNameEnum["Or"] = "OR_FILTER";
    ShopInAppRulesFilterTypeNameEnum["And"] = "AND_FILTER";
})(ShopInAppRulesFilterTypeNameEnum || (ShopInAppRulesFilterTypeNameEnum = {}));
export var RetargetingTypeEnum;
(function (RetargetingTypeEnum) {
    RetargetingTypeEnum["Milestone"] = "MILESTONE";
    RetargetingTypeEnum["Cyclic"] = "CYCLIC";
})(RetargetingTypeEnum || (RetargetingTypeEnum = {}));
export var UserAudioTopicEnum;
(function (UserAudioTopicEnum) {
    UserAudioTopicEnum["MealToCook"] = "MEAL_TO_COOK";
    UserAudioTopicEnum["FavMovie"] = "FAV_MOVIE";
    UserAudioTopicEnum["ReadSomething"] = "READ_SOMETHING";
    UserAudioTopicEnum["BestSpot"] = "BEST_SPOT";
    UserAudioTopicEnum["HeadySong"] = "HEADY_SONG";
    UserAudioTopicEnum["Sunday"] = "SUNDAY";
    UserAudioTopicEnum["FavSceneFriends"] = "FAV_SCENE_FRIENDS";
    UserAudioTopicEnum["Holidays"] = "HOLIDAYS";
    UserAudioTopicEnum["ReasonsToLikeMe"] = "REASONS_TO_LIKE_ME";
    UserAudioTopicEnum["WhereCrossingMe"] = "WHERE_CROSSING_ME";
    UserAudioTopicEnum["OpenMic"] = "OPEN_MIC";
})(UserAudioTopicEnum || (UserAudioTopicEnum = {}));
export var ReportStatusEnum;
(function (ReportStatusEnum) {
    ReportStatusEnum["Opened"] = "OPENED";
    ReportStatusEnum["Closed"] = "CLOSED";
})(ReportStatusEnum || (ReportStatusEnum = {}));
