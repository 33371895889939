import { gql } from '@apollo/client';
import { IdentityTypeName } from './types';
export const IDENTITIES_QUERY = gql `
  query IdentitiesQuery($phoneNumber: String, $mobileId: String) {
    identities(phoneNumber: $phoneNumber, mobileId: $mobileId)
      @rest(
        type: "${IdentityTypeName}"
        path: "api/identities?phone_number={args.phoneNumber}&mobile_id={args.mobileId}"
      ) {
        id
      }
  }
`;
