export var Gender;
(function (Gender) {
    Gender["Female"] = "FEMALE";
    Gender["Male"] = "MALE";
    Gender["NonBinary"] = "NON_BINARY";
    Gender["Unknown"] = "UNKNOWN";
})(Gender || (Gender = {}));
export var ImageFormatInput;
(function (ImageFormatInput) {
    ImageFormatInput["Crop80x80"] = "CROP_80x80";
    ImageFormatInput["Crop145x250"] = "CROP_145x250";
    ImageFormatInput["Crop160x160"] = "CROP_160x160";
    ImageFormatInput["Crop290x328"] = "CROP_290x328";
    ImageFormatInput["Crop290x500"] = "CROP_290x500";
    ImageFormatInput["Crop320x320"] = "CROP_320x320";
    ImageFormatInput["Crop320x550"] = "CROP_320x550";
    ImageFormatInput["Resize320x480"] = "RESIZE_320x480";
    ImageFormatInput["Resize640x960"] = "RESIZE_640x960";
    ImageFormatInput["Resize640x1136"] = "RESIZE_640x1136";
})(ImageFormatInput || (ImageFormatInput = {}));
export var OnlineStatus;
(function (OnlineStatus) {
    OnlineStatus["BetweenNowAnd15Min"] = "BETWEEN_NOW_AND_15MIN";
    OnlineStatus["Between15MinAnd1H"] = "BETWEEN_15_MIN_AND_1H";
    OnlineStatus["Between1HAnd2H"] = "BETWEEN_1H_AND_2H";
    OnlineStatus["Between2HAnd3H"] = "BETWEEN_2H_AND_3H";
    OnlineStatus["Between3HAndYesterday"] = "BETWEEN_3H_AND_YESTERDAY";
    OnlineStatus["BetweenYesterdayAnd3DaysAgo"] = "BETWEEN_YESTERDAY_AND_3DAYS_AGO";
    OnlineStatus["Before3DaysAgo"] = "BEFORE_3DAYS_AGO";
    OnlineStatus["Unknown"] = "UNKNOWN";
})(OnlineStatus || (OnlineStatus = {}));
export var ReactionContainerType;
(function (ReactionContainerType) {
    ReactionContainerType["Photo"] = "PHOTO";
    ReactionContainerType["Description"] = "DESCRIPTION";
    ReactionContainerType["Traits"] = "TRAITS";
    ReactionContainerType["DescriptionTraits"] = "DESCRIPTION_TRAITS";
    ReactionContainerType["Map"] = "MAP";
    ReactionContainerType["Charm"] = "CHARM";
})(ReactionContainerType || (ReactionContainerType = {}));
