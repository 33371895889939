import styled, { css } from 'styled-components';
import { ThemeColor, getThemeColor } from '@happn/theme';
import Text from '../Text';
const Label = styled(Text).attrs({ variant: 'bodyMedium' }) `
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
`;
const darkVariantStyle = css `
  background-color: ${({ isSelected }) => isSelected
    ? getThemeColor(ThemeColor.FillDark)
    : getThemeColor(ThemeColor.FillDarkMuted)};

  &:hover {
    background-color: ${getThemeColor(ThemeColor.FillDarkPressed)};

    ${Label}, svg {
      color: ${getThemeColor(ThemeColor.TextLightSubdued)};
    }
  }

  ${Label}, svg {
    color: ${({ isSelected }) => isSelected
    ? getThemeColor(ThemeColor.TextLight)
    : getThemeColor(ThemeColor.TextDark)};
  }
`;
const lightVariantStyle = css `
  background-color: ${({ isSelected }) => isSelected
    ? getThemeColor(ThemeColor.FillLight)
    : getThemeColor(ThemeColor.FillWhiteDisabled, 0.12)};

  ${Label}, svg {
    color: ${({ isSelected }) => isSelected
    ? getThemeColor(ThemeColor.TextDark)
    : getThemeColor(ThemeColor.TextLight)};
  }

  &:hover {
    background-color: ${getThemeColor(ThemeColor.FillLightPressed)};

    ${Label}, svg {
      color: ${getThemeColor(ThemeColor.TextDarkSubdued)};
    }
  }
`;
const Tab = styled.div `
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.375rem 0.75rem;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
  max-height: 2rem;

  ${({ variant }) => variant === 'dark' ? darkVariantStyle : lightVariantStyle}
  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
`;
const IconLeftWrapper = styled.div `
  margin-right: 0.5rem;
`;
const IconRightWrapper = styled.div `
  margin-left: 0.5rem;
`;
const BadgeWrapper = styled.div `
  margin-left: 0.5rem;
`;
export default { BadgeWrapper, IconLeftWrapper, IconRightWrapper, Label, Tab };
