var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { snakeCase } from '@happn/helpers';
import loggerService from '@happn/logger';
import { GqlTypes } from '@happn/types';
import { USER_UPDATE_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_BASE_FIELDS_QUERY, } from './gql';
const useBaseFields = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_BASE_FIELDS_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const baseFields = (data === null || data === void 0 ? void 0 : data.user) || {
        id: '',
        age: undefined,
        firstName: '',
        job: '',
        school: '',
        workplace: '',
        birthdate: '',
        lastName: '',
        about: '',
        gender: GqlTypes.Gender.Unknown,
        genderAlias: undefined,
        hideGenderAlias: true,
        residenceCity: undefined,
    };
    const updateBulk = useCallback((fields) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: Object.assign(Object.assign({}, snakecaseKeys(fields)), { birth_date: fields.birthdate }),
                },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    Object.entries(fields).forEach(([field, value]) => {
                        // If we update user birthdate then we have to also update the age field
                        if (field === 'birthdate' && value) {
                            cache.modify({
                                id: getUserIdentifier(userId),
                                fields: {
                                    age() {
                                        const ageDifMs = Date.now() - new Date(value).getTime();
                                        const ageDate = new Date(ageDifMs); // miliseconds from epoch
                                        return Math.abs(ageDate.getUTCFullYear() - 1970);
                                    },
                                },
                            });
                        }
                        cache.modify({
                            id: getUserIdentifier(userId),
                            fields: {
                                [field]() {
                                    return value;
                                },
                            },
                        });
                    });
                },
            });
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not update user base fields, ${err}`));
            return false;
        }
    }), [apolloClient, id, userId]);
    const update = useCallback((field) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: { id, input: { [snakeCase(field.key)]: field.value } },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            [field.key]() {
                                return field.value;
                            },
                        },
                    });
                },
            });
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not update user base fields, ${err}`));
            return false;
        }
    }), [apolloClient, id, userId]);
    return Object.assign(Object.assign({}, baseFields), { update,
        updateBulk });
};
export default useBaseFields;
