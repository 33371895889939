var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { RestLink } from 'apollo-link-rest';
import env from '@happn/env';
export const createRestLink = (apolloService) => {
    return new RestLink({
        endpoints: { bi: env.common.biUrl },
        uri: env.common.apiUrl,
        typePatcher: apolloService.typePatcher,
        responseTransformer: (response, typeName) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (!response) {
                    return;
                }
                const data = yield response.json();
                const mapper = apolloService.mappers[typeName];
                if (!mapper) {
                    return data;
                }
                return mapper(data, response);
            }
            catch (err) {
                // TODO: Add logger once we create that pkg
                // loggerService.error(err)
            }
        }),
        bodySerializers: {
            formSerializer: (body, headers) => {
                headers.set('Content-Type', 'application/x-www-form-urlencoded');
                return { body, headers };
            },
        },
        customFetch: (uri, options) => {
            return fetch(uri, options);
        },
    });
};
