import { css } from 'styled-components';
import LaNordRegular from './LaNord/LaNord-Regular.otf';
import LaNordMedium from './LaNord/LaNord-Medium.otf';
import LaNordBold from './LaNord/LaNord-Bold.otf';
import SpykBlack from './Spyk/Spyk-Black.otf';
import SpykBlackItalic from './Spyk/Spyk-Black-Italic.otf';
import { getThemeFont, ThemeFont } from '@happn/theme';
const fontsStyle = css `
  @font-face {
    font-family: 'LaNord Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${LaNordRegular})
      format('opentype');
  }

  @font-face {
    font-family: 'LaNord Medium';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${LaNordMedium})
      format('opentype');
  }

  @font-face {
    font-family: 'LaNord Bold';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${LaNordBold})
      format('opentype');
  }

  @font-face {
    font-family: 'Spyk Black';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${SpykBlack})
      format('opentype');
  }

  @font-face {
    font-family: 'Spyk Black Italic';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${SpykBlackItalic})
      format('opentype');
  }

  em {
    font-family: ${getThemeFont(ThemeFont.SpykBlack)};
  }

  i {
    font-family: ${getThemeFont(ThemeFont.SpykBlackItalic)};
  }
`;
export { fontsStyle };
