import { useQuery } from '@apollo/client';
import { SubscriptionLevelEnum } from '@happn/types/build/api';
import { USER_ID_ME } from '../../apollo';
import { USER_IS_SUB } from './gql';
export const useIsSub = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_IS_SUB, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    if (!(data === null || data === void 0 ? void 0 : data.user.subscriptionLevel)) {
        return false;
    }
    return (data === null || data === void 0 ? void 0 : data.user.subscriptionLevel) !== SubscriptionLevelEnum.Freemium;
};
export default useIsSub;
