import { Lottie, AnimationType } from '@happn/animations'

import Styled from './Small.styles'

const Small = ({ className }: { className?: string }) => (
  <Styled.Loader className={className}>
    <Lottie type={AnimationType.DarkLoading} />
  </Styled.Loader>
)

export default Small
