import { jsx as _jsx } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import env from '@happn/env';
import { DirectionType, } from './SlidingContent.types';
export const slidingContentTransition = {
    ease: 'easeInOut',
    duration: 0.3,
};
const DIRECTIONS = {
    [DirectionType.Left]: {
        transition: slidingContentTransition,
        initial: {
            x: '-100%',
            width: '100%',
        },
        animate: {
            x: '0%',
            width: '100%',
        },
        exit: env.common.isTesting
            ? undefined
            : {
                x: '-100%',
                position: 'absolute',
                width: '100%',
            },
    },
    [DirectionType.Right]: {
        transition: slidingContentTransition,
        animate: {
            x: '0%',
            position: 'relative',
            width: '100%',
        },
        initial: {
            x: '100%',
            position: 'absolute',
            width: '100%',
        },
        exit: {
            x: '100%',
            position: 'absolute',
            width: '100%',
        },
    },
};
const SlidingContent = forwardRef(({ direction, className, testId, children }, ref) => (_jsx(motion.div, Object.assign({ "data-testid": testId, ref: ref }, DIRECTIONS[direction], { className: className }, { children: children }), void 0)));
export default SlidingContent;
