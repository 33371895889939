import { gql } from '@apollo/client';
import { USER_PENDING_LIKERS_FRAGMENT } from '../../../apollo';
export const USER_PENDING_LIKERS_QUERY = gql `
  ${USER_PENDING_LIKERS_FRAGMENT}

  query UserPendingLikersQuery($id: String) {
    user(id: $id) {
      ...UserPendingLikers
    }
  }
`;
