var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import immutabilityUpdate from 'immutability-helper';
import { useCallback, useMemo } from 'react';
import loggerService from '@happn/logger';
import { USER_UPDATE_MUTATION, USER_ID_ME, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import { USER_MYSTERIOUS_MODE_PREFERENCES_QUERY, } from './gql';
const useMysteriousModePreferences = (id = USER_ID_ME) => {
    var _a;
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_MYSTERIOUS_MODE_PREFERENCES_QUERY, { fetchPolicy: 'cache-only', variables: { id } });
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const mysteriousPreferences = useMemo(() => {
        var _a;
        return ((_a = data === null || data === void 0 ? void 0 : data.user.preferences) === null || _a === void 0 ? void 0 : _a.mysteriousMode) || {
            hideGenderAlias: true,
        };
    }, [(_a = data === null || data === void 0 ? void 0 : data.user.preferences) === null || _a === void 0 ? void 0 : _a.mysteriousMode]);
    const update = useCallback((preferences) => __awaiter(void 0, void 0, void 0, function* () {
        const updatePreferences = Object.assign(Object.assign({}, mysteriousPreferences), preferences);
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        mysterious_mode_preferences: {
                            hide_gender_alias: updatePreferences.hideGenderAlias,
                        },
                    },
                },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            preferences(current) {
                                return immutabilityUpdate(current, {
                                    mysteriousMode: {
                                        $set: updatePreferences,
                                    },
                                });
                            },
                        },
                    });
                },
            });
            return data === null || data === void 0 ? void 0 : data.userUpdate.success;
        }
        catch (err) {
            loggerService.error(new Error(`Could not mysterious mode preferences, ${err}`));
            return false;
        }
    }), [apolloClient, id, mysteriousPreferences, userId]);
    return { mysteriousPreferences, update };
};
export default useMysteriousModePreferences;
