export const getPendingLikers = (userGto) => {
    var _a, _b;
    if (!((_a = userGto.pending_likers) === null || _a === void 0 ? void 0 : _a.counter_label) ||
        !((_b = userGto.pending_likers.icon) === null || _b === void 0 ? void 0 : _b.url)) {
        return null;
    }
    return {
        label: userGto.pending_likers.counter_label,
        iconUrl: userGto.pending_likers.icon.url,
    };
};
