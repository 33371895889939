var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery, useLazyQuery } from '@apollo/client';
import immutableUpdate from 'immutability-helper';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import loggerService from '@happn/logger';
import { ApiTypes } from '@happn/types';
import { USER_UPDATE_MUTATION, NotificationsPreferencesType, USER_ID_ME, USER_RECOVERY_INFO_QUERY, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
import useEmail from '../useEmail';
import { USER_NOTIFICATIONS_PREFERENCES_QUERY, } from './gql';
import { mapDifference, mapNotificationsToBody, updateValue } from './helpers';
const initialPreferences = {
    charms: NotificationsPreferencesType.None,
    crush: NotificationsPreferencesType.None,
    crushTime: NotificationsPreferencesType.None,
    dailyRecap: NotificationsPreferencesType.None,
    news: NotificationsPreferencesType.None,
    others: NotificationsPreferencesType.None,
    weeklyRecap: NotificationsPreferencesType.None,
    // Push only
    dates: NotificationsPreferencesType.None,
    likes: NotificationsPreferencesType.None,
    messages: NotificationsPreferencesType.None,
    near: NotificationsPreferencesType.None,
    reminders: NotificationsPreferencesType.None,
    visit: NotificationsPreferencesType.None,
};
const useNotificationsPreferences = (id = USER_ID_ME) => {
    var _a, _b;
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const { data: notificationsData } = useQuery(USER_NOTIFICATIONS_PREFERENCES_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    const [getRecoveryInfo, { data }] = useLazyQuery(USER_RECOVERY_INFO_QUERY, {
        fetchPolicy: 'network-only',
        variables: { id },
    });
    const recoveryMail = (_a = data === null || data === void 0 ? void 0 : data.userRecoveryInfo.recoveryInfo.find((ri) => ri.type === ApiTypes.UserRecoveryInfoType.EMAIL)) === null || _a === void 0 ? void 0 : _a.value;
    const email = useEmail() || recoveryMail || '';
    const userId = notificationsData === null || notificationsData === void 0 ? void 0 : notificationsData.user.id;
    const notificationsPreferences = useMemo(() => { var _a; return ((_a = notificationsData === null || notificationsData === void 0 ? void 0 : notificationsData.user.preferences) === null || _a === void 0 ? void 0 : _a.notifications) || initialPreferences; }, [(_b = notificationsData === null || notificationsData === void 0 ? void 0 : notificationsData.user.preferences) === null || _b === void 0 ? void 0 : _b.notifications]);
    const previousState = useRef(notificationsPreferences);
    const [values, setValues] = useState(notificationsPreferences);
    const getValueDifference = useCallback(() => mapDifference(values, previousState.current), [values]);
    const setValue = useCallback((name, value) => {
        if (name) {
            setValues((prev) => (Object.assign(Object.assign({}, prev), { [name]: updateValue(prev[name], value) })));
        }
    }, []);
    const turnOffAll = useCallback(() => {
        setValues((prevVal) => {
            // Dont turn off the push only notifications
            const names = [
                'charms',
                'crush',
                'crushTime',
                'dailyRecap',
                'news',
                'others',
                'weeklyRecap',
            ];
            return names.reduce((prev, val) => {
                const previous = prevVal[val];
                return Object.assign(Object.assign({}, prev), { [val]: updateValue(previous, false) });
            }, prevVal);
        });
    }, []);
    const update = useCallback((newNotifications) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const updatedNotifications = Object.assign(Object.assign({}, notificationsPreferences), newNotifications);
        try {
            yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id,
                    input: {
                        // To backend send only changed fields, for now don't send full WebApp state
                        notification_settings: mapNotificationsToBody(newNotifications),
                    },
                },
                update(cache, { data }) {
                    if (!(data === null || data === void 0 ? void 0 : data.userUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserIdentifier(userId),
                        fields: {
                            preferences(current) {
                                return immutableUpdate(current, {
                                    notifications: {
                                        $set: updatedNotifications,
                                    },
                                });
                            },
                        },
                    });
                },
            });
            setIsLoading(false);
            return true;
        }
        catch (err) {
            loggerService.error(new Error(`Could not update notification settings, ${err}`));
            setIsLoading(false);
            return false;
        }
    }), [apolloClient, id, notificationsPreferences, userId]);
    const saveSettings = useCallback(() => {
        const onlyChangedValues = getValueDifference();
        previousState.current = values;
        setHasChanged(false);
        setIsLoading(true);
        return update(onlyChangedValues);
    }, [getValueDifference, update, values]);
    useEffect(() => {
        if (!email) {
            getRecoveryInfo();
        }
    }, [email, getRecoveryInfo]);
    useEffect(() => {
        const isChanged = JSON.stringify(values) !== JSON.stringify(previousState.current);
        setHasChanged(isChanged);
    }, [values]);
    useEffect(() => {
        const hasUpdated = JSON.stringify(values) !== JSON.stringify(notificationsPreferences);
        if (!hasUpdated) {
            return;
        }
        setValues(notificationsPreferences);
        previousState.current = notificationsPreferences;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsPreferences]);
    return {
        values,
        hasChanged,
        email,
        isLoading,
        update,
        turnOffAll,
        setValue,
        saveSettings,
    };
};
export default useNotificationsPreferences;
