const getUuidTime = (uuidv1) => {
    const sp = uuidv1.split('-');
    const time = [sp[2].substring(1), sp[1], sp[0]].join('');
    return parseInt(time, 16);
};
const uuidV1ToDate = (uuidv1) => {
    const time = getUuidTime(uuidv1) - 122192928000000000;
    const ms = Math.floor(time / 10000);
    return new Date(ms);
};
export default uuidV1ToDate;
