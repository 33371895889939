import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
import { SHOP_PRODUCT_BASE_FRAGMENT, SHOP_PRODUCT_GROUP_BASE_FRAGMENT, SHOP_RULE_BASE_FRAGMENT, } from './fragments';
import { ShopProductGroupTypeName, ShopProductTypeName, ShopRuleTypeName, ShopCheckoutSessionTypeName, } from './types';
export const SHOP_PRODUCT_GROUP_REMOVE_MUTATION = gql `
  mutation ShopProductGroupRemoveMutation($id: ID!) {
    shopProductGroupRemove(id: $id)
      @rest(
        type: "${StatusResponseTypeName}"
        method: "DELETE"
        path: "api/shop-groups/{args.id}"
      ) {
        success
      }
  }
`;
export const SHOP_PRODUCT_GROUP_ADD_MUTATION = gql `
    ${SHOP_PRODUCT_GROUP_BASE_FRAGMENT}
    
    mutation ShopProductGroupAddMutation($input: Any!) {
        shopProductGroupAdd(input: $input)
        @rest(
            type: "${ShopProductGroupTypeName}"
            method: "POST"
            path: "api/shop-groups"
        ) {
            ...ShopProductGroupBase
        }
    }
`;
export const SHOP_PRODUCT_GROUP_UPDATE_MUTATION = gql `
    ${SHOP_PRODUCT_GROUP_BASE_FRAGMENT}
    
    mutation ShopProductGroupUpdateMutation($id: ID!, $input: Any!) {
        shopProductGroupUpdate(id: $id, input: $input)
        @rest(
            type: "${ShopProductGroupTypeName}"
            method: "PUT"
            path: "api/shop-groups/{args.id}"
        ) {
            ...ShopProductGroupBase
            modificationDate
        }
    }
`;
export const SHOP_PRODUCT_ADD_MUTATION = gql `
  ${SHOP_PRODUCT_BASE_FRAGMENT}

  mutation ShopProductAddMutation($input: any) {
    shopProductAdd(input: $input) @rest(
      type: "${ShopProductTypeName}"
      method: "POST"
      path: "api/v2/shop-products",
    ) {
      ...ShopProductBase
    }
  }
`;
export const SHOP_PRODUCT_UPDATE_MUTATION = gql `
  ${SHOP_PRODUCT_BASE_FRAGMENT}

  mutation ShopProductUpdateMutation($id: String, $input: any) {
    shopProductUpdate(id: $id, input: $input) @rest(
      type: "${ShopProductTypeName}"
      method: "PUT"
      path: "api/v2/shop-products/{args.id}",
    ) {
      ...ShopProductBase
    }
  }
`;
export const SHOP_RULE_ADD_MUTATION = gql `
    ${SHOP_RULE_BASE_FRAGMENT}
    
    mutation ShopRuleAddMutation($input: Any!) {
        shopRuleAdd(input: $input)
        @rest(
            type: "${ShopRuleTypeName}"
            method: "POST"
            path: "api/v2/shop/rules"
        ) {
            ...ShopRuleBase
        }
    }
`;
export const SHOP_RULE_UPDATE_MUTATION = gql `
    ${SHOP_RULE_BASE_FRAGMENT}
    
    mutation ShopRuleUpdateMutation($id: String, $input: Any!) {
        shopRuleUpdate(id: $id, input: $input)
        @rest(
            type: "${ShopRuleTypeName}"
            method: "PUT"
            path: "api/v2/shop/rules/{args.id}"
        ) {
            ...ShopRuleBase
        }
    }
`;
export const SHOP_CHECKOUT_SESSION_MUTATION = gql `
  mutation ShopCheckoutSessionMutation($input: Any) {
    shopCheckoutSession(input: $input) @rest(
      type: "${ShopCheckoutSessionTypeName}"
      method: "POST"
      path: "api/v1/checkout"
    ) {
      url
    }
  }
`;
export const SHOP_RULE_DELETE_MUTATION = gql `
  mutation ShopRuleDeleteMutation($id: String) {
    shopRuleDelete(id: $id) @rest(
      type: "${StatusResponseTypeName}"
      path: "api/v2/shop/rules/{args.id}"
      method: "DELETE"
    ) {
      success
    }
  }
`;
