var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import qs from 'qs';
import { useCallback } from 'react';
import { USER_ARCHIVES_QUERY, USER_ARCHIVE_CREATE_MUTATION, USER_ARCHIVE_DOWNLOAD_LINK_QUERY, USER_ID_ME, } from '../../apollo';
import { getUserArchiveIdentifier } from '../../helpers';
const useArchives = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const fetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.query({
            query: USER_ARCHIVES_QUERY,
            variables: {
                id,
            },
        });
        return (data === null || data === void 0 ? void 0 : data.userArchives) || [];
    }), [apolloClient, id]);
    const getDownloadLink = useCallback((processId) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.query({
            query: USER_ARCHIVE_DOWNLOAD_LINK_QUERY,
            variables: { userId: id, processId },
            fetchPolicy: 'no-cache',
        });
        return data === null || data === void 0 ? void 0 : data.userArchiveDownloadLink.url;
    }), [apolloClient, id]);
    const create = useCallback((input) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = yield apolloClient.mutate({
            mutation: USER_ARCHIVE_CREATE_MUTATION,
            variables: {
                id,
                input,
                transform: (body, headers) => {
                    headers.set('Content-Type', 'application/x-www-form-urlencoded');
                    return {
                        body: qs.stringify(body),
                        headers,
                    };
                },
            },
            update(cache, result) {
                var _a;
                const id = (_a = result.data) === null || _a === void 0 ? void 0 : _a.userArchiveCreate.id;
                if (!id) {
                    return;
                }
                cache.modify({
                    fields: {
                        userArchives(existing) {
                            return [{ __ref: getUserArchiveIdentifier(id) }, ...existing];
                        },
                    },
                });
            },
        });
        return !!(data === null || data === void 0 ? void 0 : data.userArchiveCreate.id);
    }), [apolloClient, id]);
    return { fetch, getDownloadLink, create };
};
export default useArchives;
