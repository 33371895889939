import { ApolloClient, InMemoryCache, } from '@apollo/client/core';
import env from '@happn/env';
import { createLink } from './link';
import { toStatusResponseModel } from './mappers';
import { StatusResponseTypeName, } from './types';
export class ApolloService {
    constructor() {
        this._mappers = {
            [StatusResponseTypeName]: toStatusResponseModel,
        };
        this._typePatcher = {};
        this._rootFieldPolicies = {};
        this._typePolicies = {};
    }
    get mappers() {
        return this._mappers;
    }
    get wsConnectionParams() {
        return this._wsConnectionParams;
    }
    get typePatcher() {
        return this._typePatcher;
    }
    get headersExtender() {
        return this._headersExtender;
    }
    get client() {
        if (!this._client) {
            throw new Error('Client not initialized');
        }
        return this._client;
    }
    static getInstance() {
        if (!ApolloService._instance) {
            ApolloService._instance = new ApolloService();
        }
        return ApolloService._instance;
    }
    registerMapper(type, mapper) {
        this._mappers[type] = mapper;
    }
    registerTypePatcher(patcher) {
        this._typePatcher = Object.assign(Object.assign({}, this.typePatcher), patcher);
    }
    registerWsConnectionParams(wsConnectionParams) {
        this._wsConnectionParams = wsConnectionParams;
    }
    registerRootFieldPolicy(fieldName, policy) {
        this._rootFieldPolicies = Object.assign(Object.assign({}, this._rootFieldPolicies), { [fieldName]: policy });
    }
    registerTypePolicy(fieldName, typePolicy) {
        this._typePolicies = Object.assign(Object.assign({}, this._typePolicies), { [fieldName]: typePolicy });
    }
    registerHeadersExtender(headersExtender) {
        this._headersExtender = headersExtender;
    }
    initialize() {
        const { link, subscriptionClient } = createLink(this);
        this._client = new ApolloClient({
            defaultOptions: {
                mutate: {
                    keepRootFields: true,
                },
            },
            cache: new InMemoryCache({
                typePolicies: Object.assign(Object.assign({}, this._typePolicies), { Query: {
                        fields: Object.assign({}, this._rootFieldPolicies),
                    } }),
            }),
            link,
            connectToDevTools: !env.common.isProduction,
        });
        return { client: this._client, subscriptionClient };
    }
}
const apolloService = ApolloService.getInstance();
export default apolloService;
