import { ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { omitKeys } from '@happn/helpers';
export const cleanupLink = new ApolloLink((op, forward) => {
    const keys = ['__typename', '__key'];
    const definition = getMainDefinition(op.query);
    if (definition &&
        definition.operation === 'mutation') {
        op.variables = omitKeys(op.variables, ...keys);
    }
    return forward ? forward(op) : null;
});
