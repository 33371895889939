var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import immutabilityUpdate from 'immutability-helper';
import { useCallback } from 'react';
import { USER_BALANCE_QUERY, USER_ID_ME, USER_BALANCE_UPDATE_MUTATION, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
const useBalance = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_BALANCE_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    const balance = (data === null || data === void 0 ? void 0 : data.user.balance) || [];
    const isSub = (data === null || data === void 0 ? void 0 : data.user.isSub) || false;
    const userId = data === null || data === void 0 ? void 0 : data.user.id;
    const update = useCallback((value, type) => {
        apolloClient.cache.modify({
            id: getUserIdentifier(userId),
            fields: {
                balance(balance) {
                    const creditIndex = balance.findIndex((b) => b.type === type);
                    if (creditIndex < 0) {
                        return balance;
                    }
                    const newAmount = balance[creditIndex].total + value;
                    return immutabilityUpdate(balance, {
                        [creditIndex]: {
                            $set: Object.assign(Object.assign({}, balance[creditIndex]), { total: newAmount, permanent: newAmount }),
                        },
                    });
                },
            },
        });
    }, [apolloClient.cache, userId]);
    const post = useCallback((amount, type) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const result = yield apolloClient.mutate({
                mutation: USER_BALANCE_UPDATE_MUTATION,
                variables: {
                    input: {
                        amount,
                        credit_type: type,
                        receiver_id: id,
                    },
                },
            });
            if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.userBalanceUpdate.success) {
                update(amount, type);
                return true;
            }
        }
        catch (_b) {
            // fallthrough
        }
        return false;
    }), [apolloClient, id, update]);
    const refetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield apolloClient.query({
                query: USER_BALANCE_QUERY,
                fetchPolicy: 'network-only',
                variables: { id },
            });
            return true;
        }
        catch (_c) {
            return false;
        }
    }), [apolloClient, id]);
    return { balance, isSub, update, refetch, post };
};
export default useBalance;
