import { ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createAuthorizationLink } from './authorization';
import { cleanupLink } from './cleanup';
import { errorLink } from './error';
import { httpLink } from './http';
import { createRestLink } from './rest';
import { createWsLink } from './ws';
export const createLink = (apolloService) => {
    const { wsLink, subscriptionClient } = createWsLink(apolloService);
    const splitLink = split(({ query }) => {
        const definition = getMainDefinition(query);
        return (definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription');
    }, wsLink, httpLink);
    return {
        link: ApolloLink.from([
            errorLink,
            cleanupLink,
            createAuthorizationLink(apolloService),
            createRestLink(apolloService),
            splitLink,
        ]),
        subscriptionClient,
    };
};
