const inputMaxLength = parseInt(process.env.REACT_APP_INPUT_MAX_LENGTH || '');
export const common = {
    isTesting: process.env.NODE_ENV === 'test',
    isProduction: process.env.REACT_APP_STAGE === 'prod',
    isBeta: process.env.REACT_APP_STAGE === 'beta',
    isPreprod: process.env.REACT_APP_STAGE === 'preprod',
    stage: process.env.REACT_APP_STAGE || 'preprod',
    version: process.env.REACT_APP_VERSION || '',
    apiUrl: process.env.REACT_APP_API_URL || '',
    biUrl: process.env.REACT_APP_BI_URL || '',
    gqlApi: `https://${process.env.REACT_APP_GQL_API}`,
    gqlApiSubscriptions: `wss://${process.env.REACT_APP_GQL_API}/subscriptions`,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
    sentryRelease: process.env.REACT_APP_SENTRY_RELEASE || '',
    deviceType: process.env.REACT_APP_DEVICE_TYPE || 'web_app',
    appType: process.env.REACT_APP_TYPE || '',
    isWebApp: process.env.REACT_APP_TYPE === 'webapp',
    isConsole: process.env.REACT_APP_TYPE === 'console',
    inputMaxLength: !Number.isNaN(inputMaxLength) ? inputMaxLength : 200,
};
