import { gql } from '@apollo/client';
export const USER_IS_PREMIUM = gql `
  query UserisSubQuery($id: String) {
    user(id: $id) {
      id
      isSub
    }
  }
`;
export const USER_IS_SUB = gql `
  query UserisSubQuery($id: String) {
    user(id: $id) {
      id
      subscriptionLevel
    }
  }
`;
