import { ThemeBreakpoint } from '../types';
const minSmall = (props) => `@media (min-width: ${props.theme.breakpoints[ThemeBreakpoint.Small]}px)`;
const minMedium = (props) => `@media (min-width: ${props.theme.breakpoints[ThemeBreakpoint.Medium]}px)`;
const minLarge = (props) => `@media (min-width: ${props.theme.breakpoints[ThemeBreakpoint.Large]}px)`;
const maxSmall = (props) => `@media (max-width: ${props.theme.breakpoints[ThemeBreakpoint.Small]}px)`;
const maxMedium = (props) => `@media (max-width: ${props.theme.breakpoints[ThemeBreakpoint.Medium]}px)`;
const maxLarge = (props) => `@media (max-width: ${props.theme.breakpoints[ThemeBreakpoint.Large]}px)`;
export const media = {
    minSmall,
    minMedium,
    minLarge,
    maxSmall,
    maxMedium,
    maxLarge,
};
