import { getPagination } from '@happn/apollo';
export const getPaymentIntent = (dto) => ({
    id: dto.id,
    status: dto.status,
    creationDate: dto.creation_date,
    amount: dto.amount,
    currency: dto.currency,
});
export const toPaymentIntents = (res) => {
    if (!res) {
        return null;
    }
    return {
        items: res.data.map(getPaymentIntent),
        pagination: getPagination(res.pagination),
    };
};
