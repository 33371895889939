import LanguageDetector from 'i18next-browser-languagedetector'

import { languageKeys } from './i18n.languages'

export const languageDetector = new LanguageDetector(null, {
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'sessionStorage',
    'custom',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
})

// Exported for test only
export const customLookup = () => {
  const code = navigator.language

  if (!code) {
    return
  }

  let stdCode = code

  if (stdCode.includes('-')) {
    const [language, locale] = stdCode.split('-')

    if (language && locale) {
      stdCode = language.toLocaleLowerCase() + '-' + locale.toUpperCase()
    }
  }

  if (languageKeys.includes(stdCode)) {
    return stdCode
  }

  const [onlyLang] = stdCode.split('-')

  if (languageKeys.includes(onlyLang)) {
    return onlyLang
  }
}

languageDetector.addDetector({
  name: 'custom',
  lookup: customLookup,
})
