const ENV_LOCAL_STORAGE_KEY = 'env-config';
export const getLocalStorageConfig = () => {
    try {
        return JSON.parse(localStorage.getItem(ENV_LOCAL_STORAGE_KEY) || '{}');
    }
    catch (_a) {
        return {};
    }
};
export const setLocalStorageConfig = (config) => {
    localStorage.setItem(ENV_LOCAL_STORAGE_KEY, JSON.stringify(config));
};
