import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
export const USER_STRIPE_SUBSCRIPTION_CANCEL_MUTATION = gql `
  mutation UserStripeSubscriptionCancelMutation($id: ID, $subscriptionId: String, $input: Any) {
    userStripeSubscriptionCancel(id: $id, subscriptionId: $subscriptionId, input: $input) @rest(
      path: "api/v1/users/{args.id}/customer/subscriptions/{args.subscriptionId}/cancel",
      type: "${StatusResponseTypeName}",
      method: "PUT"
    ) {
      success
    }
  }
`;
