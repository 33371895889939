const gradients = {
    happn: 'linear-gradient(135deg, #0087B4 0%, #00BED7 100%);',
    boost: 'linear-gradient(135deg, #3C5A99 0%, #0087B4 100%);',
    flashNote: 'linear-gradient(135deg, #FFA30D 0%, #FFC207 100%);',
    gold: 'linear-gradient(99.05deg, #C49210 -27.9%, #FAD27A 0.86%, #F8BB17 13.84%, #DB9F00 49.83%, #FAD27A 67.59%, #F8BB17 84.22%, #DB9F00 107.91%);',
    crushTime: 'linear-gradient(130.42deg, #AEDEEB 12.59%, #DBB9E2 76.65%);',
    like: 'linear-gradient(135deg, #FF3B30 0%, #FF0081 100%);',
    gray: 'linear-gradient(135deg, #87878C 0%, #A5A5AA 100%);',
    turquoise: 'linear-gradient(135deg, #2CC7AF 0%, #00BED7 100%);',
    pauseLocation: 'linear-gradient(135deg, #3C5A99 0%, #0087B4 100%);',
    happnHover: 'linear-gradient(135deg, #009BCF 0%, #00D4F0 100%);',
    storm: 'linear-gradient(168.08deg, #A150B6 -17.84%, #6764D8 47.03%, #47AAF1 110.87%);',
    shopDesktop: 'linear-gradient(180deg, #EBF7FB 0%, rgba(235, 247, 251, 0) 100%);',
};
const breakpoints = {
    small: 600,
    medium: 900,
    large: 1200,
};
const sizes = {
    desktopCardHeight: 656,
    desktopHeaderHeight: 72,
    mobileNavigationHeight: 56,
    crushTimeCardHeight: 390,
    crushTimeCardWidth: 260,
};
const fonts = {
    primary: 'Roboto, sans-serif',
    libreBaskerville: `'Libre Baskerville', serif`,
    helveticaNeue: `'Helvetica Neue', sans-serif`,
    sofiaPro: `'Sofia Pro', sans-serif`,
    laNordRegular: `'LaNord Regular', sans-serif`,
    laNordMedium: `'LaNord Medium', sans-serif`,
    laNordBold: `'LaNord Bold', sans-serif`,
    spykBlack: `'Spyk Black', sans-serif`,
    spykBlackItalic: `'Spyk Black Italic', sans-serif`,
};
const colors = {
    primary: '#000000',
    secondary: '#4B4B4B',
    tertiary: '#7E7E7E',
    quaternary: '#BCBCBC',
    quinary: '#E6E6E6',
    senary: '#F2F2F2',
    white: '#ffffff',
    happn: '#009BCF',
    happnDark: '#014B64',
    happnDarkHover: '#013F55',
    happnHover: '#0084AF',
    like: '#D6395C',
    likeHover: '#D83B4C',
    alert: '#FF3B30',
    alertHover: '#D83228',
    success: '#05D5A1',
    successHover: '#05B488',
    flashNote: '#FFC207',
    gold: '#DB9F00',
    warning: '#FFA30D',
    warningHover: '#D88A0B',
    overlay: 'rgba(0,0,0,0.8)',
    spotify: '#1ED760',
    primaryDark: '#100f0a',
    brandLight: '#fbf8f1',
    brandLavander: '#dcb7e3',
    brandSky: '#aad3f4',
    dark: '#100f0a',
    darkMuted: 'rgba(16, 15, 10, 0.08)',
    darkSubdued: '#707376',
    iconDarkSubdued: '#767470',
    darkDisabled: 'rgba(16, 15, 10, 0.04)',
    fillSuccess: '#64c75b',
    error: '#ef6685',
    textPrimary: '#ffb7a1',
    secondaryDark: 'rgba(16, 15, 10, 0.12)',
    shopPremium: '#f6cc5b',
    brandMallow: '#aeb3ec',
    lightDisabled: 'rgba(251, 248, 241, 0.6)',
    brandButter: '#f6cc5b',
    brandDark: '#100f0a',
    fillPrimary: '#ffb7a1',
    fillLight: '#fbf8f1',
    fillLightSubdued: '#f1ede5',
    textDark: '#100f0a',
    textDarkSubdued: '#767470',
    textDarkDisabled: 'rgba(16, 15, 10, 0.4)',
    borderDark: '#100f0a',
    borderDarkMuted: '#f1ede5',
    borderError: 'rgba(214, 57, 92, 1)',
    borderLight: '#fbf8f1',
    fillLanding: '#201d14',
    brandMustard: '#bd9501',
    fillBrandLavender: '#dcb7e3',
    fillBrandSky: '#aad3f4',
    fillBrandMallow: '#aeb3ec',
    fillBrandButter: '#f6cc5b',
    fillDarkMuted: 'rgba(16, 15, 10, 0.08)',
    borderLightSubdued: '#f1ede5',
    borderDarkDisabled: 'rgba(16, 15, 10, 0.04)',
    iconSuccess: '#64c75b',
    light: '#fbf8f1',
    iconLight: '#fbf8f1',
    iconDark: '#100f0a',
    fillDark: '#100f0a',
    fillDarkSubdued: '#767470',
    fillWhite: '#ffffff',
    textLight: '#fbf8f1',
    fillWhiteDisabled: '#ffffff1f',
    fillDarkPressed: '#767470',
    fillLightPressed: '#fbf8f1',
    textLightSubdued: '#f1ede5',
};
const theme = {
    breakpoints,
    sizes,
    gradients,
    fonts,
    colors,
};
export default theme;
