import styled, { css } from 'styled-components';
import { Lottie } from '@happn/animations';
import { getThemeColor, getThemeFont, ThemeColor, ThemeFont, } from '@happn/theme';
import { ButtonSize } from './Button.types';
const Icon = styled.div `
  display: flex;
`;
const StartIcon = styled(Icon) `
  margin-right: 0.5rem;
`;
const EndIcon = styled(Icon) `
  margin-left: 0.5rem;
`;
const Base = styled.button `
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 0.75rem;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;
  font-family: ${getThemeFont(ThemeFont.LaNordRegular)};
  border: none;
  outline-color: ${getThemeColor(ThemeColor.BrandMallow)};

  &:disabled {
    cursor: not-allowed;
    background: ${getThemeColor(ThemeColor.White)};
    color: ${getThemeColor(ThemeColor.Quaternary)};
  }
`;
const Large = styled(Base) `
  background: ${getThemeColor(ThemeColor.Dark)};
  color: ${getThemeColor(ThemeColor.White)};
  padding: 1.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.41px;
`;
const LargePrimary = styled(Large) `
  &:disabled {
    background: ${getThemeColor(ThemeColor.Primary)};
    color: ${getThemeColor(ThemeColor.Tertiary)};
  }

  &:hover:not(:disabled) {
    background: ${getThemeColor(ThemeColor.Secondary)};
  }
`;
const LargeSecondary = styled(Large) `
  background: ${getThemeColor(ThemeColor.White)};
  color: ${getThemeColor(ThemeColor.Primary)};

  &:disabled {
    background: ${getThemeColor(ThemeColor.White)};
    color: ${getThemeColor(ThemeColor.Quaternary)};
  }
`;
const Small = styled(Base) `
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.08px;
  padding: 0.375rem 1rem;

  ${Icon} {
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
const SmallPrimary = styled(Small) `
  background: ${getThemeColor(ThemeColor.Dark)};
  color: ${getThemeColor(ThemeColor.White)};

  &:hover:not(:disabled) {
    background: ${getThemeColor(ThemeColor.Secondary)};
  }
`;
const XSmallSecondary = styled(Small) `
  background: ${getThemeColor(ThemeColor.White)};
  color: ${getThemeColor(ThemeColor.Primary)};
  padding: 0.5rem;
`;
const SmallSecondary = styled(Small) `
  background: ${getThemeColor(ThemeColor.White)};
  color: ${getThemeColor(ThemeColor.Primary)};
`;
const SmallTertiary = styled(Small) `
  background: ${getThemeColor(ThemeColor.DarkMuted)};
  color: ${getThemeColor(ThemeColor.Primary)};
  border: 1px solid transparent;

  &:disabled {
    background: transparent;
    color: ${getThemeColor(ThemeColor.Quaternary)};
  }
`;
const MediumPrimary = styled(Base) `
  background: ${getThemeColor(ThemeColor.Dark)};
  color: ${getThemeColor(ThemeColor.White)};
  padding: 0.875rem 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: -0.41px;
  border-radius: 1rem;

  &:hover:not(:disabled) {
    background: ${getThemeColor(ThemeColor.Secondary)};
  }

  ${Icon} {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
const LargeTertiary = styled(Large) `
  background: ${getThemeColor(ThemeColor.DarkMuted)};
  color: ${getThemeColor(ThemeColor.Primary)};

  &:disabled {
    background: ${getThemeColor(ThemeColor.White)};
    color: ${getThemeColor(ThemeColor.Quaternary)};
  }
`;
const SmallRoundedPrimary = styled(Small) `
  background: ${getThemeColor(ThemeColor.Dark)};
  color: ${getThemeColor(ThemeColor.White)};
  border-radius: 50%;
  padding: 0.5rem;
  width: 2rem;
  height: 2rem;

  &:hover {
    background: ${getThemeColor(ThemeColor.Secondary)};
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
const SmallRoundedSecondary = styled(SmallRoundedPrimary) `
  background: ${getThemeColor(ThemeColor.DarkMuted)};
  color: ${getThemeColor(ThemeColor.Primary)};

  &:hover {
    background: ${getThemeColor(ThemeColor.SecondaryDark)};
  }
`;
const Transparent = styled(Base) `
  font-size: 0.875rem;
  text-align: center;
  background-color: transparent;
  font-family: ${getThemeFont(ThemeFont.LaNordMedium)};
  margin: auto;
  padding: 0;
  transition: color 0.3s ease-in-out;
  border-radius: 0;

  &:hover {
    color: ${getThemeColor(ThemeColor.DarkSubdued)};
  }
`;
const Link = styled.button `
  font-family: ${getThemeFont(ThemeFont.LaNordMedium)};
  border: none;
  outline-color: ${getThemeColor(ThemeColor.BrandMallow)};
  color: ${getThemeColor(ThemeColor.Dark)};
  background-color: transparent;
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${getThemeColor(ThemeColor.DarkSubdued)};
  }
`;
const Loader = styled(Lottie) `
  ${({ size }) => {
    switch (size) {
        case ButtonSize.XSmall:
            return css `
          width: 1rem;
          height: 1rem;
        `;
        case ButtonSize.Small:
            return css `
          width: 1.25rem;
          height: 1.25rem;
        `;
        case ButtonSize.Medium:
        case ButtonSize.Large:
            return css `
          width: 1.5rem;
          height: 1.5rem;
        `;
    }
}}
`;
export default {
    LargePrimary,
    LargeSecondary,
    LargeTertiary,
    SmallPrimary,
    SmallSecondary,
    SmallTertiary,
    SmallRoundedPrimary,
    SmallRoundedSecondary,
    MediumPrimary,
    Transparent,
    StartIcon,
    EndIcon,
    Link,
    XSmallSecondary,
    Loader,
};
