import { nonUndefined } from '@happn/helpers';
export const getGenderAliases = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.id) || !(gto === null || gto === void 0 ? void 0 : gto.gender) || !(gto === null || gto === void 0 ? void 0 : gto.label_localized.length) || !(gto === null || gto === void 0 ? void 0 : gto.default_label)) {
        return undefined;
    }
    return {
        id: gto.id,
        gender: gto.gender,
        label: gto.label_localized[0].value,
        defaultLabel: gto.default_label,
    };
};
export const toGenderAliasesModel = (res) => {
    if (!(res === null || res === void 0 ? void 0 : res.data)) {
        return [];
    }
    return res.data.map(getGenderAliases).filter(nonUndefined);
};
