export const getUsersExport = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.cid) ||
        !gto.export_date ||
        !gto.exporter_user_id ||
        !gto.signed_url) {
        return undefined;
    }
    return {
        id: gto.cid,
        exportDate: gto.export_date,
        exporterUserId: gto.exporter_user_id,
        fields: gto.fields || [],
        fileName: gto.file_name || '',
        filter: gto.filter || '{}',
        progressPercentage: gto.progress_percentage || 0,
        signedUrl: gto.signed_url,
    };
};
