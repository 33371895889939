var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { nonUndefined } from '@happn/helpers';
import { USER_BAN_MUTATION, USER_ABOUT_FRAGMENT, USER_UPDATE_MUTATION, UserBaseStatus, } from '../../apollo';
import { getUserIdentifier } from '../../helpers';
const useBan = () => {
    const client = useApolloClient();
    return useCallback((id, banTag) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const banResult = yield client.mutate({
            mutation: USER_BAN_MUTATION,
            variables: {
                id,
            },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.banUser.success)) {
                    return;
                }
                cache.modify({
                    id: getUserIdentifier(id),
                    fields: {
                        status() {
                            return UserBaseStatus.Banned;
                        },
                    },
                });
            },
        });
        const banSucceed = (_a = banResult.data) === null || _a === void 0 ? void 0 : _a.banUser.success;
        if (!banSucceed) {
            return false;
        }
        const user = client.cache.readFragment({
            id: getUserIdentifier(id),
            fragment: USER_ABOUT_FRAGMENT,
        });
        const filteredAbout = [banTag, user === null || user === void 0 ? void 0 : user.about].filter(nonUndefined).join(' ');
        /**
         * We also save the ban tag ({@link BanTag}) inside user `about` field
         * After it's used to search banned users
         */
        const addBanTagResult = yield client.mutate({
            mutation: USER_UPDATE_MUTATION,
            variables: {
                id,
                input: {
                    about: filteredAbout,
                },
            },
            update(cache, result) {
                var _a;
                if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userUpdate.success)) {
                    return;
                }
                cache.modify({
                    id: getUserIdentifier(id),
                    fields: {
                        about() {
                            return filteredAbout;
                        },
                    },
                });
            },
        });
        return !!((_b = addBanTagResult.data) === null || _b === void 0 ? void 0 : _b.userUpdate.success);
    }), [client]);
};
export default useBan;
