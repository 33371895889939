import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
export const UPDATE_CITY_MUTATION = gql `
    mutation UpdateCityMutation($input: Any) {
        updateCity(input: $input) @rest(
            type: "${StatusResponseTypeName}", 
            path: "api/users/me", 
            bodyKey: "input",  
            method: "PUT"
        ) {
            success
        }
    }
`;
