export const notificationFieldMapper = {
    charms: 'charms',
    crushTime: 'crush_time',
    dailyRecap: 'daily_recap',
    crush: 'match',
    news: 'news',
    others: 'others',
    weeklyRecap: 'weekly_recap',
    dates: 'dates',
    likes: 'likes',
    messages: 'messages',
    near: 'near',
    reminders: 'reminders',
    visit: 'visit',
};
export function mapNotificationsToBody(newNotifications) {
    return Object.keys(newNotifications).reduce((prev, name) => {
        return Object.assign(Object.assign({}, prev), { [notificationFieldMapper[name]]: newNotifications[name] });
    }, {});
}
export default mapNotificationsToBody;
