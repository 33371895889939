import { UserProfileType } from '../types';
export const getProfilePosition = (position) => {
    if (position == null || position.lat == null || position.lon == null) {
        return null;
    }
    return Object.assign({ lat: position.lat, lon: position.lon }, (position.modification_date && {
        modificationDate: position.modification_date,
    }));
};
export const getProfileType = (type) => {
    switch (type) {
        case 0:
            return UserProfileType.Gift;
        case 1:
            return UserProfileType.Crossing;
        case 2:
            return UserProfileType.NewReg;
        case 3:
            return UserProfileType.Opportunity;
        case 4:
            return UserProfileType.Sponsor;
    }
    return UserProfileType.Gift;
};
