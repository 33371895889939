import { default as formatDate } from 'date-fns/format'

import { locales } from './date.locales'

class DateFnsService {
  private _locale: Locale | undefined = undefined

  public get locale(): Locale | undefined {
    return this._locale
  }

  format: typeof formatDate = (date, format) =>
    formatDate(new Date(date), format, {
      locale: this._locale,
    })

  changeLocale = (locale: string) => {
    // Don't load anything for CI mode = display keys
    if (locale === 'cimode') {
      return Promise.resolve()
    }

    return locales[locale]().then((data) => (this._locale = data.default))
  }
}

const dateService = new DateFnsService()

export default dateService
