var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import addDays from 'date-fns/addDays';
import { useCallback } from 'react';
import { USER_ID_ME, USER_INTERNAL_SUBSCRIPTION_ADD_MUTATION, USER_QUERY, } from '../../apollo';
export const useInternalSubscription = (id = USER_ID_ME) => {
    const client = useApolloClient();
    const add = useCallback((days, productId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const subscriptionStart = new Date();
        const subscriptionEnd = addDays(subscriptionStart, days);
        try {
            const result = yield client.mutate({
                mutation: USER_INTERNAL_SUBSCRIPTION_ADD_MUTATION,
                variables: {
                    input: {
                        creation_date: subscriptionStart.toISOString(),
                        expiration_date: subscriptionEnd.toISOString(),
                        product_id: productId,
                    },
                    id,
                },
                refetchQueries: [USER_QUERY],
            });
            return (_a = result.data) === null || _a === void 0 ? void 0 : _a.userInternalSubscriptionAdd.success;
        }
        catch (_b) {
            // fallthrough
        }
        return false;
    }), [client, id]);
    return { add };
};
export default useInternalSubscription;
