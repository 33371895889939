import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import { fontsStyle } from '@happn/polis'
import { getThemeFont, ThemeFont } from '@happn/theme'

export default createGlobalStyle`
  ${reset};
  ${fontsStyle};

  a {
    text-decoration: none;
    color: unset;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  body {
      height: 100%;
      font-family: ${getThemeFont(ThemeFont.LaNordRegular)};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  body.ReactModal__Body--open {
      #root {
          filter: blur(1.25rem);
          transform: translate3d(0px,0px,0px);
      }  
  }

  body.fixed {
      width: 100%;
      position: fixed;
  }

  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
      height: 100%;
      box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
      box-sizing: inherit;
  }

  #root {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
  }

  * { 
      -webkit-tap-highlight-color: transparent;
  }
`
