var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { all, equals } from 'ramda';
import { useCallback, useMemo } from 'react';
import { USER_ID_ME, USER_PHONE_NUMBERS_QUERY, } from '../../apollo';
import { getUserPhoneNumbersIdentifier } from '../../helpers';
import { USER_PHONE_NUMBERS_DELETE_MUTATION, USER_PHONE_NUMBERS_UPDATE_MUTATION, } from './gql';
const usePhoneNumbers = (id = USER_ID_ME) => {
    var _a;
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_PHONE_NUMBERS_QUERY, { fetchPolicy: 'cache-only', variables: { id } });
    const phoneNumbers = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.userPhoneNumbers) === null || _a === void 0 ? void 0 : _a.phoneNumbers) || []; }, [(_a = data === null || data === void 0 ? void 0 : data.userPhoneNumbers) === null || _a === void 0 ? void 0 : _a.phoneNumbers]);
    const update = useCallback((newPhoneNumbers) => __awaiter(void 0, void 0, void 0, function* () {
        if (equals(phoneNumbers, newPhoneNumbers)) {
            return true;
        }
        const needToDelete = phoneNumbers.filter((phoneNumber) => !newPhoneNumbers.includes(phoneNumber)).length > 0;
        if (needToDelete) {
            const { data: deleteData } = yield apolloClient.mutate({
                mutation: USER_PHONE_NUMBERS_DELETE_MUTATION,
                variables: { id },
                update(cache, result) {
                    var _a;
                    if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userPhoneNumbersDelete.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserPhoneNumbersIdentifier(id),
                        fields: {
                            phoneNumbers() {
                                return [];
                            },
                        },
                    });
                },
            });
            if (!(deleteData === null || deleteData === void 0 ? void 0 : deleteData.userPhoneNumbersDelete.success)) {
                return false;
            }
        }
        const filteredPhoneNumbers = needToDelete
            ? newPhoneNumbers
            : newPhoneNumbers.filter((phoneNumber) => !phoneNumbers.includes(phoneNumber));
        const results = yield Promise.all(filteredPhoneNumbers.map((phoneNumber) => __awaiter(void 0, void 0, void 0, function* () {
            const { data: phoneNumbersUpdateData } = yield apolloClient.mutate({
                mutation: USER_PHONE_NUMBERS_UPDATE_MUTATION,
                variables: { id, input: { phone_number: phoneNumber } },
                update(cache, result) {
                    var _a;
                    if (!((_a = result.data) === null || _a === void 0 ? void 0 : _a.userPhoneNumbersUpdate.success)) {
                        return;
                    }
                    cache.modify({
                        id: getUserPhoneNumbersIdentifier(id),
                        fields: {
                            phoneNumbers(existing) {
                                return [...existing, phoneNumber];
                            },
                        },
                    });
                },
            });
            return !!(phoneNumbersUpdateData === null || phoneNumbersUpdateData === void 0 ? void 0 : phoneNumbersUpdateData.userPhoneNumbersUpdate.success);
        })));
        return all((v) => v, results);
    }), [apolloClient, id, phoneNumbers]);
    return {
        phoneNumbers,
        update,
    };
};
export default usePhoneNumbers;
