import { nonUndefined } from '@happn/helpers';
export const getBalance = (userGto) => {
    if (!userGto.user_balance) {
        return [];
    }
    return userGto.user_balance
        .map((ub) => {
        if (!ub.type) {
            return undefined;
        }
        return {
            type: ub.type,
            total: ub.total || 0,
            permanent: ub.permanent || 0,
            renewable: ub.renewable || 0,
            renewablePerPeriod: ub.renewable_per_period || 0,
            cooldownPeriod: ub.cooldown_period || 0,
            cooldownTimeLeft: ub.cooldown_time_left || 0,
        };
    })
        .filter(nonUndefined);
};
