import { mergeDeepRight } from 'ramda';
import { common } from './common';
import { console } from './console';
import { getLocalStorageConfig } from './helpers';
import { webapp } from './webapp';
const env = {
    common,
    webapp,
    console,
};
const config = getLocalStorageConfig();
export * from './helpers';
export default mergeDeepRight(env, config);
