import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import env from '@happn/env';
export class SentryService {
    static getInstance() {
        if (!SentryService._instance) {
            SentryService._instance = new SentryService();
        }
        return SentryService._instance;
    }
    init(options = {}) {
        if (!env.common.sentryDsn) {
            return;
        }
        Sentry.init(Object.assign(Object.assign({}, options), { 
            // workaround for ignore errors https://github.com/getsentry/sentry-docs/issues/1137
            beforeSend(event) {
                if (event && event.message && options.ignoreErrors) {
                    // @ts-ignore
                    options.ignoreErrors.forEach((s) => {
                        var _a;
                        if ((_a = event.message) === null || _a === void 0 ? void 0 : _a.match(s))
                            return null;
                    });
                }
                return event;
            }, dsn: env.common.sentryDsn, integrations: [new Integrations.BrowserTracing()], tracesSampleRate: env.common.isProduction ? 0.001 : 1.0, environment: env.common.stage, release: env.common.sentryRelease }));
    }
}
const sentryService = SentryService.getInstance();
export default sentryService;
