import { gql } from '@apollo/client';
export const DEVICE_FRAGMENT = gql `
  fragment DeviceFields on Device {
    id
    country
    countryId
    appBuild
    language
    languageId
    modificationDate
    osBuild
    status
    type
    position {
      alt
      circleSize
      hacc
      latitude
      locationAccuracy
      longitude
      vacc
    }
  }
`;
