export var BanTag;
(function (BanTag) {
    BanTag["Commercial"] = "#C";
    BanTag["Fake"] = "#F";
    BanTag["Minor"] = "#M";
    BanTag["Prostitute"] = "#P";
    BanTag["Racism"] = "#R";
    BanTag["Sexual"] = "#S";
    BanTag["WebBot"] = "#W";
    BanTag["Duplicate"] = "#D";
    BanTag["ProactiveResearch"] = "#A";
    BanTag["Happn"] = "#H";
})(BanTag || (BanTag = {}));
