export const webapp = {
    // misc
    appBannerUrl: process.env.REACT_APP_DOWNLOAD_APP_BANNER_URL || '',
    giphyApiKey: process.env.REACT_APP_GIPHY_API_KEY || '',
    mapkitJwt: process.env.REACT_APP_MAPKIT_JWT || '',
    safariWebsitePushId: process.env.REACT_APP_SAFARI_WEBSITE_PUSH_ID || '',
    // social
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    facebookAppId: process.env.REACT_APP_FB_APP_ID || '',
    spotifyPlaylistId: process.env.REACT_APP_SPOTIFY_PLAYLIST_ID || '',
    spotifyDefaultMarket: process.env.REACT_APP_SPOTIFY_DEFAULT_MARKET || '',
    // tracking / marketing
    adjustAppToken: process.env.REACT_APP_ADJUST_APP_TOKEN || '',
    brazeApiKey: process.env.REACT_APP_BRAZE_API_KEY || '',
    brazeBaseUrl: process.env.REACT_APP_BRAZE_BASE_URL || '',
    trackingApi: process.env.REACT_APP_API_TRACKING_URL || '',
    websiteCookieUrl: process.env.REACT_APP_WEBSITE_COOKIE_URL || '',
    websiteBasicAuthSecret: process.env.REACT_APP_WEBSITE_BASIC_AUTH_SECRET || '',
    websiteBaseUrl: process.env.REACT_APP_WEBSITE_BASE_URL || 'https://www.happn.com',
};
