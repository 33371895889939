import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_LATEST_SUBSCRIPTION } from './gql/queries';
export const useLatestSubscription = (userId = USER_ID_ME) => {
    const { data } = useQuery(USER_LATEST_SUBSCRIPTION, {
        variables: {
            userId,
        },
    });
    return data === null || data === void 0 ? void 0 : data.userLatestSubscription;
};
export default useLatestSubscription;
