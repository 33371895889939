import { getPagination } from '@happn/apollo';
import { devicesMappers } from '@happn/devices';
import { nonUndefined } from '@happn/helpers';
import { UserProfileType, } from '../types';
import { getAcl } from './acl';
import { getArchive } from './archives';
import { getUserCredit } from './credit';
import { getNotification } from './notifications';
import { getOrder, getSubscriptionOrder } from './orders';
import { getLatestSubscription, getSubscription } from './subscriptions';
import { getTraitAnswer } from './traits';
import { getUser } from './user';
export const toUserModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return null;
    }
    if (Array.isArray(response.data)) {
        return response.data.map(getUser);
    }
    return getUser(response.data);
};
export const toUserProfileModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.id)) {
        return null;
    }
    const user = getUser(response.data);
    if (!user.lastMeetPosition) {
        return {
            user,
            type: UserProfileType.Gift,
        };
    }
    if (!user.crossingsCount) {
        return {
            user,
            type: UserProfileType.Opportunity,
            position: user.lastMeetPosition,
        };
    }
    return {
        user,
        type: UserProfileType.Crossing,
        position: user.lastMeetPosition,
        crossingsCount: user.crossingsCount,
    };
};
export const toUsersSearchModel = (response) => {
    var _a;
    return ({
        users: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(getUser)) || [],
        pagination: getPagination(response === null || response === void 0 ? void 0 : response.pagination),
    });
};
export const toUserUploadImageModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.id) || !response.data.url) {
        return null;
    }
    return {
        id: response.data.id,
        isDefault: false,
        url: response.data.url,
    };
};
export const toUserSaveTraitAnswerModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return { answer: '' };
    }
    return (getTraitAnswer(response.data) || null);
};
export const getIdFromNativeResponse = (response) => {
    try {
        const url = new URL((response === null || response === void 0 ? void 0 : response.url) || '');
        return new URLSearchParams(url.search).get('user_id') || '';
    }
    catch (_a) {
        return '';
    }
};
export const toUserMobileIdsModel = (response, nativeResponse) => ({
    id: getIdFromNativeResponse(nativeResponse),
    mobileIds: (response === null || response === void 0 ? void 0 : response.data) || [],
});
export const toUserMobileTokensModel = (response, nativeResponse) => ({
    id: getIdFromNativeResponse(nativeResponse),
    mobileTokens: (response === null || response === void 0 ? void 0 : response.data) || [],
});
export const toUserPhoneNumbersModel = (response, nativeResponse) => ({
    id: getIdFromNativeResponse(nativeResponse),
    phoneNumbers: (response === null || response === void 0 ? void 0 : response.data) || [],
});
export const toUserDevicesModel = (response, nativeResponse) => ({
    id: getIdFromNativeResponse(nativeResponse),
    devices: (devicesMappers.toDeviceModel(response) || []),
});
export const getRecoveryInfo = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.status) || !gto.type || !gto.value) {
        return undefined;
    }
    return {
        status: gto.status,
        type: gto.type,
        value: gto.value,
    };
};
export const toUserRecoveryInfoModel = (response, nativeResponse) => {
    var _a;
    return ({
        id: getIdFromNativeResponse(nativeResponse),
        recoveryInfo: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.map(getRecoveryInfo).filter(nonUndefined)) || [],
    });
};
export const toUserRecoveryTokenModel = (response) => {
    var _a;
    return ({
        recoveryUrl: ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.recovery_url) || null,
    });
};
export const toUserLikesReceivedModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return {
            items: [],
            pagination: null,
        };
    }
    return {
        items: response.data
            .map((gto) => {
            var _a;
            if (!((_a = gto === null || gto === void 0 ? void 0 : gto.liker) === null || _a === void 0 ? void 0 : _a.id)) {
                return undefined;
            }
            return getUser(gto.liker);
        })
            .filter(nonUndefined),
        pagination: getPagination(response.pagination),
    };
};
export const toUserCreditsModal = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return {
            items: [],
            pagination: null,
        };
    }
    return {
        items: response.data.map(getUserCredit).filter(nonUndefined),
        pagination: getPagination(response.pagination),
    };
};
export const toUserOrdersModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return {
            items: [],
            pagination: null,
        };
    }
    return {
        items: response.data.map(getOrder).filter(nonUndefined),
        pagination: getPagination(response.pagination),
    };
};
export const toSubscriptionsModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return [];
    }
    return response.data.map(getSubscription).filter(nonUndefined);
};
export const toSubscriptionOrdersModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return [];
    }
    return response.data.map(getSubscriptionOrder).filter(nonUndefined);
};
export const toUserNotificationsModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return [];
    }
    return response.data.map(getNotification).filter(nonUndefined);
};
export const toUserArchivesModel = (response) => {
    if (!(response === null || response === void 0 ? void 0 : response.data)) {
        return null;
    }
    if (Array.isArray(response.data)) {
        return response.data.map(getArchive).filter(nonUndefined);
    }
    return getArchive(response.data) || null;
};
export const toUserArchiveDownloadLinkModel = (response) => ({
    url: (response === null || response === void 0 ? void 0 : response.data) || '',
});
export const toRelationshipsBetweenUsersModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.status_a_b) || !response.data.status_b_a) {
        return null;
    }
    return {
        statusAB: response.data.status_a_b,
        statusBA: response.data.status_b_a,
    };
};
export const toUserAclsModel = (response) => {
    var _a;
    if (!((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.acl) || !response.data.id) {
        return null;
    }
    return {
        id: response.data.id,
        acls: response.data.acl.map(getAcl).filter(nonUndefined),
    };
};
export const toUserLatestSubscription = (response) => {
    return getLatestSubscription(response === null || response === void 0 ? void 0 : response.data);
};
