import { gql } from '@apollo/client';
export const USER_NOTIFICATIONS_PREFERENCES_QUERY = gql `
  query UserNotificationsPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        notifications {
          charms
          crush
          crushTime
          dailyRecap
          news
          others
          weeklyRecap
          dates
          likes
          messages
          near
          reminders
          visit
        }
      }
    }
  }
`;
