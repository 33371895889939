export const getOrder = (gto) => {
    var _a, _b, _c, _d, _e;
    if (!(gto === null || gto === void 0 ? void 0 : gto.id) || !gto.creation_date || !gto.release_date || !gto.channel) {
        return undefined;
    }
    return {
        id: gto.id,
        channel: gto.channel,
        creationDate: gto.creation_date,
        currency: gto.currency || '',
        label: ((_b = (_a = gto.content) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.label) || '',
        ordersCount: ((_c = gto.user) === null || _c === void 0 ? void 0 : _c.nb_orders) || 0,
        quantity: ((_e = (_d = gto.content) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.quantity) || 0,
        releaseDate: gto.release_date,
        sandbox: gto.sandbox || false,
        totalAmountAti: gto.total_amount_ati || 0,
    };
};
export const getSubscriptionOrder = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.order_id) ||
        !gto.channel ||
        !gto.purchase_date ||
        !gto.release_date ||
        !gto.store_product_id) {
        return undefined;
    }
    return {
        amount: gto.amount || 0,
        channel: gto.channel,
        currency: gto.currency || '',
        id: gto.order_id,
        purchaseDate: gto.purchase_date,
        releaseDate: gto.release_date,
        sandbox: gto.sandbox || false,
        storeProductId: gto.store_product_id,
    };
};
