export const PICTURE_SETTINGS = `mode(1).width(1400).height(1600)`;
export const PICTURE_FIELDS = 'id,is_default,url,width,height';
export const SOCIAL_FIELDS = 'facebook,vk,apple_sign_in,instagram,google_sign_in';
export const USER_BASE_FIELDS = [
    'id',
    'audios',
    'first_name',
    'last_name',
    'gender',
    'gender_alias',
    'age',
    'about',
    'job',
    'workplace',
    'school',
    'modification_date',
    'is_moderator',
    'is_admin',
    'type',
    'status',
    'last_position_update',
    'register_date',
    'sensitive_traits_preferences',
    'mysterious_mode_preferences',
    'residence_city',
    'teaser_answers',
];
export const USER_IP_FIELDS = ['first_ip'];
export const USER_LOCALIZATION_INFORMATION_FIELDS = ['country', 'language'];
export const USER_TRAITS_FIELDS = ['traits', 'traits_v2'];
export const USER_PICTURE_FIELDS = [
    `picture.${PICTURE_SETTINGS}.fields(${PICTURE_FIELDS})`,
];
export const USER_PROFILES_FIELDS = [
    `profiles.${PICTURE_SETTINGS}.fields(${PICTURE_FIELDS})`,
];
export const USER_SOCIAL_FIELDS = [
    'spotify_tracks',
    `social_synchronization.fields(${SOCIAL_FIELDS})`,
];
export const DEVICE_INFO_FIELDS = ['device_info'];
export const USER_RELATIONS_FIELDS = [
    'is_charmed',
    'is_accepted',
    'has_charmed_me',
    'my_relations',
];
export const USER_CROSSING_FIELDS = ['last_meet_position', 'crossing_nb_times'];
export const USER_VERIFICATION_FIELDS = ['verification.fields(status,reason)'];
export const USER_UNREAD_FIELDS = [
    'unread_conversations',
    'unread_notifications',
];
export const USER_PREFERENCES_FIELDS = [
    'matching_preferences.fields(matching_traits.fields(enabled,traits))',
    'location_preferences',
    'mysterious_mode_preferences',
    'marketing_preferences',
    'notification_settings',
    'biometric_preferences',
];
export const USER_BALANCE_FIELDS = ['is_premium', 'user_balance'];
export const USER_LAST_ACCEPTED_VERSIONS_FIELDS = [
    'last_tos_version_accepted',
    'last_sdc_version_accepted',
    'last_cookie_v1_version_accepted',
    'last_cookie_v2_version_accepted',
    'last_cookie_v3_version_accepted',
];
export const USER_SUBSCRIPTION_FIELDS = ['credits', 'subscription_level'];
export const USER_CITY_FIELDS = ['location_city', 'residence_city'];
export const USER_PENDING_LIKERS_FIELDS = ['pending_likers'];
export const USER_EMAIL_FIELDS = ['login'];
export const USER_NOTIFICATIONS_FIELDS = [
    'id',
    'modification_date',
    'notification_type',
    'nb_times',
    `notifier.fields(${[...USER_BASE_FIELDS, ...USER_PICTURE_FIELDS]})`,
];
export const USER_LIKES_RECEIVED_FIELDS = [
    'id',
    `liker.fields(${[
        ...USER_PROFILES_FIELDS,
        ...USER_PICTURE_FIELDS,
        'id',
        'age',
        'gender',
        'gender_alias',
        'status',
        'job',
        'first_name',
        'is_accepted',
        'has_charmed_me',
        'modification_date',
        'residence_city',
    ]})`,
];
export const USERS_EXPORTS_FIELDS = [
    'cid',
    'exporter_user_id',
    'filter',
    'fields',
    'progress_percentage',
    'export_date.format(c)',
    'signed_url',
];
export const USER_PROFILE_FIELDS = [
    ...USER_BASE_FIELDS,
    ...USER_PROFILES_FIELDS,
    ...USER_TRAITS_FIELDS,
    ...USER_SOCIAL_FIELDS,
    ...USER_RELATIONS_FIELDS,
    ...USER_CROSSING_FIELDS,
    ...USER_VERIFICATION_FIELDS,
];
export const USER_SEARCH_FIELDS = [
    ...USER_BASE_FIELDS,
    ...USER_PROFILES_FIELDS,
    ...USER_PICTURE_FIELDS,
    ...DEVICE_INFO_FIELDS,
    ...USER_PREFERENCES_FIELDS,
    ...USER_EMAIL_FIELDS,
];
export const USER_FIELDS = [
    ...USER_BASE_FIELDS,
    ...USER_PICTURE_FIELDS,
    ...USER_PROFILES_FIELDS,
    ...USER_SOCIAL_FIELDS,
    ...USER_TRAITS_FIELDS,
    ...USER_VERIFICATION_FIELDS,
    ...USER_UNREAD_FIELDS,
    ...USER_BALANCE_FIELDS,
    ...USER_SUBSCRIPTION_FIELDS,
    ...USER_PREFERENCES_FIELDS,
    ...USER_LAST_ACCEPTED_VERSIONS_FIELDS,
    ...USER_CITY_FIELDS,
    ...USER_PENDING_LIKERS_FIELDS,
    ...USER_EMAIL_FIELDS,
    ...DEVICE_INFO_FIELDS,
    ...USER_IP_FIELDS,
    ...USER_LOCALIZATION_INFORMATION_FIELDS,
];
