var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { equals } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useIdentities } from '@happn/identities';
import { USER_ID_ME, USER_MOBILE_IDS_DELETE_MUTATION, USER_MOBILE_IDS_QUERY, } from '../../apollo';
import { getUserMobileIdsIdentifier } from '../../helpers';
const useMobileIds = (id = USER_ID_ME) => {
    var _a;
    const apolloClient = useApolloClient();
    const { patch } = useIdentities();
    const { data } = useQuery(USER_MOBILE_IDS_QUERY, { fetchPolicy: 'cache-only', variables: { id } });
    const mobileIds = useMemo(() => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.userMobileIds) === null || _a === void 0 ? void 0 : _a.mobileIds) || []; }, [(_a = data === null || data === void 0 ? void 0 : data.userMobileIds) === null || _a === void 0 ? void 0 : _a.mobileIds]);
    const update = useCallback((newMobileIds) => __awaiter(void 0, void 0, void 0, function* () {
        if (equals(mobileIds, newMobileIds)) {
            return true;
        }
        const needToDelete = mobileIds.filter((mobileId) => !newMobileIds.includes(mobileId))
            .length > 0;
        if (needToDelete) {
            const { data: deleteData } = yield apolloClient.mutate({ mutation: USER_MOBILE_IDS_DELETE_MUTATION, variables: { id } });
            if (!(deleteData === null || deleteData === void 0 ? void 0 : deleteData.userMobileIdsDelete.success)) {
                return false;
            }
        }
        const filteredMobileIds = needToDelete
            ? newMobileIds
            : newMobileIds.filter((mobileId) => !mobileIds.includes(mobileId));
        const patchResult = yield patch({
            userId: id,
            mobileIds: filteredMobileIds,
        });
        if (!patchResult) {
            return false;
        }
        apolloClient.cache.modify({
            id: getUserMobileIdsIdentifier(id),
            fields: {
                mobileIds() {
                    return newMobileIds;
                },
            },
        });
        return true;
    }), [apolloClient, id, mobileIds, patch]);
    return { mobileIds, update };
};
export default useMobileIds;
