export const getInstagram = (instagram) => {
    var _a;
    return {
        pictures: ((_a = instagram === null || instagram === void 0 ? void 0 : instagram.pictures) === null || _a === void 0 ? void 0 : _a.map((p) => {
            var _a;
            return ({
                createdAt: p.created_time || '',
                id: p.id || '',
                link: p.link || '',
                description: p.description || '',
                images: ((_a = p.images) === null || _a === void 0 ? void 0 : _a.map((i) => ({ url: i.url || '' }))) || [],
            });
        })) || [],
    };
};
export const getSocialPlatform = (platform) => ({
    id: (platform === null || platform === void 0 ? void 0 : platform.id) || '',
    email: (platform === null || platform === void 0 ? void 0 : platform.email) || '',
});
export const getSocialMedia = (user) => {
    if (!user.social_synchronization) {
        return null;
    }
    return {
        instagram: getInstagram(user.social_synchronization.instagram),
        facebook: getSocialPlatform(user.social_synchronization.facebook),
        google: getSocialPlatform(user.social_synchronization.google_sign_in),
        apple: getSocialPlatform(user.social_synchronization.apple_sign_in),
    };
};
