import { equals } from 'ramda'
import { useEffect, useState, FC } from 'react'

import { getLocalStorageConfig, setLocalStorageConfig } from '@happn/env'
import loggerService from '@happn/logger'

import { FullScreenLoader } from 'components/Loaders'

const Environment: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetch('config.json')
      .then((res) => res.json())
      .then((config) => {
        const previousConfig = getLocalStorageConfig()

        if (equals(config, previousConfig)) {
          return
        }

        localStorage.clear()
        setLocalStorageConfig(config)

        window.location.reload()
      })
      .catch(() => {
        loggerService.info(new Error('Config file not found'))
      })
      .finally(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <>{children}</>
}

export default Environment
