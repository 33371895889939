import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_MATCHING_TRAITS_PREFERENCES_QUERY, } from './gql';
const useMatchingTraitsPreferences = (id = USER_ID_ME) => {
    var _a, _b;
    const { data } = useQuery(USER_MATCHING_TRAITS_PREFERENCES_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    return { matchingTraits: ((_b = (_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.preferences) === null || _b === void 0 ? void 0 : _b.matchingTraits) || [] };
};
export default useMatchingTraitsPreferences;
