import { gql } from '@apollo/client';
import { USER_STATUS_FRAGMENT } from '../../../apollo';
export const USER_STATUS_QUERY = gql `
  ${USER_STATUS_FRAGMENT}

  query UserStatusQuery($id: String) {
    user(id: $id) {
      id
      status
      isSub
      onlineStatus
      isSponsor
      isModerator
    }
  }
`;
