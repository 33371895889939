import { AnimationType } from '@happn/animations'

import Styled from './FullScreen.styles'

const FullScreen = () => (
  <Styled.Outer data-testid="full-screen-loader">
    <Styled.Image type={AnimationType.Loading} loop />
  </Styled.Outer>
)

export default FullScreen
