import { ApolloError } from '@apollo/client';
export const genericError = {
    error: new ApolloError({
        graphQLErrors: [],
        networkError: {
            name: '403',
            message: 'Request failed with status code 403',
            result: {
                error_code: 403,
            },
        },
    }),
    id: '4658ca6f-3bd8-4197-826c-f70e5e60431b',
};
export const underEighteenError = {
    error: new ApolloError({
        graphQLErrors: [],
        networkError: {
            name: '403',
            message: 'Request failed with status code 403',
            result: {
                error_code: 1022,
            },
        },
    }),
    id: '021e4204-f165-475a-98b9-5a58edb45e01',
};
export const userBannedError = {
    error: new ApolloError({
        graphQLErrors: [],
        networkError: {
            name: '403',
            message: 'Request failed with status code 412',
            result: {
                error_code: 1018,
            },
        },
    }),
    id: 'b21692b6-4a93-4e5b-a559-68fcf712dd66',
};
