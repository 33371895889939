import { useQuery } from '@apollo/client';
import { USER_ID_ME, VerificationStatusType, } from '../../apollo';
import { USER_VERIFICATION_QUERY } from './gql';
const useVerification = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_VERIFICATION_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    return ((data === null || data === void 0 ? void 0 : data.user.verification) || {
        status: VerificationStatusType.Unverified,
        reason: '',
    });
};
export default useVerification;
