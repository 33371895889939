import { gql } from '@apollo/client';
import { TeasersTypeName } from '../../../apollo';
export const GET_TEASERS_QUERY = gql `
  query GetTeasersQuery {
    teasersList @rest(
      path: "api/v1/teasers/", 
      type: "${TeasersTypeName}",  
      method: "GET"
    ) {
      id
      defaultTeaser
      translatedTeaser
      categoryId
    }
  }
`;
