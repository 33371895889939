import { jsx as _jsx } from "react/jsx-runtime";
import { useLottie } from 'lottie-react';
import { useEffect } from 'react';
import { AnimationType } from './Lottie.types';
import circleEndAnimationDark from './assets/circle-end-dark.json';
import circleEndAnimationLight from './assets/circle-end-light.json';
import clockAnimation from './assets/clock.json';
import darkLoadingAnimation from './assets/dark-loading.json';
import heartsAnimation from './assets/hearts.json';
import loadingAnimation from './assets/loading.json';
const ANIMATIONS = {
    [AnimationType.DarkLoading]: darkLoadingAnimation,
    [AnimationType.CircleEndLight]: circleEndAnimationLight,
    [AnimationType.CircleEndDark]: circleEndAnimationDark,
    [AnimationType.Clock]: clockAnimation,
    [AnimationType.Hearts]: heartsAnimation,
    [AnimationType.Loading]: loadingAnimation,
};
const Lottie = ({ autoplay = true, className, loop = true, observable, onComplete, type, }) => {
    const { play, View } = useLottie({
        loop,
        autoplay: (observable === null || observable === void 0 ? void 0 : observable.isInView) ? false : autoplay,
        animationData: ANIMATIONS[type],
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
        onComplete,
    });
    useEffect(() => {
        if (observable === null || observable === void 0 ? void 0 : observable.isInView) {
            play();
        }
    }, [play, observable === null || observable === void 0 ? void 0 : observable.isInView]);
    if (observable && !(observable === null || observable === void 0 ? void 0 : observable.isInView)) {
        return null;
    }
    return (_jsx("div", Object.assign({ "data-testid": type, className: className }, { children: View }), void 0));
};
export default Lottie;
