var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { SHOP_PRODUCT_ADD_MUTATION, SHOP_PRODUCTS_QUERY, SHOP_PRODUCT_UPDATE_MUTATION, isShopProductPlan, } from '../apollo';
import { IntroductoryReactivationFilter, } from './useProducts.types';
const useProducts = () => {
    const apolloClient = useApolloClient();
    const add = useCallback((product) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_PRODUCT_ADD_MUTATION,
                variables: { input: snakecaseKeys(product) },
            });
            const addedProduct = (data === null || data === void 0 ? void 0 : data.shopProductAdd) || null;
            if (addedProduct) {
                apolloClient.cache.modify({
                    fields: {
                        shopProducts: (shopProducts, { toReference }) => [toReference(addedProduct), ...shopProducts],
                    },
                });
            }
            return addedProduct;
        }
        catch (_a) {
            return null;
        }
    }), [apolloClient]);
    const update = useCallback((product) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!product.id) {
                return null;
            }
            const { data } = yield apolloClient.mutate({
                mutation: SHOP_PRODUCT_UPDATE_MUTATION,
                variables: { id: product.id, input: snakecaseKeys(product) },
            });
            return (data === null || data === void 0 ? void 0 : data.shopProductUpdate) || null;
        }
        catch (_b) {
            return null;
        }
    }), [apolloClient]);
    const list = useCallback((filters = {}) => __awaiter(void 0, void 0, void 0, function* () {
        var _c, _d;
        const result = yield apolloClient.query({
            query: SHOP_PRODUCTS_QUERY,
        });
        const data = (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.shopProducts) === null || _d === void 0 ? void 0 : _d.filter((p) => (filters.id ? p.id === filters.id : true)).filter((p) => filters.storeProductId
            ? p.storeProductId === filters.storeProductId
            : true).filter((p) => filters.name
            ? p.name.toLowerCase().includes(filters.name.toLowerCase())
            : true).filter((p) => filters.productType ? p.productType === filters.productType : true).filter((p) => (filters.channel ? p.channel === filters.channel : true)).filter((p) => { var _a; return ((_a = filters.status) === null || _a === void 0 ? void 0 : _a.length) ? filters.status.includes(p.activated) : true; }).filter((p) => {
            switch (filters.introductoryReactivation) {
                case IntroductoryReactivationFilter.Introductory:
                    return p.introductory;
                case IntroductoryReactivationFilter.Reactivation:
                    return p.reactivation;
                case IntroductoryReactivationFilter.IntroductoryAndReactivation:
                    return p.introductory && p.reactivation;
                case IntroductoryReactivationFilter.None:
                    return !p.introductory && !p.reactivation;
                default:
                    return true;
            }
        }).filter((p) => {
            if (!filters.priceId) {
                return true;
            }
            if (!isShopProductPlan(p)) {
                return false;
            }
            const ids = p.planInformation.prices.map((price) => { var _a; return (_a = price.id) === null || _a === void 0 ? void 0 : _a.toLowerCase(); }) ||
                [];
            return ids.includes(filters.priceId.toLowerCase());
        });
        return {
            data: data || [],
        };
    }), [apolloClient]);
    return { add, update, list };
};
export default useProducts;
