import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar/Avatar';
import Styled from './Item.styles';
const Item = ({ translationKey, testId, icon, picture, isActive, reduced, href, onConversationItemClick, }) => {
    const { t } = useTranslation();
    return (_jsx(Styled.Item, Object.assign({ "data-testid": testId, isActive: isActive, onClick: onConversationItemClick }, { children: _jsxs(Link, Object.assign({ to: href, title: t(translationKey) }, { children: [icon && _jsx(Styled.IconWrapper, { children: icon }, void 0), picture && (_jsx(Styled.IconWrapper, { children: _jsx(Avatar, { picture: picture, size: "xx-small" }, void 0) }, void 0)), !reduced && _jsx(Styled.Title, { children: t(translationKey) }, void 0)] }), void 0) }), void 0));
};
export default Item;
