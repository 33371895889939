export const getSubscription = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.subscription_id) ||
        !gto.channel ||
        !gto.end_date ||
        !gto.start_date ||
        !gto.status ||
        !gto.subscription_type ||
        !gto.user_id ||
        !gto.subscription_level) {
        return undefined;
    }
    return {
        channel: gto.channel,
        endDate: gto.end_date,
        id: gto.subscription_id,
        introductory: gto.introductory || false,
        reactivation: gto.introductory || false,
        startDate: gto.start_date,
        cancellationDate: gto.cancellation_date,
        status: gto.status,
        type: gto.subscription_type,
        userId: gto.user_id,
        level: gto.subscription_level,
    };
};
export const getLatestSubscription = (gto) => {
    if (!(gto === null || gto === void 0 ? void 0 : gto.user_id) ||
        !gto.status ||
        !gto.channel ||
        !gto.end_date ||
        !gto.start_date ||
        !gto.subscription_id ||
        !gto.subscription_type) {
        return null;
    }
    return {
        userId: gto.user_id,
        channel: gto.channel,
        endDate: gto.end_date,
        introductory: gto.introductory || false,
        reactivation: gto.reactivation || false,
        startDate: gto.start_date,
        status: gto.status,
        subscriptionId: gto.subscription_id,
        subscriptionType: gto.subscription_type,
    };
};
