import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from 'react';
import Styled from './Tab.styles';
const Tab = ({ onClick, isSelected, label, iconLeft, iconRight, badge, className, variant = 'dark', }) => {
    const handleKeyPress = useCallback(({ key }) => {
        if (key === 'Enter') {
            onClick();
        }
    }, [onClick]);
    return (_jsxs(Styled.Tab, Object.assign({ tabIndex: 0, onClick: onClick, isSelected: isSelected, variant: variant, onKeyPress: handleKeyPress, className: className }, { children: [iconLeft && _jsx(Styled.IconLeftWrapper, { children: iconLeft }, void 0), _jsx(Styled.Label, { children: label }, void 0), iconRight && (_jsx(Styled.IconRightWrapper, { children: iconRight }, void 0)), badge && _jsx(Styled.BadgeWrapper, { children: badge }, void 0)] }), void 0));
};
export default Tab;
