import { gql } from '@apollo/client';
import { USER_PICTURES_FRAGMENT } from '../../../apollo';
export const USER_PICTURES_QUERY = gql `
  ${USER_PICTURES_FRAGMENT}

  query UserPicturesQuery($id: String) {
    user(id: $id) {
      id
      ...UserPictures
    }
  }
`;
