import { gql } from '@apollo/client';
export const USER_BIOMETRIC_PREFERENCES_QUERY = gql `
  query UserBiometricPreferencesQuery($id: String) {
    user(id: $id) {
      id
      preferences {
        biometric {
          profileVerification {
            accepted
          }
        }
      }
    }
  }
`;
