import apolloService, { dateReadFunction } from '@happn/apollo';
import { toDeviceModel } from './mappers';
import { DeviceTypeName } from './types';
export const apolloRegisterDevices = () => {
    apolloService.registerTypePolicy(DeviceTypeName, {
        fields: {
            modificationDate: dateReadFunction,
        },
    });
    apolloService.registerMapper(DeviceTypeName, toDeviceModel);
};
