import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useIsSub, usePictures, usePendingLikers, useUnreadInfo, } from '@happn/user';
import Item from './Item/Item';
import { config } from './SideBar.config';
import Styled, { SIDE_BAR_WIDTH_REDUCED, SIDE_BAR_WIDTH, } from './SideBar.styles';
const SideBar = ({ reduced = false, onConversationItemClick }) => {
    const { firstPicture } = usePictures();
    const { pathname } = useLocation();
    const { conversations: unreadConversationsCount } = useUnreadInfo();
    const isUserPremium = useIsSub();
    const pendingLikers = usePendingLikers();
    const itemConfig = config({
        firstPicture: firstPicture === null || firstPicture === void 0 ? void 0 : firstPicture.url,
        pendingLikersCount: pendingLikers === null || pendingLikers === void 0 ? void 0 : pendingLikers.label,
        unreadConversationsCount,
        isUserPremium,
    });
    const Logo = () => (reduced ? _jsx(Styled.Logo, {}, void 0) : _jsx(Styled.LogoWithText, {}, void 0));
    return (_jsxs(Styled.SideBar, Object.assign({ animate: {
            width: reduced ? SIDE_BAR_WIDTH_REDUCED : SIDE_BAR_WIDTH,
        } }, { children: [_jsx(Styled.HomeLink, Object.assign({ to: "/home" }, { children: _jsx(Logo, {}, void 0) }), void 0), _jsx("ul", { children: itemConfig.map((item) => {
                    if (!item) {
                        return null;
                    }
                    return (_jsx(Item, Object.assign({ isActive: pathname.startsWith(item.href), reduced: reduced, onConversationItemClick: item.testId === 'sidebar-item-crushs'
                            ? onConversationItemClick
                            : undefined }, item), item.translationKey));
                }) }, void 0)] }), void 0));
};
export default SideBar;
