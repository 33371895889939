import { gql } from '@apollo/client';
import { StatusResponseTypeName } from '@happn/apollo';
import { UserSaveTraitAnswerTypeName } from '../../../apollo';
export const USER_REMOVE_TRAIT_ANSWER_MUTATION = gql `
    mutation UserRemoveTraitAnswerMutation($userId: String, $traitId: String) {
        userRemoveTraitAnswer(userId: $userId, traitId: $traitId)
            @rest(
                type: "${StatusResponseTypeName}"
                path: "api/v1/trait-answers/users/{args.userId}/traits/{args.traitId}"
                method: "DELETE"
            ) {
            success
        }
    }
`;
export const USER_SAVE_TRAIT_ANSWER_MUTATION = gql `
    mutation UserSaveTraitAnswerMutation($userId: String, $traitId: String, $input: Any) {
        userSaveTraitAnswer(userId: $userId, traitId: $traitId, input: $input)
            @rest(
                type: "${UserSaveTraitAnswerTypeName}"
                path: "api/v1/trait-answers/users/{args.userId}/traits/{args.traitId}"
                method: "PUT"
            )  {
                traitId
                answer
                rawAnswer {
                    type
                    single {
                        id
                    }
                    float_range {
                        value
                        metric
                    }
                }
            }
    }
`;
