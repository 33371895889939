import { getUser } from '../user';
export const getNotification = (gto) => {
    var _a;
    if (!(gto === null || gto === void 0 ? void 0 : gto.id) ||
        !gto.modification_date ||
        !gto.notification_type ||
        !((_a = gto.notifier) === null || _a === void 0 ? void 0 : _a.id)) {
        return undefined;
    }
    const user = getUser(gto.notifier);
    return {
        id: gto.id,
        modificationDate: gto.modification_date,
        type: gto.notification_type,
        count: gto.nb_times || 0,
        user,
    };
};
