var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { USER_ID_ME } from '../../apollo/types';
import { USER_STRIPE_SUBSCRIPTION_CANCEL_MUTATION } from './gql/mutations';
export const useStripeSubscription = (id = USER_ID_ME, subscriptionId = '') => {
    const client = useApolloClient();
    const cancelSubscription = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const res = yield client.mutate({
                mutation: USER_STRIPE_SUBSCRIPTION_CANCEL_MUTATION,
                variables: {
                    id,
                    subscriptionId,
                    input: {},
                },
                update: (cache, result) => {
                    var _a;
                    ((_a = result.data) === null || _a === void 0 ? void 0 : _a.userStripeSubscriptionCancel.success) &&
                        cache.modify({
                            id: `Subscription:${subscriptionId}`,
                            fields: {
                                cancellationDate() {
                                    return new Date();
                                },
                            },
                        });
                },
            });
            return !!((_a = res.data) === null || _a === void 0 ? void 0 : _a.userStripeSubscriptionCancel.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not cancel subscription: ${err}`));
        }
        return false;
    }), [client, id, subscriptionId]);
    return {
        cancelSubscription,
    };
};
export default useStripeSubscription;
