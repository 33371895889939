var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
const Icon = forwardRef((_a, ref) => {
    var { width = 24, height = 24, viewBox = '0 0 24 24', children } = _a, rest = __rest(_a, ["width", "height", "viewBox", "children"]);
    return (_jsx("svg", Object.assign({ width: width, height: height, viewBox: viewBox, fill: "none", ref: ref }, rest, { children: children }), void 0));
});
export default Icon;
