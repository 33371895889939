import { onError } from '@apollo/client/link/error';
import { authorizationService } from '@happn/authorization';
export const errorLink = onError(({ networkError }) => {
    var _a;
    const error = networkError;
    if (((_a = error === null || error === void 0 ? void 0 : error.result) === null || _a === void 0 ? void 0 : _a.error) !== 'invalid_token') {
        return;
    }
    authorizationService.refreshRequest();
});
