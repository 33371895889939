var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import loggerService from '@happn/logger';
import { ApiTypes } from '@happn/types';
import { USER_DELETE_MUTATION, USER_CREATE_MUTATION, USER_QUERY, USER_ID_ME, USER_UPDATE_MUTATION, } from '../../apollo';
const useUser = (id = USER_ID_ME) => {
    const apolloClient = useApolloClient();
    const { data } = useQuery(USER_QUERY, {
        fetchPolicy: 'cache-only',
        variables: {
            id,
        },
    });
    const create = useCallback((input) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_CREATE_MUTATION,
                variables: {
                    input: Object.assign(Object.assign({}, input), { type: ApiTypes.UserCreationGtoTypeEnum.CLIENT }),
                },
            });
            return (data === null || data === void 0 ? void 0 : data.userCreate) || null;
        }
        catch (err) {
            loggerService.error(new Error(`Could not create user, ${err}`));
            return null;
        }
    }), [apolloClient]);
    const update = useCallback((input, overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_UPDATE_MUTATION,
                variables: {
                    id: overrideId || id,
                    input,
                },
            });
            return !!(data === null || data === void 0 ? void 0 : data.userUpdate.success);
        }
        catch (err) {
            loggerService.error(new Error(`Could not update user, ${err}`));
            return false;
        }
    }), [apolloClient, id]);
    const fetch = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield apolloClient.query({
                query: USER_QUERY,
                variables: {
                    id,
                },
            });
            if (!(data === null || data === void 0 ? void 0 : data.user)) {
                loggerService.error(new Error('Could not get user'));
                return Promise.reject();
            }
            return data.user;
        }
        catch (err) {
            loggerService.error(new Error(`Could not get user, ${err}`));
            return Promise.reject(err);
        }
    }), [apolloClient, id]);
    const remove = useCallback((overrideId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield apolloClient.mutate({
                mutation: USER_DELETE_MUTATION,
                variables: {
                    id: overrideId || id,
                },
            });
            if (!((_a = data === null || data === void 0 ? void 0 : data.deleteUser) === null || _a === void 0 ? void 0 : _a.success)) {
                loggerService.error(new Error('Could not delete user'));
                return Promise.reject(false);
            }
            return Promise.resolve(true);
        }
        catch (err) {
            loggerService.error(new Error(`Could not delete user, ${err}`));
            return Promise.reject(err);
        }
    }), [apolloClient, id]);
    return { create, update, fetch, remove, user: data === null || data === void 0 ? void 0 : data.user };
};
export default useUser;
