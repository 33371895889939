import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_ID_QUERY } from './gql';
const useId = (id = USER_ID_ME) => {
    var _a;
    const { data } = useQuery(USER_ID_QUERY, {
        fetchPolicy: 'cache-only',
        variables: { id },
    });
    return ((_a = data === null || data === void 0 ? void 0 : data.user) === null || _a === void 0 ? void 0 : _a.id) || '';
};
export default useId;
