import { useQuery } from '@apollo/client';
import { USER_ID_ME } from '../../apollo';
import { USER_STATUS_QUERY } from './gql';
const useStatus = (id = USER_ID_ME) => {
    const { data } = useQuery(USER_STATUS_QUERY, { fetchPolicy: 'cache-only', variables: { id } });
    return ((data === null || data === void 0 ? void 0 : data.user) || {
        isModerator: false,
        isSub: false,
        isSponsor: false,
        status: undefined,
    });
};
export default useStatus;
